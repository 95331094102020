import { createApp } from 'vue'
import App from './App.vue'
import store from "./store/store"
import router from './router'
import attemptToAuthoriseTokens from "./helpers/authorise-tokens";
import Particles from "particles.vue3";
import Toast, { POSITION } from "vue-toastification";
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import Vue3NativeNotification from 'vue3-native-notification'
import { createHead } from '@vueuse/head'

import './main.css'
import 'vue-toastification/dist/index.css';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://2f9708b2c46b470786ba159f597fbcf1@o667319.ingest.sentry.io/5766576',
    release: 'kairo@1.0-beta', // + process.env.npm_package_version,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

const app = createApp(App);
const head = createHead();

if (process.env.NODE_ENV === 'production') {
  app.config.errorHandler = (err) => {
    Sentry.captureException(err)
  }
}

//if (process.env.NODE_ENV === 'production') {
  window.inlineManualOptions = { language: 'en'};
  const inlinemanual_app_key="5a399d012fe5a5fc0894c2102cb8f728";
  !function(e,n,a,t,i,r,s){e[i]=e[i]||function(){(e[i].q=e[i].q||[]).push(arguments)},e[i].l=1*new Date,e[i].snippet={version:"2.0"},r=n.createElement(a),s=n.getElementsByTagName(a)[0],r.async=1,r.src=t,r.id=t+"-"+i,s.parentNode.insertBefore(r,s)}(window,document,"script","https://cdn.inlinemanual.com/inm/loader/loader." + inlinemanual_app_key + ".js","InlineManual");
//}

  /* EngageKit */
  // https://cdn.engagekit.com/client/client.umd.js
  (function(){"use strict";if(window.EngageKitPreLoadQueue=[],window.EngageKit&&window.EngageKit.invoked)throw"EngageKit snippet can't be used twice.";const o=t=>function(...e){window.EngageKitPreLoadQueue.push([t,...e])},n={_options:{},invoked:!0,methods:["ready","reset","identify","group","track","setLogLevel","getLogLevel","setTheme"],load(t){const e=document.createElement("script");e.type="text/javascript",e.async=!0,e.src="https://cdn.engagekit.com/client/client.umd.js";const i=document.getElementsByTagName("script")[0];i.parentNode&&i.parentNode.insertBefore(e,i),this._options=Object.assign(t,{snippetVersion:"0.1.0"})}};for(let t=0;t<n.methods.length;t++){const e=n.methods[t];n[e]=o(e)}window.EngageKit=n})();
  window.EngageKit.load({
    clientApiKey: "client_L1KhvwrU03aUnh3C8yYtEUCEmhjZQa2K4tvRJoxW3C4Tlfk1",
    rewardClaimedCallback(reward) {
      const trial = document.getElementById('trial-link');
      const days = parseInt(trial.text.replace(/[^0-9]/g, ""));
      const newDays = days + reward.reward.amount;
      let daysText = 'days';
      if (newDays < 2) daysText = 'day';
      trial.text = `Trial expires in ${newDays} ${daysText}`;
    },
  });

app.use(router);
app.use(store);
app.use(head);
app.use(Particles);
app.use(Toast, {
  // Setting the global default position
  position: POSITION.TOP_CENTER
});
app.use(Vue3NativeNotification, {
  // Automatic permission request before
  // showing notification (default: true)
  requestOnNotify: true,
});

app.mount('#app');

if (process.env.NODE_ENV === 'production') {
  window.addEventListener('error', (event) => {
    Sentry.captureException(event)
  });
  window.addEventListener('unhandledrejection', (event) => {
    Sentry.captureException(event)
  });
}

store.dispatch("auth/initAuth");

attemptToAuthoriseTokens();
