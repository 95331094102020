<template>
  <div
    :id="'block-' + id"
    :ref="'block-' + id"
    class="col-span-1 shadow-xl flex flex-col text-center bg-white rounded-xl shadow overflow-hidden relative border-4 h-auto"
    :class="[
      'bg-' + color + '-' + bg + ' border-' + color + '-900',
      'hover:bg-' + color + '-200',
      {'opacity-0': isNew, 'cursor-pointer': !buildMode}
    ]"
    @click="!buildMode && blockClick(true)"
  >

    <div class="absolute z-10 w-full h-full">
      <div class="rounded-md h-full opacity-20 border-3"
           style="border-radius: 8px;"
           :style="'box-shadow: inset 0 0 100px 0 #' + colorDeep + '; border-color: #' + colorLight">
      </div>
    </div>

    <div
      class="z-20 p-2 relative shadow-none items-center"
      :class="[{'flex p-4': blockWidth !== 'small'}, 'bg-transparent border-' + color + '-300']"
    >
      <div class="flex-shrink-0">
        <span class="rounded-lg inline-flex p-2"
          :class="'bg-' + color + '-50 border-' + color + '-300 border-2'">
          <BlockImage
            v-if="imageLink != ''"
            @click="blockClick(false)"
            v-bind:imageLink="imageLink"
            v-bind:color="color"
          ></BlockImage>
          <BlockIcon
            v-else
            @click="blockClick(false)"
            v-bind:type="faceType"
            v-bind:color="color"
          ></BlockIcon>
        </span>
      </div>
      <div class="flex-1 text-left pl-2" v-if="blockWidth!=='small'">
        <svg
          @click="blockClick(false)"
          :key="titleKey"
          class="h-1 cursor-pointer z-10 text-gray-900 text-lg font-bold inline-block"
          style="font-family: 'Roboto Mono', monospace;width: 100%; height: 28px;">
          <text :style="'stroke: #' + colorOutline + ';'" text-anchor="left" x="1" y="22" fill="white" stroke-width="4" letter-spacing="2" paint-order="stroke">{{title}}</text>
        </svg>
      </div>

      <button v-if="buildMode" @click="blockEdit"
        class="z-20 top-1 left-1 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-900'"
      >
        <svg class="w-4 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path></svg>
      </button>

      <button v-if="buildMode"
        class="cursor-move block-drag-handle z-20 top-3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-900'"
      >
        <svg class="w-6 h-6" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="5" cy="9" r="1" /><circle cx="5" cy="15" r="1" /><circle cx="12" cy="9" r="1" /><circle cx="12" cy="15" r="1" /><circle cx="19" cy="9" r="1" /><circle cx="19" cy="15" r="1" />
        </svg>
      </button>

      <button v-if="buildMode" @click="deleteBlock"
        class="z-20 top-1 right-1 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-900'"
      >
        <span class="sr-only">Close</span>
        <!-- Heroicon name: x -->
        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>
  </div>

</template>
<script>
  import {colors} from '../colors/colors.js';
  import {useToast} from "vue-toastification";
  import {v4 as uuidv4} from "uuid";
  import {nextTick} from "vue";
  import { useNativeNotifications } from "vue3-native-notification";
  import anime from "animejs";
  import BlockIcon from './BlockIcon.vue';
  import BlockImage from './BlockImage.vue';

  export default {
    name: 'BlockLink',
    components: {
      BlockIcon,
      BlockImage,
    },
    props: {
      id: String,
      title: String,
      color: String,
      size: Number,
      link: String,
      imageLink: String,

      src: String,
      faceType: {
        default: 'default',
        type: String
      },
      preview: {
        default: false,
        type: Boolean
      },
      buildMode: {
        default: false,
        type: Boolean
      },
      embed: {
        default: false,
        type: Boolean
      },
      embedPreview: {
        default: false,
        type: Boolean
      },
      isLoading: {
        default: false,
        type: Boolean
      },
      hasError: {
        default: false,
        type: Boolean
      },
      isNew: {
        default: false,
        type: Boolean
      },
      embedOptions: Object,
      refID: String,
    },
    setup() {
      const toast = useToast();
      const nativeNotification = useNativeNotifications();
      return { toast, nativeNotification };
    },
    data() {
      return {
        bg: '100',
        titleKey: uuidv4(),
        colors,
        blockWidth: 'regular',
      }
    },
    computed: {
      isActive() {
        return !!this.activeCountdownSince;
      },
      colorLight() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[100];
      },
      colorDark() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[500];
      },
      colorDeep() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[600];
      },
      colorGrad() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[600];
      },
      colorOutline() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[900];
      },
    },
    methods: {
      blockClick(parent = false) {
        if (parent) {
          if (!this.buildMode) {
            this.visitLink();
          }
        }
        if (!parent) {
          if (this.buildMode) {
            this.blockEdit();
          }
        }
      },
      blockEdit() {
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else {
          this.$emit('blockEditStart', this.id);
        }
      },
      deleteBlock() {
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else {
          this.$emit('blockDelete', this.id, this.refID);
        }
      },
      changeBG() {
        if (this.bg === '100') {
          this.bg = '300';
        } else {
          this.bg = '100';
        }
      },
      visitLink() {
        if (this.link) {
          window
            .open(this.link, '_blank')
            .focus();
        }
      },
      blockGetWidth() {
        // select element with block id
        const block = document.querySelector("#block-" + this.id);
        // get width of block
        const blockWidth = block.offsetWidth;
        if (blockWidth < 200) {
          return 'small';
        }
        return 'regular';
      }
    },
    mounted() {
      if (this.isNew) {
        this.$emit('blockAdded', this.id);
        const block = document.querySelector("#block-" + this.id);
        //block.style.transform = "scale(0.5)";
        block.style.zIndex = 10000000;

        anime({
          targets: '#block-' + this.id,
          easing: 'linear',
          duration: 380,
          opacity: [1, 1],
          rotate: [3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          delay: 600,
          //scale: [{value: 0.5},{value: 1}],
          matrix3d: [
            '0.1, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.318, 0, 0, 0, 0, 0.318, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.448, 0, 0, 0, 0, 0.448, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.688, 0, 0, 0, 0, 0.688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.983, 0, 0, 0, 0, 0.983, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.011, 0, 0, 0, 0, 1.011, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.131, 0, 0, 0, 0, 1.131, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.147, 0, 0, 0, 0, 1.147, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.056, 0, 0, 0, 0, 1.056, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.129, 0, 0, 0, 0, 1.129, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.097, 0, 0, 0, 0, 1.097, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.023, 0, 0, 0, 0, 1.023, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.965, 0, 0, 0, 0, 0.965, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.967, 0, 0, 0, 0, 0.967, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1'
          ],
          complete: () => {
            anime.remove('#block-' + this.id);
            block.style.zIndex = 'auto';

            // Dirty fix to get around title rendering issue
            nextTick(() => {
              let titleUid = uuidv4();
              this.titleKey = titleUid;
              this.blockWidth = this.blockGetWidth();
            });
          }
        });
      } else {
        this.blockWidth = this.blockGetWidth();
      }
    }
  }
</script>

<style>
</style>
