import { createStore } from "vuex"
import createPersistedState from "vuex-persistedstate";
import tracker from "./modules/tracker";
import projects from "./modules/projects";
import notes from "./modules/notes";
import app from "./modules/app";
import auth from "./modules/auth";

const store = createStore({
  modules: {
    auth,
    tracker,
    projects,
    notes,
    app
  },
  plugins: [createPersistedState()],
});

export default store
