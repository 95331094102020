<template>
  <div id="tracker-nav" class="pt-3 pb-4 mx-auto shadow w-full fixed z-30 bg-white bg-opacity-30">
    <div class="w-full mx-auto px-4 sm:px-6 lg:px-8 flex">
      <div class="mt-2 border-r-2 pr-1 mr-3 border-gray-100 hidden md:block">
        <div
          class="text-right md:flex-grow-0"
        >
          <button
            data-im-id="button-deepwork"
            :disabled="!timerActive || isLoading"
            @click="deepWorkModal = true"
            type="button"
            class="mr-3 border-gray-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-gray-600 bg-gray-200 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50"
            style="box-shadow: inset 0px -6px 0px #9CA3AF, inset 0 -7px 0 0 #F3F4F6, inset 0px 1px 0px 1px #F3F4F6;"
            :class="{'opacity-40' : !timerActive, 'opacity-100' : timerActive}"
          >
            <svg v-if="isLoading" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
            <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"></path></svg>
          </button>
        </div>
      </div>
      <div class="mt-2 border-r-2 pr-1 mr-3 border-gray-100 hidden md:block">
        <div
          class="text-right md:flex-grow-0"
        >
          <button
            data-im-id="button-notes"
            :disabled="isLoading"
            @click="notesEdited = true"
            type="button"
            class="mr-3 relative border-gray-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-gray-600 bg-gray-200 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50"
            style="box-shadow: inset 0px -6px 0px #9CA3AF, inset 0 -7px 0 0 #F3F4F6, inset 0px 1px 0px 1px #F3F4F6;"
            :class="{'opacity-40' : isLoading, 'opacity-100' : !isLoading}"
          >
            <svg v-if="isLoading" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
            <svg  v-else class="w-6 h-6" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
            </svg>
            <span class="inline-flex items-center px-1 py-0.5 rounded-full text-xs absolute right-0 top-0 opacity-80 bg-gray-100 text-gray-800" style="font-size: 9px;">{{notes.length}}</span>
          </button>
        </div>
      </div>
      <div class="flex-grow mt-2 hidden md:block">
      </div>
      <div class="flex second-col mt-0">
        <div data-im-id="timer-session" class="text-left md:text-right mt-1 md:mt-0 md:flex-grow md:border-l-2 md:ml-3 md:pl-1">
          <p class="text-xs text-gray-500 uppercase">Session</p>
          <h2 v-if="blocks.length" class="z-10 text-gray-900 md:pl-2 text-2xl md:text-3xl font-bold" style="font-family: 'Roboto Mono', monospace;">{{timerSession}}</h2>
          <span v-else class="z-10 text-gray-300 md:pl-2 text-2xl md:text-3xl font-bold" style="font-family: 'Roboto Mono', monospace;">00:00:00</span>
        </div>
        <div data-im-id="timer-total" class="flex-grow md:flex-shrink md:flex-none mt-1 md:mt-0 text-left md:text-right border-l-2 ml-3 pl-1">
          <p class="text-xs pl-2 text-gray-500 uppercase">Total</p>
          <h2 v-if="blocks.length" class="z-10 text-gray-900 pl-2 text-2xl md:text-3xl font-bold" style="font-family: 'Roboto Mono', monospace;">{{timerTotal}}</h2>
          <span v-else class="z-10 text-gray-300 pl-2 text-2xl md:text-3xl font-bold" style="font-family: 'Roboto Mono', monospace;">00:00:00</span>
        </div>
        <div class="mt-2 text-right border-l-2 ml-3 pl-3">
          <button data-im-id="button-freshstart" type="button" @click="freshStart('all')" :disabled="isLoading"
            class="inline-flex items-center p-2 pb-3 border-2 border-b-3 border-blue-900 border-transparent rounded-md shadow-sm text-blue-900 bg-blue-100 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            style="box-shadow: inset 0px -6px 0px #93C5FD, inset 0 -7px 0 0 #EFF6FF, inset 0px 1px 0px 1px #EFF6FF;"
          >
            <div>
              <svg v-if="isLoading" class="w-6 h-6 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clip-rule="evenodd" />
              </svg>
              <span class="font-bold ml-1 hidden md:inline-block">Fresh start</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <main id="tracker-main" class="pt-2">
    <div v-if="isLoading" class="max-w-7xl px-4 mx-auto sm:px-6 lg:px-8 text-center pt-2" style="margin-top: 82px;">
      <div class="inline-flex text-base leading-6 font-medium rounded-md text-white text-gray-600 cursor-not-allowed">
        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        Loading fun assets...
      </div>
    </div>
    <div style="margin-top: 82px;">
      <div v-if="currentUser && boards.length && boards[0].rows.length && !blocks.length" class="text-center max-w-7xl min-w-7xl px-4 mx-auto sm:px-6 lg:px-8 mt-4 pt-5" style="width: 80rem;">
          Once upon a time, there was a blank board.<br />
            <div v-if="!showStatic">
              <button @click="launchOnboarding" type="button"
                class="mt-2 ml-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50"
                style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
              >
                <svg xmlns="http://www.w3.org/2000/svg"  class="h-5 w-5 inline-block" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                </svg>

                <span class="font-bold ml-1">Let's get started</span>
              </button>
          </div>
          <p v-if="showStatic">Click on the magical 
          <button type="button" class="px-4 text-gray-500 rounded-sm border focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" style=""><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"></path></svg><span>Build</span></button>
          button in the top left corner. That is where it all starts.
          </p>
      </div>
      <div v-if="boards.length" class="grid gap-y-3 grid-cols-1 max-w-7xl min-w-7xl px-4 mx-auto sm:px-6 lg:px-8" style="width: 80rem;">
        <div class="block"
          v-for="(row, rowIndex) in boards[0].rows"
          v-bind:key="row.id"
           :id="'row-' + rowIndex"
        >
          <div class="p-2 rounded bg-opacity-80"
           :class="'bg-' + row.color + '-50'">
            <div class="flex">
              <div v-if="row.title"
                class="px-3 py-3 pt-0 text-left font-medium tracking-wider"
                :class="'text-' + row.color + '-500'"
              >{{row.title}}</div>
              <div v-if="row.freshStart" class="flex-grow text-right">
                <button data-im-id="button-freshstart" type="button" @click="freshStart(row.id)"
                  class="inline-flex items-center p-0.5 border-2 border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                  :class="[row.color ? 'shadow-sm text-' + row.color + '-600 bg-' + row.color + '-50 hover:bg-' + row.color + '-100 border-' + row.color + '-600 focus:ring-' + row.color + '-500'
                          : 'shadow-sm text-blue-600 bg-blue-50 hover:bg-blue-100 border-blue-600 focus:ring-blue-500']"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="grid gap-6" :class="'grid-cols-' + row.cols.length">
          <div v-for="col in row.cols" v-bind:key="col.id" class="rounded p-2 bg-opacity-80"
            :class ="' bg-' + col.color + '-100'">
            <draggable
              :list="col.data"
              group="blocks"
              itemKey="id"
              handle=".block-drag-handle"
              @change="boardChange"
              class="col-transition grid gap-y-4 grid-cols-1 h-full content-start min-h-16"
              :class="{'custom-outline': boardDrag}"
              :animation="200"
              @start="boardDrag=true"
              @end="boardDrag=false"
              drag-class="block-drag-class"
              ghost-class=""
              chosen-class=""
            >
              <template #header>
                <div
                  v-if="col.title"
                  :class="'text-' + col.color + '-500'"
                  class="px-2 py-0 pt-2 text-left font-medium tracking-wider">{{col.title}}</div>
              </template>
              <template #item="{element}">
                <div>
                  <component
                    :is="blocks[blockIndexById(element.block)].type || 'Block'"
                    v-bind="blocks[blockIndexById(element.block)]"
                    :blockTick="blockTick"
                    :boardMode="boards[0].mode"
                    :projects="this.$store.state.projects.projects"
                    :performance="this.performance"
                    @block-reset="blockReset"
                    @block-edit-title="blockEditTitle"
                    @block-edit-size="blockEditSize"
                    @block-edit-color="blockEditColor"
                    @block-edit-link="blockEditLink"
                    @block-edit-image-link="blockEditImageLink"
                    @block-edit-cancel="blockEditCancel"
                    @block-edit-start="blockEditStart"
                    @block-notification-update="blockNotificationUpdate"
                    @block-update="blockUpdate"
                    @block-add-time="blockAddTime"
                    @block-remove-time="blockRemoveTime"
                    @element-start="elementStart"
                    @element-end="elementEnd"
                    @element-increase="elementIncrease"
                    @element-decrease="elementDecrease"
                    @countdown-start="countdownStart"
                    @countdown-end="countdownEnd"
                    @handle-tracking-change="handleTrackingChange"
                  />
                </div>
              </template>
            </draggable>
          </div>
        </div>
        </div>
        </div>
      </div>
    </div>

    <div class="anime-container bg-green-200 border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0 hidden"></div>
    <div class="anime-container bg-green-200 border-0 border-green-600 w-8 h-8 absolute rounded-lg opacity-0 hidden"></div>

    <div v-if="toggleModal" class="fixed z-50 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="inline-block align-middle h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

          <div class="flex items-center">
            <div class="rounded-full bg-blue-100 text-blue-900 p-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clip-rule="evenodd" />
              </svg>
            </div>
            <div>
              <div class="flex items-center">
                <h1 class="ml-3 text-2xl font-bold leading-7 text-gray-900">
                  Fresh start
                </h1>
              </div>
              <div class="ml-3 flex flex-col">
                <div class="flex items-center text-sm text-gray-500 font-medium">
                  <p>Whether it's a new day or a new start.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="border-t border-gray-200 mt-3">
            <div class="mt-3 text-center sm:mt-5">
              <div class="text-sm text-yellow-500 font-medium mb-2">
                <p class="text-center">
                  <svg class="w-6 h-6 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
                  <span v-if="toggleModal === 'all'">Resets all trackers on the board.</span>
                  <span v-else>Resets trackers in the selected row.</span>
                </p>
              </div>
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                Do you want to add tracked blocks to your reports?
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                </p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button v-if="currentUser" @click="resetAll" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
              NO
            </button>
            <button v-if="!currentUser" @click="resetBlocksAnon" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
              NO
            </button>
            <button v-if="currentUser" @click="freshStartSave" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
              YES
            </button>
            <button v-else type="button" class="cursor-not-allowed mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-200 text-base font-medium text-gray-400 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm">
              YES
            </button>
          </div>
          <div v-if="!currentUser" class="text-sm  text-gray-500 font-medium mt-1">* Reports are available only to paid plans.</div>
          <div class="mt-5 sm:mt-6">
            <button @click="toggleModal = !toggleModal" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="deepWorkModal || deepWorkState" class="fixed z-50 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-black opacity-100"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div>
            <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-200">
              <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"></path></svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-2xl leading-6 font-medium text-gray-200 border-b pb-4" id="modal-headline">
                Deep Work
              </h3>
              <div v-if="deepWorkState">
                <h2 v-if="blocks.length" class="z-10 text-gray-200 pl-2 text-3xl font-bold" style="font-family: 'Roboto Mono', monospace;">{{deepWorkTotal}}</h2>
              </div>
              <div v-if="!deepWorkState" class="text-gray-200">

                <div class="grid grid-cols-2 border-b mb-2 pb-2">
                  <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6 sm:py-1">
                    <div>
                      <label for="block_size_hours" class="block text-sm font-medium text-white sm:mt-px sm:pt-2">
                        Hours
                      </label>
                    </div>
                    <div class="sm:col-span-2">
                      <select v-model="deepWorkSizeHours" id="block_size_hours" name="block_size_hours" class="text-black mt-0 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm">
                        <option v-for="option in sizeOptionsHours" :value="option.value" v-bind:key="option.value">
                          {{ option.text }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6 sm:py-1">
                    <div>
                      <label for="block_size_minutes" class="block text-sm font-medium text-white-900 sm:mt-px sm:pt-2">
                        Minutes
                      </label>
                    </div>
                    <div class="sm:col-span-2">
                      <select v-model="deepWorkSizeMinutes" id="block_size_minutes" name="block_size_minutes" class="text-black mt-0 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm">
                        <option v-for="option in sizeOptionsMinutes" :value="option.value" v-bind:key="option.value">
                          {{ option.text }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="relative flex text-left items-start pt-2 pb-2"
                v-for="item in deepChecklist" v-bind:key="item.id"
                >
                  <div class="h-8 w-8">
                    <input :id="item.id" name="comments" type="checkbox" class="focus:ring-green-500 h-8 w-8 text-green-600 border-gray-300 rounded">
                  </div>
                  <div class="ml-3 text-lg">
                    <label :for="item.id" class="font-medium text-gray-200">{{item.title}}</label>
                    <p class="text-gray-500 text-sm">{{item.description}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 text-center">
            <button v-if="!deepWorkState" @click="deepWorkStart" type="button" class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm">
              Start Deep Work
            </button>
            <button v-if="deepWorkState === 'active'" @click="deepWorkStop" type="button" class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm">
              Stop Deep Work
            </button>
            <p v-if="deepWorkState === 'done'" class="text-gray-300 text-2xl">
              👏 Well done<br />
              <a @click="deepWorkDismiss" class="cursor-pointer inline-flex justify-center underline text-base text-gray-300 sm:text-sm">
                Close
              </a>
            </p>
          </div>
          <div v-if="!deepWorkState" class="mt-2 sm:mt-2 text-center">
            <a @click="deepWorkModal = false" class="cursor-pointer inline-flex justify-center underline text-base text-gray-300 sm:text-sm">
              Not now
            </a>
          </div>
        </div>
      </div>
    </div>

    <template v-if="blocks">
      <template v-for="block in blocks">
        <template
          v-for="collector in block.collectors"
          v-bind:key="collector"
        >
          <div v-if="blocks[blockIndexById(collector.id)]">
            <div
                    :id="'block-' + block.id + '-collector-' + collector.id"
                    class="absolute collector-anim items-center rounded-lg w-10 h-10 p-2 hidden"
                    :class="'bg-' + blocks[blockIndexById(collector.id)].color  + '-300'"
            ></div>
            <div
                    :id="'block-' + block.id + '-collector-' + collector.id"
                    class="absolute collector-anim items-center rounded-lg w-10 h-10 p-2 hidden"
                    :class="'bg-' + blocks[blockIndexById(collector.id)].color + '-300'"
            ></div>
            <div
                    :id="'block-' + block.id + '-collector-' + collector.id"
                    class="absolute collector-anim items-center rounded-lg w-10 h-10 p-2 hidden"
                    :class="'bg-' + blocks[blockIndexById(collector.id)].color + '-300'"
            ></div>
            <div
                    :id="'block-' + block.id + '-collector-' + collector.id"
                    class="absolute collector-anim items-center rounded-lg w-10 h-10 p-2 hidden"
                    :class="'bg-' + blocks[blockIndexById(collector.id)].color + '-300'"
            ></div>
            <div
                    :id="'block-' + block.id + '-collector-' + collector.id"
                    class="absolute collector-anim items-center rounded-lg w-10 h-10 p-2 hidden"
                    :class="'bg-' + blocks[blockIndexById(collector.id)].color + '-300'"
            ></div>
            <div
                    :id="'block-' + block.id + '-collector-' + collector.id"
                    class="absolute collector-anim items-center rounded-lg w-10 h-10 p-2 hidden"
                    :class="'bg-' + blocks[blockIndexById(collector.id)].color + '-300'"
            ></div>
            <div
              :id="'block-' + block.id + '-collector-' + collector.id"
              class="absolute collector-anim items-center rounded-lg w-10 h-10 p-2 hidden"
              :class="'bg-' + blocks[blockIndexById(collector.id)].color + '-50 border-' + blocks[blockIndexById(collector.id)].color + '-300 border-2'"
            >
              <BlockIcon
                v-bind:type="blocks[blockIndexById(collector.id)].faceType"
                v-bind:color="blocks[blockIndexById(collector.id)].color"
                v-bind:isPreview="true"
              ></BlockIcon>
            </div>
          </div>
        </template>
      </template>
    </template>
  </main>

  <div v-if="blockEdited.id" class="fixed inset-0 transition-opacity z-40" aria-hidden="true">
    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
  </div>
  <transition
    enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
    leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
    enter-from-class="translate-x-full"
    enter-to-class="translate-x-0"
    leave-from-class="translate-x-0"
    leave-to-class="translate-x-full"
  >
    <component
      v-if="blockEdited.id"
      :is="blockEdited.type + 'Edit'"
      v-bind:key="blockEdited.id"
      v-bind:id="blockEdited.id"
      v-bind:block="blockEdited"
      v-bind:projects="projects"
      v-bind:blocks="blocks"
      @block-edit-stop="blockEditStop"
      @block-update="blockUpdate"
    ></component>
  </transition>

  <div v-if="notesEdited" class="fixed inset-0 transition-opacity z-40" aria-hidden="true">
    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
  </div>
  <transition
    enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
    leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
    enter-from-class="translate-x-full"
    enter-to-class="translate-x-0"
    leave-from-class="translate-x-0"
    leave-to-class="translate-x-full"
  >
    <notesEdit
      v-if="notesEdited"
      v-bind:notes="notes"
      v-bind:blocks="blocks"
      v-bind:projects="projects"
      @notes-edit-stop="notesEditStop"
    ></notesEdit>
  </transition>

  <div v-if="noteAddModal" class="fixed inset-0 transition-opacity z-40" aria-hidden="true">
    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
  </div>
  <div v-if="noteAddModal" class="fixed z-50 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span class="inline-block align-middle h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

        <div class="flex items-center">
          <div class="rounded-full bg-blue-100 text-blue-900 p-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-10 h-10" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
            </svg>
          </div>
          <div>
            <div class="flex items-center">
              <h1 class="ml-3 text-2xl font-bold leading-7 text-gray-900">
                Add a note
              </h1>
            </div>
            <div class="ml-3 flex flex-col">
              <div class="flex items-center text-sm text-gray-500 font-medium">
                <p>So you can recap later.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="border-t border-gray-200 mt-3">
          <div class="mt-3 text-center sm:mt-5">
            <div class="mt-2">
              <div class="mt-1">
                <select v-model="noteAddBlockRef" id="note_block_ref" name="note_block_ref" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  <option value="null">None</option>
                  <option v-for="option in blocks" :value="option.id" v-bind:key="option.id">
                    {{ option.title }}
                  </option>
                </select>
              </div>
              <div class="mt-1">
                <select v-model="noteAddProjectRef" id="note_project_ref" name="note_project_ref" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                  <option value="null">None</option>
                  <option v-for="option in projects" :value="option.id" v-bind:key="option.id">
                    {{ option.name }}
                  </option>
                </select>
              </div>
              <div class="mt-1">
                <textarea rows="4" ref="noteAddContentRef" v-model="noteAddContent" @keydown.enter="noteAdd($event)" class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <button @click="noteAdd" type="button" class="mb-5 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
            Submit
          </button>
          <button @click="noteAddModalDismiss" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="questionOneModal" class="fixed inset-0 transition-opacity z-40" aria-hidden="true">
    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
  </div>
  <div v-if="questionOneModal" class="fixed z-50 inset-0 overflow-y-auto">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div class="fixed inset-0 transition-opacity" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <span class="inline-block align-middle h-screen" aria-hidden="true">&#8203;</span>
      <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

        <div class="flex items-center">
          <div class="rounded-full bg-blue-100 text-blue-900 p-2">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
              <path stroke-linecap="round" stroke-linejoin="round" d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z" />
            </svg>

          </div>
          <div>
            <div class="flex items-center">
              <h1 class="ml-3 text-2xl font-bold leading-7 text-gray-900">
                {{questionPicker.title}}
              </h1>
            </div>
          </div>
        </div>

        <Form
          @submit="questionOneSubmit()"
          name="questionOne"
        >
        <div class="mt-3">
          <div class="mt-3 text-center sm:mt-5">
            <div class="mt-2">
              <div class="mt-1">
                  <Field
                    :id="questionPicker.name"
                    :name="questionPicker.name"
                    v-model="formQuestion"
                    type="text"
                    :rules="isRequired"
                    placeholder="I want to..."
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                  <ErrorMessage :name="questionPicker.name" class="mt-2 text-sm text-red-600" />
              </div>
            </div>
          </div>
        </div>
        <div class="mt-5 sm:mt-6">
          <button type="submit" class="mb-5 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
            Submit
          </button>
        </div>
        </Form>
      </div>
    </div>
  </div>

</template>

<script>
  import Block from '@/components/Block.vue';
  import BlockCounter from '@/components/BlockCounter.vue';
  import BlockCountdown from '@/components/BlockCountdown.vue';
  import BlockProgress from '@/components/BlockProgress.vue';
  import BlockCollector from '@/components/BlockCollector.vue';
  import BlockLink from '@/components/BlockLink.vue';
  import BlockEdit from '@/components/BlockEdit.vue';
  import BlockCounterEdit from '@/components/BlockCounterEdit.vue';
  import BlockCountdownEdit from '@/components/BlockCountdownEdit.vue';
  import BlockProgressEdit from '@/components/BlockProgressEdit.vue';
  import BlockCollectorEdit from '@/components/BlockCollectorEdit.vue';
  import BlockLinkEdit from '@/components/BlockLinkEdit.vue';
  import BlockIcon from '@/components/BlockIcon.vue';
  import NotesEdit from '@/components/NotesEdit.vue';
  import { animationInterval } from '../utils/animationInterval';
  import {colors} from '../colors/colors.js';
  import {skins} from '../colors/skins.js';
  import {presets} from '../presets/free.js';
  import { v4 as uuidv4 } from 'uuid';
  import {
    updateBlock,
    getBlocks
  } from "../models/BlocksModel";
  import {
    getBoards,
    createBoard,
    updateBoard
  } from "../models/BoardsModel";
  import {
    createReport
  } from "../models/ReportsModel";
  import {mapGetters, mapActions} from "vuex";
  import { useToast } from "vue-toastification";
  import draggable from 'vuedraggable';
  import {nextTick, ref} from "vue";
  import { useNativeNotifications } from "vue3-native-notification";
  import Worker from "worker-loader!../workers/countdown.js";
  import {getProjects} from "../models/ProjectsModel";
  import {getNotes, createNote} from "../models/NotesModel";
  import anime from "animejs";

  import { Field, Form, ErrorMessage } from 'vee-validate';
  //import dayjs from "dayjs";

  const sfx = require('@/assets/sfx/alarm.mp3');

  export default {
    name: 'Tracker',
    //emits: ["updateBG"],
    components: {
      draggable,
      Block,
      BlockCounter,
      BlockCountdown,
      BlockProgress,
      BlockCollector,
      BlockLink,
      BlockEdit,
      BlockCounterEdit,
      BlockCountdownEdit,
      BlockProgressEdit,
      BlockCollectorEdit,
      BlockLinkEdit,
      BlockIcon,
      NotesEdit,
      Field,
      Form,
      ErrorMessage
    },
    setup() {
      const toast = useToast();
      const nativeNotification = useNativeNotifications();
      const noteAddContentRef = ref(null);
      return { toast, nativeNotification, noteAddContentRef };
    },
    data() {
      return {
        boards: this.$store.state.tracker.boards,
        blocks: this.$store.state.tracker.blocks,
        projects: this.$store.state.projects.projects,
        notes: this.$store.state.notes.notes,
        favicons: {},
        timerTotal: 0,
        timerSession: 0,
        blockTick: new Date().getTime() + 1000,
        worker: null,
        limits: {
          blocks: 32,
          columns: 8,
          rows: 4
        },
        isLoading: false,
        jobQueue: [],
        jobQueueInterval: '',
        jobQueueRunning: false,
        audio: null,
        sfx: sfx,
        toggleModal: false,
        noteAddModal: false,
        noteAddContent: '',
        noteAddProjectRef: null,
        noteAddBlockRef: null,
        deepWorkEnabled: true,
        deepWorkState: false,
        deepWorkModal: false,
        deepWorkTotal: "00:00:00",
        deepWorkSize: 0,
        deepChecklist: [
          {
            id: 1,
            title: "Disable notifications",
            description: "Turn off all your notifications that might disturb you."
          },
          {
            id: 2,
            title: "Tell others",
            description: "Is there anyone that can disturb you? Tell them not to."
          },
        ],
        colors,
        colorSwatchEdit: null,
        modeSwatchEdit: null,
        controller: new AbortController(),
        blockEdited: {},
        notesEdited: false,
        boardDrag: false,
        boardData: [],
        formQuestion: '',
        formQuestionOneSubmitted: false,
        formQuestions: [
          {
            name: 'question1',
            title: 'Hello, what would you like to get from Kairo?'
          },
          {
            name: 'question2',
            title: 'Hello, where did you hear about Kairo?'
          },
          {
            name: 'question3',
            title: 'Hello, are you using Notion and for what?'
          },
          {
            name: 'question4',
            title: 'Hello, what made you decide to sign up for a trial?'
          },
          {
            name: 'question5',
            title: 'Hello, are you paying for any app to help you with your productivity?'
          }
        ],
        showStatic: false,
        sizeOptionsHours: [
          {text: '00', value: 0},
          {text: '01', value: 1},
          {text: '02', value: 2},
          {text: '03', value: 3},
          {text: '04', value: 4},
          {text: '05', value: 5},
          {text: '06', value: 6},
          {text: '07', value: 7},
          {text: '08', value: 8}
        ],
        sizeOptionsMinutes: [
          {text: '00', value: 0},
          {text: '15', value: 0.25},
          {text: '30', value: 0.5},
          {text: '45', value: 0.75}
        ],
        bgGradients: [
          {
            text: 'Gradient 1',
            value: 'bg-grad-1',
          },
          {
            text: 'Gradient 2',
            value: 'bg-grad-2',
          },
          {
            text: 'Gradient 3',
            value: 'bg-grad-3',
          },
          {
            text: 'Gradient 4',
            value: 'bg-grad-4',
          },
          {
            text: 'Gradient 5',
            value: 'bg-grad-5',
          },
          {
            text: 'Gradient 6',
            value: 'bg-grad-6',
          },
          {
            text: 'Gradient 7',
            value: 'bg-grad-7',
          },
          {
            text: 'Gradient 8',
            value: 'bg-grad-8',
          },
        ],
        demoColor: 0,
        presets,
        skins
      };
    },
    computed: {
      ...mapGetters("auth", ["currentUser"]),
      performance() {
        if (this.currentUser.user_metadata && this.currentUser.user_metadata.performance) {
          return this.currentUser.user_metadata.performance;
        } else {
          return 'high';
        }
      },
      allowBlockAdd() {
        if (this.isLoading) return false;
        return !!(this.blocks.length < this.limits.blocks);
      },
      timerActive() {
        return this.blocks.some(function (block) {
          return block.activeSince !== 0;
        });
      },
      blockPlaceholders() {
        let placeholders = [];
        const count = 4 - this.blocks.length;
        for (let i = 0; i < count; i++) {
          placeholders.push(
            {
              id: i
            }
          );
        }
        return placeholders;
      },
      questionPicker() {
        const q = Math.floor(Math.random() * (5 - 1 + 1) + 1);
        console.log(this.formQuestions);
        return this.formQuestions[q - 1];
      },
      questionOneModal() {
        /*
        const userCreatedAt = dayjs(this.currentUser.created_at);
        if (userCreatedAt.isAfter(dayjs('2023-01-02T12:45:00Z'))) {
          if (this.formQuestionOneSubmitted) return false;
          if (this.currentUser.user_metadata && typeof this.currentUser.user_metadata.onboarding === 'undefined') {
            window.EngageKit.track('Question One Modal Displayed');
            return true;
          }
        }
        */
        return false;
      },
      deepWorkSizeHours: {
        get() {
          return ~~this.deepWorkSize;
        },
        set(v) {
          this.deepWorkSize = v + this.deepWorkSizeMinutes;
        }
      },
      deepWorkSizeMinutes: {
        get() {
          return this.deepWorkSize - Math.floor(this.deepWorkSize);
        },
        set(v) {
          this.deepWorkSize = this.deepWorkSizeHours + v;
        }
      },
    },
    watch: {
      timerActive(newValue) {
        this.handleTrackingChange(newValue);
        this.calculateTimerTotal();
        this.calculateTimerSession();
        this.deepWork();
      },
    },
    methods: {
      launchOnboarding() {
        if (typeof window.inline_manual_player !== 'undefined' && typeof window.inline_manual_player.activateTopic === 'function') {
          window.EngageKit.track('IM Onboarding Started');
          window.inline_manual_player.activateTopic(94935);
        } else {
          window.EngageKit.track('Kairo Onboarding Started');
          this.showStatic = true;
        }
      },
      questionOneSubmit() {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: this.encode({
            'form-name': 'questionOne',
            [this.questionPicker.name]: this.formQuestion,
            'email': this.currentUser.email,
          }),
        })
        .then(() => {
          this.updateUserAccount({
            data: {
              onboarding: true
            }
          })
            .then(() => {
              this.formQuestionOneSubmitted = true;
              this.toast.success(`Thank you, really appreciate your feedabck.`);
            })
            .catch(() => {
              this.toast.error(`Sorry, something went wrong. We were notified.`);
            });
        })
        .catch(() => { this.toast.error(`Sorry, something went wrong. We were notified.`);})
      },
      isRequired(value) {
        return value ? true : 'This field is required';
      },
      ...mapActions("auth", [
        "updateUserAccount"
      ]),
      ...mapActions(["resetEverything", "projectsResetAll", "notesResetAll"]),
      blockIndexById(blockId) {
        let index = this.blocks.findIndex(item => item.id === blockId);
        if (index > -1) {
          return index;
        }
      },
      freshStart(scope) {
        this.toggleModal = scope;
      },
      freshStartSave() {
        this.isLoading = true;
        let blocksData = JSON.parse(JSON.stringify(this.blocks));

        // We want to reset just a row, filter blocks
        if (this.toggleModal !== 'all') {
          let scopeBlocks = [];
          this.boards[0].rows.forEach((row) => {
            // Find the row id in question
            if (row.id === this.toggleModal) {
              row.cols.forEach((col) => {
                col.data.forEach((block) => {
                  scopeBlocks.push({id: block.block});
                });
              });
            }
          });
          let newBlocksData = blocksData.filter(block => {
            if (scopeBlocks.findIndex(item => item.id === block.id) >= 0) return true;
            return false;
          });
          blocksData = newBlocksData;
        }

        let from = new Date().getTime();
        let to = 0;

        // Clean up the data before saving
        blocksData.forEach((block, index) => {
          // Delete block if there are no elements
          if (block.elements.length) {
            delete blocksData[index].owner;
            delete blocksData[index].activeSince;

            // find the max and min within elements
            // close with end
            let block_start = 0;
            let block_end = 0;
            blocksData[index].elements.forEach((element, elIndex) => {
              if (block_start < element.start) block_start = element.start;

              // make sure elements with empty end are stopped
              if (element.end === null) {
                element.end = new Date().getTime();
                blocksData[index].elements[elIndex].end = element.end;
              }

              if (blocksData[index].type !== 'Block') {
                if (block_end < element.start) block_end = element.start;
              }

              if (block_end < element.end) block_end = element.end;
            });

            blocksData[index].from = block_start;
            if (block_start < from) from = block_start;

            blocksData[index].to = block_end;
            if (block_end > to) to = block_end;

          } else {
            delete blocksData[index];
          }
        });
        const reportData = {
          title: 'R',
          from: from,
          to: to,
          blocks: blocksData
        };
        createReport(reportData)
        .then(() => {
          this.resetPinned(blocksData)
          .then(() => {
            this.$store.commit('resetAll');
            this.totalTime = 0;
            this.timerTotal = '00:00:00';
            this.toggleModal = false;
            window.EngageKit.track('Fresh Start Saved');
            this.userContent();
          })
        });
      },
      resetBlocksAnon() {
        let blocksData = JSON.parse(JSON.stringify(this.blocks));

        // We want to reset just a row, filter blocks
        if (this.toggleModal !== 'all') {
          let scopeBlocks = [];
          this.boards[0].rows.forEach((row) => {
            // Find the row id in question
            if (row.id === this.toggleModal) {
              row.cols.forEach((col) => {
                col.data.forEach((block) => {
                  scopeBlocks.push({id: block.block});
                });
              });
            }
          });
          let newBlocksData = blocksData.filter(block => {
            if (scopeBlocks.findIndex(item => item.id === block.id) >= 0) return true;
            return false;
          });
          blocksData = newBlocksData;
        }

        blocksData.forEach((block) => {
          this.blockReset(block.id);
        });
        this.totalTime = 0;
        this.timerTotal = '00:00:00';
        this.toggleModal = false;
      },
      resetPinned(blocksData) {
        return new Promise((resolve) => {
          let promises = [];
          const toReset = blocksData;
          if (toReset.length) {
            toReset.forEach((block, index) => {
              toReset[index].activeSince = 0;
              toReset[index].elapsedTime = 0;
              toReset[index].elements = [];
              toReset[index].totalTime = 0;
              delete toReset[index].owner;
              promises.push(updateBlock(toReset[index].refID, toReset[index]));
            });
          }
          Promise.all(promises).then(()=>{
            resolve();
          });
        })
      },
      resetAll() {
        let blocksData = JSON.parse(JSON.stringify(this.blocks));

        // We want to reset just a row, filter blocks
        if (this.toggleModal !== 'all') {
          let scopeBlocks = [];
          this.boards[0].rows.forEach((row) => {
            // Find the row id in question
            if (row.id === this.toggleModal) {
              row.cols.forEach((col) => {
                col.data.forEach((block) => {
                  scopeBlocks.push({id: block.block});
                });
              });
            }
          });
          let newBlocksData = blocksData.filter(block => {
            if (scopeBlocks.findIndex(item => item.id === block.id) >= 0) return true;
            return false;
          });
          blocksData = newBlocksData;
        }

        this.resetPinned(blocksData)
            .then(() => {
              this.$store.commit('resetAll');
              this.totalTime = 0;
              this.timerTotal = '00:00:00';
              this.toggleModal = false;
              this.userContent();
            })
      },
      boardGridEditing() {
        const blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        const blockCountdownActive = this.blocks.filter(block => block.activeCountdownSince > 0);
        if (blockActive || blockCountdownActive.length > 0) {
          this.toast.warning(`Please stop any running blocks to be able to switch to Build mode.`);
        } else {
          this.$router.push({name: 'Tracker Build'});
        }
      },
      boardGridEditUpdate() {
        if (this.boardData[0].rows.length < 1) {
          this.boardData[0].rows.push(
            {
              title: "",
              order: 1,
              id: uuidv4(),
              color: null,
              freshStart: false,
              cols: [
                {
                  title: "",
                  id: uuidv4(),
                  order: 1,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 2,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 3,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 4,
                  data: [],
                  color: null,
                }
              ]
            });
        }

        this.$store.commit('boardAdd', this.boardData[0]);
        this.boardData.splice(0, this.boardData.length);
        if (this.currentUser) {
          this.jobQueueAdd({event: 'boardUpdate', expiration: 500});
        }
      },
      boardAdd(board) {
        this.$store.commit('boardAdd', board);
      },
      boardRemoveColumn(row, columnId) {

        let index = this.boardData[0].rows[row].cols.findIndex(item => item.id === columnId );
        this.boardData[0].rows[row].cols.splice(index, 1);

        // this.$store.commit('boardRemoveColumn', {
        //   row: row,
        //   columnId: columnId
        // });
      },
      colorGridSwatchEdit(id) {
        if (this.colorSwatchEdit === id) {
          this.colorSwatchEdit = null;
        } else {
          this.colorSwatchEdit = id;
        }
      },
      colorGridSwatch(element, color) {
        this.colorSwatchEdit = null;
        element.color = color;
      },
      colorBoardSwatch(type, color) {
        this.colorSwatchEdit = null;
        if (type === 'gradient') {
          //this.$emit('updateBG', color);
          this.updateBoardBG(color);
        } else {
          //this.$emit('updateBG', 'bg-' + color + '-100');
          this.updateBoardBG('bg-' + color + '-100');
        }
        this.boardData[0].color = color;
      },
      modeBoardSwatch(mode) {
        this.boardData[0].mode = mode;
        this.modeSwatchEdit = null;
      },
      blockIncrement(blockId) {
        this.$store.commit('blockIncrement', blockId);
        this.calculateTimerTotal();
      },
      blockReset(blockId) {
        this.$store.commit('blockReset', blockId);
      },
      blockDeleteBoard(blockId) {
        // find the block in the board
        this.boards[0].rows.forEach((row, rowIndex) => {
          this.boards[0].rows[rowIndex].cols.forEach((col, index) => {
            let blockRef = col.data.findIndex(item => item.block === blockId);
            // found
            if (blockRef > -1) {
              this.$store.commit('blockDeleteBoard', {rowIndex: rowIndex, colIndex: index, blockIndex: blockRef});
              if (this.currentUser) {
                this.jobQueueAdd({event: 'boardUpdate', expiration: 2000});
              }
            }
          })
        });
      },
      blockEditTitle(blockId, title) {
        this.$store.commit('blockEditTitle', {
          id: blockId,
          title: title
        });
      },
      blockEditSize(blockId, size) {
        this.$store.commit('blockEditSize', {
          id: blockId,
          size: size
        });
      },
      blockEditLink(blockId, link) {
        this.$store.commit('blockEditLink', {
          id: blockId,
          link: link
        });
      },
      blockEditImageLink(blockId, imageLink) {
        this.$store.commit('blockEditImageLink', {
          id: blockId,
          imageLink: imageLink
        });
      },
      blockEditIncreaseValue(blockId, increaseValue) {
        this.$store.commit('blockEditIncreaseValue', {
          id: blockId,
          increaseValue: increaseValue
        });
      },
      blockEditColor(blockId, color) {
        this.$store.commit('blockEditColor', {
          id: blockId,
          color: color
        });
      },
      blockEditProjectRef(blockId, projectId) {
        this.$store.commit('blockEditProjectRef', {
          id: blockId,
          projectRef: projectId
        });
      },
      blockEditSkin(blockId, skin) {
        this.$store.commit('blockEditSkin', {
          id: blockId,
          skin: skin
        });
      },
      blockEditFaceType(blockId, faceType) {
        this.$store.commit('blockEditFaceType', {
          id: blockId,
          faceType: faceType
        });
      },
      blockEditElements(blockId, elements) {
        this.$store.commit('blockEditElements', {
          id: blockId,
          elements: elements
        });
      },
      blockEditCollectors(blockId, collectors) {
        this.$store.commit('blockEditCollectors', {
          id: blockId,
          collectors: collectors
        });
      },
      blockEditTotalTime(blockId, totalTime) {
        this.$store.commit('blockEditTotalTime', {
          id: blockId,
          totalTime: totalTime
        });
      },
      blockEditProgressStart(blockId, progressStart) {
        this.$store.commit('blockEditProgressStart', {
          id: blockId,
          progressStart: progressStart
        });
      },
      blockEditProgressEnd(blockId, progressEnd) {
        this.$store.commit('blockEditProgressEnd', {
          id: blockId,
          progressEnd: progressEnd
        });
      },
      blockEditCounters(blockId, counters) {
        this.$store.commit('blockEditCounters', {
          id: blockId,
          counters: counters
        });
      },
      blockEditEmbedId(blockId, embedId) {
        this.$store.commit('blockEditEmbedId', {
          id: blockId,
          embedId: embedId
        });
      },
      blockEditEmbedOptions(blockId, embedOptions) {
        this.$store.commit('blockEditEmbedOptions', {
          id: blockId,
          embedOptions: embedOptions
        });
      },
      blockEditCancel(blockId, data) {
        if (data.title) this.blockEditTitle(blockId, data.title);
        if (data.size) this.blockEditSize(blockId, data.size);
        if (data.link) this.blockEditLink(blockId, data.link);
        if (data.imageLink) this.blockEditImageLink(blockId, data.imageLink);
        if (data.increaseValue) this.blockEditIncreaseValue(blockId, data.increaseValue);
        if (data.color) this.blockEditColor(blockId, data.color);
      },
      blockUpdate(blockRefID, blockId, blockData) {
        if (blockData.title) this.blockEditTitle(blockId, blockData.title);
        if (blockData.size) this.blockEditSize(blockId, blockData.size);
        if (blockData.increaseValue) this.blockEditIncreaseValue(blockId, blockData.increaseValue);
        if (blockData.color) this.blockEditColor(blockId, blockData.color);
        if (blockData.link) this.blockEditLink(blockId, blockData.link);
        if (blockData.imageLink) this.blockEditImageLink(blockId, blockData.imageLink);
        if (typeof blockData.projectRef !== 'undefined') this.blockEditProjectRef(blockId, blockData.projectRef);

        if (typeof blockData.skin !== 'undefined') this.blockEditSkin(blockId, blockData.skin);
        if (typeof blockData.faceType !== 'undefined') this.blockEditFaceType(blockId, blockData.faceType);
        if (typeof blockData.elements !== 'undefined') this.blockEditElements(blockId, blockData.elements);
        if (typeof blockData.collectors !== 'undefined') this.blockEditCollectors(blockId, blockData.collectors);
        if (typeof blockData.totalTime !== 'undefined') this.blockEditTotalTime(blockId, blockData.totalTime);

        if (typeof blockData.progressStart !== 'undefined') this.blockEditProgressStart(blockId, blockData.progressStart);
        if (typeof blockData.progressEnd !== 'undefined') this.blockEditProgressEnd(blockId, blockData.progressEnd);
        if (typeof blockData.counters !== 'undefined') this.blockEditCounters(blockId, blockData.counters);

        if (typeof blockData.embedId !== 'undefined') this.blockEditEmbedId(blockId, blockData.embedId);
        if (typeof blockData.embedOptions !== 'undefined') this.blockEditEmbedOptions(blockId, blockData.embedOptions);

        // Update total time
        this.calculateTimerTotal();

        if (this.currentUser) {
          updateBlock(blockRefID, blockData);
        }
      },
      blockNotificationUpdate(blockId, status) {
        this.$store.commit('blockNotificationUpdate', {
          id: blockId,
          status: status
        });
      },
      blockEditStart(blockId) {
        let index = this.blocks.findIndex(item => item.id === blockId);
        if (typeof this.blocks[index].type === 'undefined') this.blocks[index].type = 'Block';
        this.blockEdited = this.blocks[index];
      },
      blockEditStop() {
        this.blockEdited = {};
      },
      blockAddTime(blockId, time) {
        this.$store.commit('blockAddTime', {
          id: blockId,
          time: time
        });
      },
      blockRemoveTime(blockId, time) {
        this.$store.commit('blockRemoveTime', {
          id: blockId,
          time: time
        });
      },
      getRandomColor() {
        return this.colors[Math.floor(Math.random() * this.colors.length)].value;
      },
      handleTrackingChange (status) {
        // Get or create the favicon
        let color = 'green';
        let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        // Set the attributes of the favicon
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';

        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        if (blockActive) {
          color = blockActive.color;
        }

        if (status) {
          if (typeof this.favicons[color] === 'undefined') {
            link.href = this.favicons.offline.src;
          } else {
            link.href = this.favicons[color].src;
          }
        } else {
          link.href = this.favicons.offline.src;
        }
        // Append the favicon to the `head`
        document.getElementsByTagName('head')[0].appendChild(link);
      },
      deepWorkStart () {
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        if (blockActive) {
          this.elementStart(blockActive.id, 'track_deep');
          this.deepWorkState = 'active';
        }
      },
      deepWorkStop () {
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        if (blockActive) {
          this.elementEnd(blockActive.id);
          this.deepWorkState = 'done';
        }
      },
      deepWorkDismiss () {
        this.deepWorkState = false;
        this.deepWorkModal = false;
        this.deepWorkTotal = '00:00:00';
      },
      elementStart (blockId, trackType = 'track') {
        // stop any running block - there should always be just one
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');

        if (blockActive) {
          if (blockActive.id !== blockId) {
            this.elementEnd(blockActive.id);
          } else {
            this.elementEnd(blockId, false);
          }
        }

        let index = this.blocks.findIndex(item => item.id === blockId );
        const randomId = uuidv4();

        const element = {
          id: randomId,
          type: trackType,
          start: new Date().getTime(),
          end: null,
          size: null
        };

        if (trackType === 'track_deep') {
          if (this.deepWorkSize > 0) {
            element.size = this.deepWorkSize;
          }
        }

        const elements = JSON.parse(JSON.stringify(this.blocks[index].elements));

        this.$store.commit('elementStart', {
          index: index,
          element: element
        });

        if (this.currentUser) {
          const blockData = {
            elements: elements,
            activeSince: element.start,
            totalTime: this.blocks[index].totalTime
          };
          blockData.elements.push(element);
          updateBlock(this.blocks[index].refID, blockData)
              .then(() => {
              })
              .catch(() => {
                this.toast.error(`Something went wrong. We were not able to save that into DB.`)
              });

          // Track Block Block Start
          window.EngageKit.track('Block Block Tracked Start');
        }

        // Add to collectors
        if (this.blocks[index].collectors && this.blocks[index].collectors.length) {
          this.blocks[index].collectors.forEach((collector) => {
            let blockIndex = this.blocks.findIndex(item => item.id === collector.id);
            if (blockIndex !== -1) {
              const collectorElement = {
                id: element.id,
                start: element.start,
                end: null,
                value: collector.value,
                title: this.blocks[index].title,
                source: 'Block',
              };

              const collectorElements = JSON.parse(JSON.stringify(this.blocks[blockIndex].elements));

              this.$store.commit('elementStart', {
                index: blockIndex,
                element: collectorElement,
              });

              if (this.currentUser) {
                const collectorData = {
                  elements: collectorElements,
                  activeSince: collectorElement.start,
                  totalTime: this.blocks[blockIndex].totalTime
                };
                collectorData.elements.push(collectorElement);
                updateBlock(this.blocks[blockIndex].refID, collectorData)
                    .then(() => {
                    })
                    .catch(() => {
                      this.toast.error(`Something went wrong. We were not able to save that into DB.`)
                    });
              }
            }
          });
        }

        this.handleTrackingChange(true);
      },
      elementEnd (blockId, writeDB = true) {
        let index = this.blocks.findIndex(item => item.id === blockId );
        let n = new Date().getTime();
        let length = this.blocks[index].elements.length - 1;

        const elements = JSON.parse(JSON.stringify(this.blocks[index].elements));

        this.$store.commit('elementEnd', {
          index: index,
          last: length,
          end: n
        });

        if (this.currentUser && writeDB) {
          const blockData = {
            elements: elements,
            activeSince: 0,
            totalTime: 0
          };
          blockData.elements[length].end = n;
          const total = this.calculateElementsTotal(blockData.elements);
          blockData.totalTime = total;

          updateBlock(this.blocks[index].refID, blockData)
          .then(()=>{
          })
          .catch(() => {
            this.toast.error(`Something went wrong. We were not able to save the stop event into DB.`);
          });
        }

        // Add to collectors
        if (this.blocks[index].collectors && this.blocks[index].collectors.length) {
          this.blocks[index].collectors.forEach((collector) => {
            let blockIndex = this.blocks.findIndex(item => item.id === collector.id);
            if (blockIndex !== -1) {
              let collectorLength = this.blocks[blockIndex].elements.length - 1;
              const collectorElements = JSON.parse(JSON.stringify(this.blocks[blockIndex].elements));

              this.$store.commit('elementEnd', {
                index: blockIndex,
                last: collectorLength,
                end: n
              });

              if (this.currentUser && writeDB) {
                const collectorData = {
                  elements: collectorElements,
                  activeSince: 0,
                  totalTime: 0
                };

                collectorData.elements[collectorLength].end = n;
                const total = this.calculateElementsTotal(collectorData.elements);
                collectorData.totalTime = total;

                updateBlock(this.blocks[blockIndex].refID, collectorData)
                  .then(()=>{
                  })
                  .catch(() => {
                    this.toast.error(`Something went wrong. We were not able to save the stop event into DB.`);
                  });
              }

            }
          });
        }
      },
      elementIncrease (blockId, incTitle, incIcon, incValue) {
        let index = this.blocks.findIndex(item => item.id === blockId );
        const randomId = uuidv4();

        const element = {
          id: randomId,
          title: incTitle,
          icon: incIcon,
          start: new Date().getTime(),
          value: incValue
        };

        this.$store.commit('elementUpdate', {
          index: index,
          element: element
        });

        if (this.currentUser) {
          this.jobQueueAdd({event: 'blockUpdate_' + blockId, expiration: 2000});
        }
      },
      elementDecrease (blockId) {
        let index = this.blocks.findIndex(item => item.id === blockId );

        this.$store.commit('elementRemove', {
          index: index
        });

        if (this.currentUser) {
          this.jobQueueAdd({event: 'blockUpdate_' + blockId, expiration: 2000});
        }
      },
      countdownIncrement(blockId) {
        this.$store.commit('countdownIncrement', blockId);
      },
      countdownStart (blockId) {
        let index = this.blocks.findIndex(item => item.id === blockId );
        this.$store.commit('countdownStart', {
          index: index,
          start: new Date().getTime()
        });

        if (this.currentUser) {
          this.jobQueueAdd({event: 'blockUpdate_' + blockId, expiration: 2000});
        }
      },
      countdownEnd (blockId) {
        this.soundStop();
        let index = this.blocks.findIndex(item => item.id === blockId );
        this.$store.commit('countdownEnd', {
          index: index
        });

        if (this.currentUser) {
          this.jobQueueAdd({event: 'blockUpdate_' + blockId, expiration: 2000});
        }
      },
      calculateElementsTotal(elements) {
        let total = 0;
        elements.forEach((element) => {
          total = total + (element.end - element.start);
        });
        return total;
      },
      calculateTimerTotal() {
        let total = 0;
        total = this.blocks.reduce(function (total, block) {
          if (block && block.type === 'Block') {
            let blockTotal = 0;
            if (block.activeSince) {
              const now = new Date().getTime();
              blockTotal = now - block.activeSince;
            }
            blockTotal += Number(block.totalTime);
            blockTotal = blockTotal / 1000;
            blockTotal = ~~blockTotal * 1000;

            return total + blockTotal;
          } else {
            return total;
          }
        }, 0);

        if (total === 0) {
          this.timerTotal = '00:00:00';
        } else {
          const day = 86400 * 1000;
          let days = total / day;
          let daysDisplay = '';
          if (days >= 1) {
            if (days >= 2) {
              daysDisplay = ~~days + ' DAYS ';
            } else {
              daysDisplay = ~~days + ' DAY ';
            }
          }
          const date = new Date(null);
          date.setSeconds(total / 1000);
          const utc = date.toUTCString();
          this.timerTotal = daysDisplay + utc.substr(utc.indexOf(":") - 2, 8);
        }
      },
      calculateTimerSession() {
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        let total = 0;
        if (blockActive) {
          const now = new Date().getTime();
          total = now - blockActive.activeSince;
        }

        if (total === 0) {
          this.timerSession = '00:00:00';
        } else {
          const date = new Date(null);
          date.setSeconds(total / 1000);
          const utc = date.toUTCString();
          this.timerSession = utc.substr(utc.indexOf(":") - 2, 8);
        }
      },
      calculateDeepWork() {
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        let total = 0;
        if (blockActive) {
          const now = new Date().getTime();
          total = now - blockActive.activeSince;
        }

        if (total === 0) {
          this.deepWorkTotal = '00:00:00';
        } else {
          const date = new Date(null);
          date.setSeconds(total / 1000);
          const utc = date.toUTCString();
          this.deepWorkTotal = utc.substr(utc.indexOf(":") - 2, 8);
        }
      },
      userContent() {
        if (this.currentUser) {
          this.isLoading = true;
          this.resetEverything();
          this.projectsResetAll();
          this.notesResetAll();

          getProjects()
              .then(resp => {
                let projectsData = resp.data;
                let projects = [];

                projectsData.forEach(function (project) {
                  project.data.refID = project.ref.value.id;
                  projects.push(project.data);
                });
                this.$store.commit('projectsAdd', projects);
              })
              .catch(() => {
                this.toast.error(`We are not able to fetch projects from the database.`);
              });

          getNotes()
              .then(resp => {
                let notesData = resp.data;
                let notes = [];

                notesData.forEach(function (note) {
                  note.data.refID = note.ref.value.id;
                  notes.push(note.data);
                });
                this.$store.commit('notesAdd', notes);
              })
              .catch(() => {
                this.toast.error(`We are not able to fetch notes from the database.`);
              });

          getBlocks()
              .then(resp => {
                let blocksData = resp.data;
                let blocks = [];
                blocksData.forEach(function (block) {
                  if (block.data.isNew) delete block.data.isNew;
                  block.data.refID = block.ref.value.id;
                  blocks.push(block.data);
                });
                this.$store.commit('blocksAdd', blocks);
                this.getBoardsApp();
              })
              .catch(() =>
                this.toast.error(`We are not able to fetch blocks from the database.`)
              );

        } else {
          if (!this.boards.length) {
            this.boardAddDefault(false);
          }
        }
      },
      getBoardsApp() {
        getBoards()
            .then(resp => {
              let boardsData = resp.data;
              let board = null;
              boardsData.forEach(function (boardData) {
                board = boardData.data;
                board.refID = boardData.ref.value.id;
              });
              if (board === null) {
                // no board found
                // create default and save to db
                this.boardAddDefault(true);
              } else {
                // sanitize board
                board = this.boardSanitize(board);
                this.$store.commit('boardAdd', board);
                this.updateBoardBG(board.color);
                //this.$emit('updateBG', board.color);
              }
              this.isLoading = false;
            })
            .catch(() =>
                this.toast.error(`We are not able to fetch boards from the database.`)
            );
      },
      boardAddDefault(save = false) {
        let board = {
          title: "Default",
          id: uuidv4(),
          color: '',
          rows: [
            {
              title: "",
              order: 1,
              id: uuidv4(),
              color: null,
              cols: [
                {
                  title: "",
                  id: uuidv4(),
                  order: 1,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 2,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 3,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 4,
                  data: [],
                  color: null,
                }
              ]
            }
          ]
        };

        if (this.blocks.length) {
          // If there are blocks but no board, it means it is an older version
          this.blocks.forEach((block, index) => {
            let pos = index;
            if (index > 3) pos = 1;
            board.rows[0].cols[pos].data.push(
                {
                  id: index,
                  block: block.id
                }
            );
          });
        }

        this.boardAdd(board);
        if (save) {
          createBoard(board)
              .then((res) => {
                this.$store.commit('boardUpdateRef', {refID: res.ref.value.id});
              })
              .catch(() => {
                this.toast.error(`We are not able to create the default board.`)
              });
        }
      },
      deepWork() {
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        if (blockActive) {
          if (blockActive.elements[blockActive.elements.length - 1].type === 'track_deep' &&
              (blockActive.elements[blockActive.elements.length - 1].end === null ||
               typeof blockActive.elements[blockActive.elements.length - 1].end === 'undefined')
          ) {
            this.deepWorkState = 'active';
          }
        }
      },
      boardChange() {
        this.$store.commit('boardUpdate', this.boards[0]);
        if (this.currentUser) {
          this.jobQueueAdd({event: 'boardUpdate', expiration: 2000});
        }
      },
      boardSanitize(board) {
        if (board.rows.length) {
          board.rows.forEach((row, rowIndex) => {
            row.cols.forEach((col, colIndex) => {
              col.data.forEach((block, blockIndex) => {
                if (typeof this.blockIndexById(block.block) === "undefined") {
                  board.rows[rowIndex].cols[colIndex].data.splice(blockIndex, 1);
                }
              });
            });
          });
        }
        return board;
      },
      updateBoardBG(className) {
        const elBody = document.querySelector("body");
        const classIndexRemove = [];
        elBody.classList.forEach((element) => {
          if (element.includes('bg-grad-')) {
            classIndexRemove.push(element);
          } else if (element.includes('-100')) {
            classIndexRemove.push(element);
          }
        });
        if (classIndexRemove.length) {
          classIndexRemove.forEach((element) => {
            elBody.classList.remove(element);
          });
        }

        if (className) {
          if (className.includes('bg-')) {
            elBody.classList.add(className);
          } else {
            elBody.classList.add('bg-' + className + '-100');
          }
        }
      },
      jobQueueAdd(job) {
        /*
        {
          event: 'boardUpdate', // event function to call
          event: 'blockUpdate_1', // parameter passed with underscores
          expiration: 1000 // when to expire in milliseconds
        }
        */
        let index = this.jobQueue.findIndex(item => item.event === job.event);
        if (index >= 0) {
          let timeObject = new Date().getTime();
          const expiration = timeObject + job.expiration;
          this.jobQueue[index].expiration = expiration;
        } else {
          let timeObject = new Date().getTime();
          const expiration = timeObject + job.expiration;
          this.jobQueue.push({id: uuidv4(), event: job.event, expiration: expiration});
        }
      },
      jobQueueProcess() {
        // execute only if jobQueue is not running
        if (!this.jobQueueRunning) {
          this.jobQueueRunning = true;
          const jobsToRun = JSON.parse(JSON.stringify(this.jobQueue));
          //this.jobQueue.splice(0, this.jobQueue.length); // clear the queue so other events can be added and processed later

          jobsToRun.forEach((job) => {
            let timeNow = new Date().getTime();
            if (job.expiration < timeNow) {
              let jobEvent = job.event.split("_");
              if (jobEvent[0] === "boardUpdate") {
                let job_index = this.jobQueue.findIndex(item => item.id === job.id);
                this.jobQueue.splice(job_index, 1);
                delete this.boards[0].owner;
                updateBoard(this.boards[0].refID, this.boards[0])
                    .then(() => {
                    })
                    .catch(() => {
                      this.toast.error(`Something went wrong. We were not able to save the Board updates into DB.`)
                    });
              } else if (jobEvent[0] === "blockUpdate") {
                let job_index = this.jobQueue.findIndex(item => item.id === job.id);
                this.jobQueue.splice(job_index, 1);
                let block_index = this.blocks.findIndex(item => item.id === jobEvent[1]);
                if (block_index >= 0) {
                  const blockData = this.blocks[block_index];
                  updateBlock(blockData.refID, blockData)
                      .then(() => {
                      })
                      .catch(() => {
                        this.toast.error(`Something went wrong. We were not able to save that into DB.`)
                      });
                }
              }
            }
          });

          this.jobQueueRunning = false;
        }
      },
      dataPopulate() {
        this.$store.commit('blocksAdd', this.presets[0].blocks);
        const board = this.boardSanitize(this.presets[0].boards[0]);
        this.boardAdd(board);
      },
      encode(data) {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&')
      },
      setTrackerHeight() {
        let trackerNavHeight = document.getElementById("app-nav").clientHeight;//tracker-nav
        let viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        let trackerHeight = viewportHeight - trackerNavHeight;
        //document.getElementById("tracker-main").style.height = trackerHeight + 'px';
        document.querySelector("body").style.height = trackerHeight + 'px';
      },
      soundPlay() {
        this.audio = new Audio(sfx);
        this.audio.loop = true;
        this.audio.play();
      },
      soundStop() {
        if (this.audio !== null) {
          this.audio.pause();
          this.audio = null;
        }
      },
      notesEditStop() {
        this.notesEdited = false;
      },
      noteAdd(event) {
        event.preventDefault();
        const randomId = uuidv4();
        let start = new Date().getTime();

        const note = {
          id: randomId,
          ts: start,
          content: this.noteAddContent,
          refID: null,
          projectRef: this.noteAddProjectRef,
          blockRef: this.noteAddBlockRef
        };

        if (this.currentUser) {
          createNote(note)
              .then((res) => {
                this.$store.commit('noteAdd', note);
                this.$store.commit('noteUpdateRef', {id: note.id, refID: res.ref.value.id});
                this.toast.success(`Note has been created.`);
                this.noteAddContent = '';
                this.noteAddProjectRef = null;
                this.noteAddBlocktRef = null;
                this.noteAddModal = false;
              })
              .catch(() => {
                this.toast.error(`We are not able to create the note. Please try again.`);
              });
        }
      },
      noteAddModalPopulate() {
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        if (blockActive) {
          this.noteAddBlockRef = blockActive.id;
          this.noteAddProjectRef = blockActive.projectRef;
        } else {
          this.noteAddBlockRef = null;
          this.noteAddProjectRef = null;
        }
      },
      noteAddModalDismiss() {
        this.noteAddContent = '';
        this.noteAddProjectRef = null;
        this.noteAddBlocktRef = null;
        this.noteAddModal = false;
      },
      demoChangeColor() {
        // find block
        // change color
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        this.demoColor = this.demoColor + 1;
        let skinIndex = Math.floor(Math.random() * 4);
        blockActive.skin = this.skins[skinIndex].value;
        blockActive.color = this.colors[this.demoColor].value;
      },
      animateButton(id) {
        nextTick(() => {
          anime({
            targets: id,
            easing: 'easeOutQuad',
            duration: 175,
            scale: [{value: 0.9},{value: 1}],
            //paddingBottom: [30],
            //marginTop: [20, 0],
            backgroundColor: ['#6EE7B7', '#bbf7d0'],
            borderBottom: [1, 3],
            boxShadow: ['inset 0px 0px 0px #34D399, inset 0 0px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5', 'inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5'],
            complete: () => {
              anime.remove(id);
            }
          });
          anime({
            targets: id + ' svg',
            easing: 'easeOutQuad',
            duration: 175,
            //scale: [{value: 0.95},{value: 1}],
            paddingBottom: [0, 0],
            paddingTop: [4, 0],
            complete: () => {
              anime.remove(id);
            }
          });
        });
      },
      bezier(t, p0, p1, p2, p3) {
        var cX = 3 * (p1.x - p0.x),
            bX = 3 * (p2.x - p1.x) - cX,
            aX = p3.x - p0.x - cX - bX;

        var cY = 3 * (p1.y - p0.y),
            bY = 3 * (p2.y - p1.y) - cY,
            aY = p3.y - p0.y - cY - bY;

        var x = (aX * Math.pow(t, 3)) + (bX * Math.pow(t, 2)) + (cX * t) + p0.x;
        var y = (aY * Math.pow(t, 3)) + (bY * Math.pow(t, 2)) + (cY * t) + p0.y;

        return {x: x, y: y};
      },
      // el = element to animate
      // start El = starting element - from
      // end El = ending element - to
      animeFly(startEl, endEl, el) {
        const startElement = document.querySelector(startEl).getBoundingClientRect();
        const endElement = document.querySelector(endEl).getBoundingClientRect();
        const startX = ((startElement.width/2) - 20) + startElement.x;
        const startY = ((startElement.height/2) - 20) + startElement.y;
        const endX = ((endElement.width/2) - 20) + endElement.x;
        const endY = ((endElement.height/2) - 20) + endElement.y;

        const animeEl = document.querySelectorAll(el);

        setTimeout(() => {
          animeEl.forEach((el) => {
            el.style.transform='translateX('+ (startX) +'px) translateY('+ (startY) +'px)';
            el.style.opacity = 0.7;
            el.style.display = 'block';
            el.style.width = 40 + 'px';
            el.style.height = 40 + 'px';
            el.style.borderWidth = 1 + 'px';
          });

          let accuracy = 0.01; //this'll give the bezier 100 segments
          let p0 = {x: startX, y: startY - 6};
          let p1 = {x: startX + 20, y: startY - 370};
          let p2 = {x: endX - 500, y: endY};
          let p3 = {x: endX, y: endY};

          const pointsX = [];
          const pointsY = [];

          for (var i=0; i<1; i+=accuracy) {
            var p = this.bezier(i, p0, p1, p2, p3);
            pointsX.push(p.x);
            pointsY.push(p.y);
          }


          //let colorIndex = this.colors.findIndex(item => item.value === color );

          anime({
            loop: false,
            easing: 'easeInSine',
            duration: 800,
            targets: el,
            translateX: pointsX,
            translateY: pointsY,
            opacity: anime.stagger([0.3, 1]),
            scale: [0.5, 1],
            //width: [30, 30],
            //height: [30, 30],
            rotate: [180, 360],
            delay: anime.stagger(10, {direction: 'reverse'}),
            //backgroundColor: ['#6EE7B7', '#' + this.colors[colorIndex].colors[200]],
            //borderColor: ['#16A34A', '#' + this.colors[colorIndex].colors[700]],
            //borderWidth: [1, 2, 2, 2.5, 2.7],
            complete: () => {
              animeEl.forEach((el) => {
                //el.style.opacity=0;
                el.style.display='none';
              });
            }
          });

          let svgEl = document.querySelector(endEl + ' svg');
          svgEl.style.transform = 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)';

          anime({
            targets: endEl + ' svg',
            easing: 'linear',
            duration: 420,
            opacity: [1, 1],
            delay: 800,
            matrix3d: [
              '0.1, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.318, 0, 0, 0, 0, 0.318, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.448, 0, 0, 0, 0, 0.448, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.688, 0, 0, 0, 0, 0.688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.983, 0, 0, 0, 0, 0.983, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.011, 0, 0, 0, 0, 1.011, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.131, 0, 0, 0, 0, 1.131, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.147, 0, 0, 0, 0, 1.147, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.056, 0, 0, 0, 0, 1.056, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.129, 0, 0, 0, 0, 1.129, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.097, 0, 0, 0, 0, 1.097, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.023, 0, 0, 0, 0, 1.023, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.965, 0, 0, 0, 0, 0.965, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.967, 0, 0, 0, 0, 0.967, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1'
            ],
            complete: () => {
              anime.remove(endEl + ' svg');
            }
          });

        }, 0);
      },
      keyListener(event) {
        if (event.code === "KeyN" && event.altKey && !this.noteAddModal) {
          // Add a quick note
          this.noteAddModal = true;
          nextTick(() => {
            event.preventDefault();
            this.noteAddModalPopulate();
            this.noteAddContentRef.focus();
          });
        }
      }
    },
    created () {
      // Create the JS images for on/off favicons
      this.favicons = {
        'offline': new Image(),
        'bluegray': new Image(),
        'gray': new Image(),
        'truegray': new Image(),
        'warmgray': new Image(),
        'red': new Image(),
        'orange': new Image(),
        'amber': new Image(),
        'yellow': new Image(),
        'lime': new Image(),
        'green': new Image(),
        'emerald': new Image(),
        'teal': new Image(),
        'cyan': new Image(),
        'lightblue': new Image(),
        'blue': new Image(),
        'indigo': new Image(),
        'violet': new Image(),
        'purple': new Image(),
        'fuchsia': new Image(),
        'pink': new Image(),
        'rose': new Image()
      };

      // Set the source properties
      this.favicons.offline.src = require('@/assets/favicon/favicon-off.png');
      this.favicons.bluegray.src = require('@/assets/favicon/favicon-bluegray.png');
      this.favicons.gray.src = require('@/assets/favicon/favicon-gray.png');
      this.favicons.truegray.src = require('@/assets/favicon/favicon-truegray.png');
      this.favicons.warmgray.src = require('@/assets/favicon/favicon-warmgray.png');
      this.favicons.red.src = require('@/assets/favicon/favicon-red.png');
      this.favicons.orange.src = require('@/assets/favicon/favicon-orange.png');
      this.favicons.amber.src = require('@/assets/favicon/favicon-amber.png');
      this.favicons.yellow.src = require('@/assets/favicon/favicon-yellow.png');
      this.favicons.lime.src = require('@/assets/favicon/favicon-lime.png');
      this.favicons.green.src = require('@/assets/favicon/favicon-green.png');
      this.favicons.emerald.src = require('@/assets/favicon/favicon-emerald.png');
      this.favicons.teal.src = require('@/assets/favicon/favicon-teal.png');
      this.favicons.cyan.src = require('@/assets/favicon/favicon-cyan.png');
      this.favicons.lightblue.src = require('@/assets/favicon/favicon-lightblue.png');
      this.favicons.blue.src = require('@/assets/favicon/favicon-blue.png');
      this.favicons.indigo.src = require('@/assets/favicon/favicon-indigo.png');
      this.favicons.violet.src = require('@/assets/favicon/favicon-violet.png');
      this.favicons.purple.src = require('@/assets/favicon/favicon-purple.png');
      this.favicons.fuchsia.src = require('@/assets/favicon/favicon-fuchsia.png');
      this.favicons.pink.src = require('@/assets/favicon/favicon-pink.png');
      this.favicons.rose.src = require('@/assets/favicon/favicon-rose.png');
      this.handleTrackingChange(false);

      animationInterval(1000, this.controller.signal, () => {
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        if (blockActive) {
          if (blockActive.elements[blockActive.elements.length - 1].type === 'track_deep' &&
              (blockActive.elements[blockActive.elements.length - 1].end === null ||
              typeof blockActive.elements[blockActive.elements.length - 1].end === 'undefined')
          ) {
            this.calculateDeepWork();
          } else {
            // collector push to the collector from active block
            let seconds = Math.floor(((new Date().getTime() + 1000) / 1000) % 60);
            if (seconds % 5 === 0) {
              if (blockActive.collectors && blockActive.collectors.length) {
                // emit collector
                blockActive.collectors.forEach((collector) => {
                  let blockIndex = this.blocks.findIndex(item => item.id === collector.id);
                  if (blockIndex !== -1) {
                    // animate
                    let startEl = '#block-' + blockActive.id;
                    let endEl = '#block-' + this.blocks[blockIndex].id + ' .block-icon';
                    let el = '#block-' + blockActive.id + '-collector-' + collector.id;
                    this.animeFly(startEl, endEl, el);
                  }
                });
              }
            }
          }
          this.calculateTimerSession();
          this.blockIncrement(blockActive.id);
        }
        const blockCountdownActive = this.blocks.filter(block => block.activeCountdownSince > 0);
        if (blockCountdownActive.length > 0) {
          blockCountdownActive.forEach((block) => {
            this.countdownIncrement(block.id);
          });
        }
        let theUpdateTime = new Date().getTime() + 1000;
        if (Math.floor((theUpdateTime / 1000) % 60) % 5 === 0) {
          setTimeout(() => {
            this.blockTick = theUpdateTime;
          }, 900);
        }
      });
    },
    mounted () {
      this.calculateTimerTotal();
      this.calculateTimerSession();

      this.userContent();

      this.deepWork();
      this.jobQueueInterval = setInterval(() => {
        this.jobQueueProcess();
      }, 1000);
      // set background
      if(this.boards.length) {
        if (typeof this.boards[0].color !== "undefined") {
          if (this.boards[0].color.indexOf("bg-") >= 0) {
            this.updateBoardBG(this.boards[0].color);
            //this.$emit('updateBG', this.boards[0].color);
          } else {
            this.updateBoardBG('bg-' + this.boards[0].color + '-100');
            //this.$emit('updateBG', 'bg-' + this.boards[0].color + '-100');
          }
        }
      }
      // set height
      window.addEventListener("resize", this.setTrackerHeight);

      // Keyboard shortcut for Notes
      window.addEventListener("keydown", this.keyListener);

      // Worker
      if (window.Worker) {
        this.worker = new Worker();
        this.worker.postMessage('');
        this.worker.onmessage = () => {
          const blockCountdownActive = this.blocks.filter(block => block.activeCountdownSince > 0);
          if (blockCountdownActive.length > 0) {
            blockCountdownActive.forEach((block) => {
              if (!block.notificationSent) {
                let fullTime = block.size;
                const now = new Date().getTime();
                const elapsed = (now - block.activeCountdownSince) / 1000;
                fullTime = fullTime - elapsed;
                if (fullTime < 0) {
                  if (this.audio === null) {
                    this.soundPlay();
                  }
                  this.blockNotificationUpdate(block.id, true);
                  this.nativeNotification.show(
                      'Kairo: Time is up',
                      {
                        body: block.title + ' has finished.',
                        requireInteraction: true
                      },
                      {},
                  );
                }
              }
            });
          }
        };
      }

      nextTick(() => {
        this.setTrackerHeight();
      });
    },
    unmounted() {
      //this.$emit('updateBG', 'none');
      this.updateBoardBG(false);
      this.controller.abort();
      this.worker.terminate();
      window.removeEventListener("resize", this.setTrackerHeight);
      window.removeEventListener("keydown", this.keyListener);
    },
    beforeUnmount () {
      clearInterval(this.jobQueueInterval);
    },
  }
</script>
<style>
  .custom-outline {
    box-shadow: 0 0 1px 1px rgba(100,100,100,0.2);
    background-color: rgba(100,100,100,0.1);
  }
  .col-transition {
    transition-property: background-color, box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
  }
  .block-drag-class .block-component {
    height: 90px !important;
    overflow: hidden;
  }
  .block-drag-class .block-component canvas,
  .block-drag-class .block-component [id^='block-face-'] svg {
    display: none;
  }
  .bounce-enter-active {
    animation: bounceme .6s linear both;
  }
  .bounce-leave-active {
    animation: bounce-in .2s reverse;
  }
  .bounce-add {
    animation: bounceme .7s linear .4s both;
  }

  @keyframes bounceopacity {
    0% {
      display: none;
      opacity: 0;
    }
    1% {
      display: block;
      opacity: 0;
    }
    100% {
      display: block;
      opacity: 1;
    }
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  .bg-grad-1 {
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  }
  .bg-grad-2 {
    background-color: #0093E9;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
  }
  .bg-grad-3 {
    background-color: #74EBD5;
    background-image: linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%);
  }
  .bg-grad-4 {
    background-color: #FBAB7E;
    background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
  }
  .bg-grad-5 {
    background-color: #85FFBD;
    background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);
  }
  .bg-grad-6 {
    background-color: #8BC6EC;
    background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
  }
  .bg-grad-7 {
    background-color: #A9C9FF;
    background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);
  }
  .bg-grad-8 {
    background-color: #FF3CAC;
    background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
  }


  /* Generated with Bounce.js. Edit at http://bouncejs.com#%7Bs%3A%5B%7BT%3A%22c%22%2Ce%3A%22b%22%2Cd%3A500%2CD%3A0%2Cf%3A%7Bx%3A0.2%2Cy%3A0.2%7D%2Ct%3A%7Bx%3A1%2Cy%3A1%7D%2Cs%3A1%2Cb%3A4%7D%2C%7BT%3A%22c%22%2Ce%3A%22b%22%2Cd%3A500%2CD%3A0%2Cf%3A%7Bx%3A0.5%2Cy%3A0.5%7D%2Ct%3A%7Bx%3A1%2Cy%3A1%7D%2Cs%3A1%2Cb%3A6%7D%5D%7D */
  @keyframes bounceme {
    0% { -webkit-transform: matrix3d(0.1, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.1, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);opacity: 0;}
    3.4% { -webkit-transform: matrix3d(0.318, 0, 0, 0, 0, 0.318, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.318, 0, 0, 0, 0, 0.318, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);opacity: 0.9; }
    4.7% { -webkit-transform: matrix3d(0.448, 0, 0, 0, 0, 0.448, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.448, 0, 0, 0, 0, 0.448, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    6.81% { -webkit-transform: matrix3d(0.688, 0, 0, 0, 0, 0.688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.688, 0, 0, 0, 0, 0.688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    9.41% { -webkit-transform: matrix3d(0.983, 0, 0, 0, 0, 0.983, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.983, 0, 0, 0, 0, 0.983, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    10.21% { -webkit-transform: matrix3d(1.061, 0, 0, 0, 0, 1.061, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.061, 0, 0, 0, 0, 1.061, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    13.61% { -webkit-transform: matrix3d(1.281, 0, 0, 0, 0, 1.281, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.281, 0, 0, 0, 0, 1.281, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    14.11% { -webkit-transform: matrix3d(1.297, 0, 0, 0, 0, 1.297, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.297, 0, 0, 0, 0, 1.297, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    17.52% { -webkit-transform: matrix3d(1.306, 0, 0, 0, 0, 1.306, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.306, 0, 0, 0, 0, 1.306, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    18.72% { -webkit-transform: matrix3d(1.279, 0, 0, 0, 0, 1.279, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.279, 0, 0, 0, 0, 1.279, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    21.32% { -webkit-transform: matrix3d(1.197, 0, 0, 0, 0, 1.197, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.197, 0, 0, 0, 0, 1.197, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    24.32% { -webkit-transform: matrix3d(1.098, 0, 0, 0, 0, 1.098, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.098, 0, 0, 0, 0, 1.098, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    25.23% { -webkit-transform: matrix3d(1.073, 0, 0, 0, 0, 1.073, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.073, 0, 0, 0, 0, 1.073, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    29.03% { -webkit-transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    29.93% { -webkit-transform: matrix3d(0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    35.54% { -webkit-transform: matrix3d(0.965, 0, 0, 0, 0, 0.965, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.965, 0, 0, 0, 0, 0.965, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    36.74% { -webkit-transform: matrix3d(0.967, 0, 0, 0, 0, 0.967, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.967, 0, 0, 0, 0, 0.967, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    41.04% { -webkit-transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    44.44% { -webkit-transform: matrix3d(0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    52.15% { -webkit-transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    59.86% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    63.26% { -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    75.28% { -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    85.49% { -webkit-transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    90.69% { -webkit-transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);opacity: 1; }
  }

  .anime-container {
    top: 0;
    left: 0;
    z-index: 100;
  }
  .collector-anim {
    top: 0;
    left: 0;
    z-index: 39;
  }

</style>
