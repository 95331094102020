<template>
  <div class="fixed inset-0 overflow-hidden z-50">
    <div class="absolute inset-0 overflow-hidden">
      <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" aria-labelledby="slide-over-heading">
        <div class="w-screen max-w-2xl">
          <div class="h-full flex flex-col bg-white shadow-xl">
            <div class="min-h-0 flex-1 flex flex-col overflow-y-scroll">
              <!-- Header -->
              <div
                class="px-4 py-6 sm:px-6"
                :class="'bg-' + block.color + '-100'"
              >
                <div class="flex items-center justify-between space-x-3">
                  <div class="flex-none">
                    <div
                      class="items-center w-10 h-10 rounded-full"
                      :class="'bg-' + block.color + '-200'"
                    >
                    </div>
                  </div>
                  <div class="space-y-1 flex-grow">
                    <h2 class="text-lg font-medium text-gray-900">
                      {{block.title}}
                    </h2>
                  </div>
                  <div class="h-7 flex-none items-center">
                    <button
                      @click="blockEditStop"
                      class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      type="button"
                    >
                      <span class="sr-only">Close panel</span>
                      <!-- Heroicon name: outline/x -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div class="border-b border-gray-200 mt-6">
                <div class="px-6">
                  <nav class="-mb-px flex space-x-6">
                    <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
                    <a @click="mode = 'info'"
                       href="#"
                       class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                       :class="{'border-indigo-500 text-indigo-600 hover:text-indigo-600 hover:border-indigo-500' : mode === 'info'}"
                    >
                      Info
                    </a>
                    <a @click="mode = 'embed'"
                       href="#"
                       class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                       :class="{'border-indigo-500 text-indigo-600 hover:text-indigo-600 hover:border-indigo-500' : mode === 'embed'}"
                    >
                      Embed
                    </a>
                  </nav>
                </div>
              </div>

              <!-- Divider container -->
              <div v-if="mode==='info'" class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <div>
                    <label for="block_title" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Title
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <input v-model="title" type="text" name="block_title" id="block_title" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                  </div>
                </div>

                <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <div>
                    <label for="block_link" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Link
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <input v-model="link" type="text" name="block_link" id="block_link" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" placeholder="https://google.com">
                  </div>
                </div>

                <!-- Colors -->
                <div class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
                  <div>
                    <h3 class="text-sm font-medium text-gray-900">
                      Color
                    </h3>
                  </div>
                  <div class="sm:col-span-2">
                      <div v-for="colorOption in colors" v-bind:key="colorOption.value"
                          class="inline-block rounded-full h-10 w-10 m-2 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 shadow border-white border-2"
                          :class="'bg-' + colorOption.value + '-400 hover:bg-' + colorOption.value + '-700 focus:ring-' + colorOption.value + '-500'"
                          @click="blockChangeColor(colorOption.value)"
                      >
                        <svg v-if="colorOption.value === color" className="inline-block h-6 w-6 rounded-full" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                      </div>
                  </div>
                </div>

                <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <div>
                    <label for="block_image_link" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Image URL
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <input v-model="imageLink" type="text" name="block_image_link" id="block_image_link" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md" placeholder="https://images.google.com/image.png">
                  </div>
                </div>
                <!-- Faces -->
                <div class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
                  <div>
                    <h3 class="text-sm font-medium text-gray-900">
                      Icon
                    </h3>
                  </div>
                  <div class="sm:col-span-2">
                      <div v-for="faceOption in faces" v-bind:key="faceOption.value"
                          class="inline-block relative rounded-md h-10 w-10 m-2 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 shadow border-white border-2"
                          :class="'bg-' + color + '-50 hover:bg-' + color + '-700 focus:ring-' + color + '-500'"
                          @click="blockChangeFace(faceOption.value)"
                      >
                        <BlockIcon
                          v-bind:type="faceOption.value"
                          v-bind:isPreview="true"
                          v-bind:color="color"
                        ></BlockIcon>
                        <svg v-if="faceOption.value === faceType" class="rounded-full absolute top-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                      </div>
                  </div>
                </div>

              </div>

              <div v-else class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                <div v-if="!currentUser" class="rounded-md shadow-md bg-yellow-50 p-4 m-4">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: solid/exclamation -->
                      <svg class="border-2 border-yellow-200 h-12 w-12 text-yellow-400 bg-yellow-100 p-2 rounded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                      </svg>
                    </div>
                    <div class="ml-3 flex-grow">
                      <h3 class="text-md font-medium text-yellow-800">
                        You are using free version of Kairo which does not store data, hence embeddables are not available.
                      </h3>
                      <div class="mt-1 text-sm text-yellow-700">
                        <p>
                          <router-link
                                  to="signup"
                                  class="underline">
                            Sign up and subscribe</router-link> to keep your data and access reports from anywhere to improve your productivity.
                        </p>
                      </div>
                    </div>
                    <div class="flex-shrink">
                      <router-link
                              to="signup"
                              class="mt-2 inline-flex items-center px-3 py-2 border-2 border-yellow-200 text-sm leading-4 font-medium rounded-md text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                      >Start trial
                      </router-link>
                    </div>
                  </div>
                </div>

                <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <div>
                    <label for="block_title" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Embed
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <div class="flex mx-2 text-xs">
                      <button @click="embedToggle" type="button"
                              :disabled="!currentUser"
                              class="mt-1 bg-gray-200 relative inline-flex flex-shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              :class="{'bg-indigo-600': embedId}"
                              role="switch" aria-checked="false">
                        <span class="sr-only">Use setting</span>
                        <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                        <span class="translate-x-0 pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                              :class="{'translate-x-4': embedId}"
                        >
                      <!-- Enabled: "opacity-0 ease-out duration-100", Not Enabled: "opacity-100 ease-in duration-200" -->
                      <span class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                            aria-hidden="true"
                            :class="{'opacity-100 ease-in duration-200': !embedId}"
                      >
                        <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                          <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </span>
                          <!-- Enabled: "opacity-100 ease-in duration-200", Not Enabled: "opacity-0 ease-out duration-100" -->
                      <span class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                            :class="{'opacity-100 ease-in duration-200': embedId}"
                            aria-hidden="true">
                        <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                          <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                      </span>
                    </span>
                      </button>
                    </div>
                  </div>

                </div>

                <div v-if="embedId" class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <div>
                    <label for="block_value" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Embed URL
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <div class="max-w-lg flex rounded-md shadow-sm relative">
                      <input disabled v-model="embedURL" id="embedURL" type="text" name="embedURL" class="flex-1 block w-full focus:ring-indigo-500 focus:border-indigo-500 min-w-0 bg-gray-50 rounded-none rounded-l-md sm:text-sm border-gray-300">
                      <button @click="doCopy(embedURL)" class="inline-flex items-center px-3 rounded-r-md border border-black border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3" />
                        </svg>
                      </button>
                      <span id="copy-status" class="absolute text-green-600 -top-5 right-0 text-sm opacity-0">Copied!</span>
                    </div>
                  </div>
                </div>
                <div v-if="embedId" class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <div>
                    <label for="block_value" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Mode
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <select v-model="embedOptions.mode" name="mode_type" class="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                      <option value="view">View</option>
                    </select>
                  </div>
                </div>

                <div v-if="embedId" class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <div>
                    <label for="block_title" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Dark mode
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <div class="flex mx-2 text-xs">
                      <button @click="embedOptions.darkMode = !embedOptions.darkMode" type="button"
                              class="mt-1 bg-gray-200 relative inline-flex flex-shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                              :class="{'bg-indigo-600': embedOptions.darkMode}"
                              role="switch" aria-checked="false">
                        <span class="sr-only">Use setting</span>
                        <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                        <span class="translate-x-0 pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                              :class="{'translate-x-4': embedOptions.darkMode}"
                        >
                      <!-- Enabled: "opacity-0 ease-out duration-100", Not Enabled: "opacity-100 ease-in duration-200" -->
                      <span class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                            aria-hidden="true"
                            :class="{'opacity-100 ease-in duration-200': !embedOptions.darkMode}"
                      >
                        <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                          <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </span>
                          <!-- Enabled: "opacity-100 ease-in duration-200", Not Enabled: "opacity-0 ease-out duration-100" -->
                      <span class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                            :class="{'opacity-100 ease-in duration-200': embedOptions.darkMode}"
                            aria-hidden="true">
                        <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                          <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                      </span>
                    </span>
                      </button>
                    </div>
                  </div>

                </div>

              </div>

            </div>

            <!-- Action buttons -->
            <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
              <div class="space-x-3 flex justify-end">
                <button @click="blockEditStop" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Cancel
                </button>
                <button @click="blockUpdate" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import {colors} from '../colors/colors.js';
  import BlockIcon from './BlockIcon.vue';
  import {ref} from "vue";
  import {mapGetters} from "vuex";
  import { copyText } from 'vue3-clipboard';
  import anime from "animejs";
  import {v4 as uuidv4} from "uuid";

  export default {
    name: 'BlockEdit',
    components: {
      BlockIcon,
    },
    props: {
      id: String,
      block: Object,
      allowTemplateAdd: Boolean
    },
    setup() {
      const formatter = ref({
        date: 'DD MMM YYYY HH:mm',
        month: 'MMM'
      });
      const doCopy = (url) => {
        copyText(url, undefined, (error) => {
          if (!error) {
            anime({
              targets: '#copy-status',
              opacity: [{value: 100}, {value: 0}],
              easing: 'easeInOutSine',
              duration: 1200
            });
          }
        })
      };
      return {
        formatter, doCopy
      };
    },
    data() {
      return {
        mode: 'info',
        embedId: this.block.embedId ? JSON.parse(JSON.stringify(this.block.embedId)) : null,
        embedOptions: this.block.embedOptions ? JSON.parse(JSON.stringify(this.block.embedOptions)) : {mode: 'view', darkMode: false},
        title: JSON.parse(JSON.stringify(this.block.title)),
        link: JSON.parse(JSON.stringify(this.block.link)),
        imageLink: JSON.parse(JSON.stringify(this.block.imageLink)),
        color: JSON.parse(JSON.stringify(this.block.color)),
        faceType: this.getFace(this.block.faceType),
        iconEdited: null,
        faces: [
          {text: 'default', value: 'default'},
          {text: 'flex', value: 'flex'},
          {text: 'money', value: 'money'},
          {text: 'coffee', value: 'coffee'},
          {text: 'death', value: 'death'},
          {text: 'happy', value: 'happy'},
          {text: 'headboom', value: 'headboom'},
          {text: 'sad', value: 'sad'},
          {text: 'cow', value: 'cow'},
          {text: 'star', value: 'star'},
          {text: 'beer', value: 'beer'},
          {text: 'radioactive', value: 'radioactive'},
          {text: 'banana', value: 'banana'},
          {text: 'rainbow', value: 'rainbow'},
          {text: 'toiletpaper', value: 'toiletpaper'},
          {text: 'phone', value: 'phone'},
          {text: 'donut', value: 'donut'},
          {text: 'book', value: 'book'},
          {text: 'tea', value: 'tea'},
          {text: 'wine', value: 'wine'},
          {text: 'cocktail', value: 'cocktail'}
        ],
        colors,
      }
    },
    computed: {
      ...mapGetters("auth", ["currentUser"]),
      embedURL() {
        return 'https://app.getkairo.com/embed?id=' + this.embedId;
      },
    },
    methods: {
      getFace(faceType) {
        if (typeof faceType === 'undefined') {
          return "default";
        }
        return faceType;
      },
      blockEditStop() {
        this.$emit('blockEditStop');
      },
      blockChangeColor(color) {
        this.color = color;
      },
      blockChangeFace(face) {
        this.faceType = face;
      },
      blockUpdate() {
        // prepare structure to save
        const data = {};
        if (this.title !== this.block.title) {
          data.title = JSON.parse(JSON.stringify(this.title));
        }
        if (this.link !== this.block.link) {
          data.link = JSON.parse(JSON.stringify(this.link));
        }
        if (this.imageLink !== this.block.imageLink) {
          data.imageLink = JSON.parse(JSON.stringify(this.imageLink));
        }
        if (this.color !== this.block.color) {
          data.color = JSON.parse(JSON.stringify(this.color));
        }
        if (this.faceType !== this.block.faceType) {
          data.faceType = JSON.parse(JSON.stringify(this.faceType));
        }

        if (this.embedId !== this.block.embedId) {
          data.embedId = JSON.parse(JSON.stringify(this.embedId));
        }
        if (this.embedId === null) {
          this.embedOptions = null;
        }
        if (this.embedOptions !== this.block.embedOptions) {
          data.embedOptions = JSON.parse(JSON.stringify(this.embedOptions));
        }

        if (Object.keys(data).length) {
          this.$emit('blockUpdate', this.block.refID, this.block.id, data);
        }
        this.blockEditStop();
      },
      embedToggle() {
        if (this.embedId) {
          this.embedId = null;
        } else {
          this.embedId = uuidv4();
        }
      },
    }
  }
</script>
