import { createRouter, createWebHistory } from 'vue-router'
import Tracker from '../views/Tracker.vue'
import store from "../store/store";

const routes = [
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Reports3.vue'),
    meta: {
      title: 'Reports',
      planRequired: true,
      userRequired: true
    },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
    meta: {
      title: 'Projects',
      userRequired: true
    },
  },
  {
    path: '/',
    name: 'Tracker',
    component: Tracker,
    meta: {
      title: 'Tracker',
      planRequired: true,
      userRequired: true
    },
  },
  {
    path: '/build',
    name: 'Tracker Build',
    component: () => import(/* webpackChunkName: "trackerBuild" */ '../views/TrackerBuild.vue'),
    meta: {
      title: 'Tracker Build',
      planRequired: true,
      userRequired: true
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      title: 'Profile',
      userRequired: true
    }
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: () => import(/* webpackChunkName: "subscription" */ '../views/Subscription.vue'),
    meta: {
      title: 'Subscription',
      userRequired: true
    }
  },
  {
    path: "/profile-edit",
    name: "Profile edit",
    component: () => import(/* webpackChunkName: "profileEdit" */ '../views/ProfileEdit.vue'),
    meta: {
      title: 'Profile edit',
      userRequired: true
    }
  },
  {
    path: "/add-calendar",
    name: "Add to calendar",
    component: () => import(/* webpackChunkName: "addCalendar" */ '../views/AddCalendar.vue'),
    meta: {
      title: 'Add to calendar',
      userRequired: true
    }
  },
  {
    path: "/share-social",
    name: "Share on social",
    component: () => import(/* webpackChunkName: "shareSocial" */ '../views/ShareSocial.vue'),
    meta: {
      title: 'Share on social',
      userRequired: true
    }
  },
  {
    path: "/status/:id",
    name: "Status",
    component: () => import(/* webpackChunkName: "status" */ '../views/Status.vue'),
    meta: {
      title: 'Status'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      title: 'Login to continue'
    },
  },
  {
    path: '/signup',
    name: 'Sign up',
    component: () => import(/* webpackChunkName: "signup" */ '../views/SignUp.vue'),
    meta: {
      title: 'Sign up'
    },
  },
  {
    path: "/recover",
    name: "Recover password",
    component: () => import(/* webpackChunkName: "recover" */ '../views/RecoverAccount.vue'),
    meta: {
      title: 'Recover password'
    }
  },
  {
    path: '/:pathMatch(.*)',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
    meta: {
      title: '404'
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'bg-gray-100 text-white',
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  }
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (typeof window.InlineManual !== 'undefined') {
    window.InlineManual('update');
  }

  const planRequired = to.matched.some(route => route.meta.planRequired);
  const userRequired = to.matched.some(route => route.meta.userRequired);
  // If the route doesnt have a `meta.planRequired` property go on ahead!
  if (!planRequired && !userRequired) {
    return next();
  }
  // If we go this far then it must have the `meta.authRequired`. But is there a user logged in? If so, then go right on ahead!
  if (store.getters["auth/loggedIn"]) {
    const currentUser = store.getters["auth/currentUser"];
    // User is on free, show them Subscription page :)
    if (currentUser.app_metadata && currentUser.app_metadata.roles.length) {
      if (currentUser.app_metadata.roles[0] === 'free') {
        return next({ name: "Subscription", query: { redirectFrom: to.fullPath } });
      }

      if (currentUser.lock && to.name !== 'Subscription' && to.name !== 'Profile edit' && to.name !== 'Profile') {
        return next({ name: "Subscription", query: { redirectFrom: to.fullPath } });
      }
    }
  } else {
    return next({ name: "Login", query: { redirectFrom: to.fullPath } });
  }
  return next();
});


router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' | Kairo';
  }

  if (store.getters["auth/loggedIn"]) {
    const currentUser = store.getters["auth/currentUser"];
    if (currentUser.app_metadata && currentUser.app_metadata.roles.length) {
      if (currentUser.app_metadata && (currentUser.app_metadata.status === 'trialing' || currentUser.app_metadata.status === 'trial_expired')) {
        if (typeof window.EngageKit !== 'undefined') {   
          window.EngageKit.ready(() => {
            window.EngageKit.track(
              'Page ' + to.meta.title,
              { url: to.fullPath }
            )
          });
        }
      }
    }
  }

});

export default router
