export const icons = [
  {text: 'default', value: 'default'},
  {text: 'flex', value: 'flex'},
  {text: 'money', value: 'money'},
  {text: 'coffee', value: 'coffee'},
  {text: 'death', value: 'death'},
  {text: 'happy', value: 'happy'},
  {text: 'headboom', value: 'headboom'},
  {text: 'sad', value: 'sad'},
  {text: 'cow', value: 'cow'},
  {text: 'star', value: 'star'},
  {text: 'beer', value: 'beer'},
  {text: 'radioactive', value: 'radioactive'},
  {text: 'banana', value: 'banana'},
  {text: 'rainbow', value: 'rainbow'},
  {text: 'toiletpaper', value: 'toiletpaper'},
  {text: 'phone', value: 'phone'},
  {text: 'donut', value: 'donut'},
  {text: 'book', value: 'book'},
  {text: 'tea', value: 'tea'},
  {text: 'wine', value: 'wine'},
  {text: 'cocktail', value: 'cocktail'}
];

export const twemoji = [
  {
    "name": "people",
    "unicode": "1f642",
    "char": "🙂",
    "emojis": [
      {
        "name": "grinning face",
        "unicode": "1f600",
        "char": "😀"
      },
      {
        "name": "grinning face with smiling eyes",
        "unicode": "1f601",
        "char": "😁"
      },
      {
        "name": "face with tears of joy",
        "unicode": "1f602",
        "char": "😂"
      },
      {
        "name": "rolling on the floor laughing",
        "unicode": "1f923",
        "char": "🤣"
      },
      {
        "name": "smiling face with open mouth",
        "unicode": "1f603",
        "char": "😃"
      },
      {
        "name": "smiling face with open mouth and smiling eyes",
        "unicode": "1f604",
        "char": "😄"
      },
      {
        "name": "smiling face with open mouth and cold sweat",
        "unicode": "1f605",
        "char": "😅"
      },
      {
        "name": "smiling face with open mouth and tightly-closed eyes",
        "unicode": "1f606",
        "char": "😆"
      },
      {
        "name": "winking face",
        "unicode": "1f609",
        "char": "😉"
      },
      {
        "name": "smiling face with smiling eyes",
        "unicode": "1f60a",
        "char": "😊"
      },
      {
        "name": "face savouring delicious food",
        "unicode": "1f60b",
        "char": "😋"
      },
      {
        "name": "smiling face with sunglasses",
        "unicode": "1f60e",
        "char": "😎"
      },
      {
        "name": "smiling face with heart-shaped eyes",
        "unicode": "1f60d",
        "char": "😍"
      },
      {
        "name": "face throwing a kiss",
        "unicode": "1f618",
        "char": "😘"
      },
      {
        "name": "kissing face",
        "unicode": "1f617",
        "char": "😗"
      },
      {
        "name": "kissing face with smiling eyes",
        "unicode": "1f619",
        "char": "😙"
      },
      {
        "name": "kissing face with closed eyes",
        "unicode": "1f61a",
        "char": "😚"
      },
      {
        "name": "white smiling face",
        "unicode": "263a",
        "char": "☺"
      },
      {
        "name": "slightly smiling face",
        "unicode": "1f642",
        "char": "🙂"
      },
      {
        "name": "hugging face",
        "unicode": "1f917",
        "char": "🤗"
      },
      {
        "name": "thinking face",
        "unicode": "1f914",
        "char": "🤔"
      },
      {
        "name": "neutral face",
        "unicode": "1f610",
        "char": "😐"
      },
      {
        "name": "expressionless face",
        "unicode": "1f611",
        "char": "😑"
      },
      {
        "name": "face without mouth",
        "unicode": "1f636",
        "char": "😶"
      },
      {
        "name": "face with rolling eyes",
        "unicode": "1f644",
        "char": "🙄"
      },
      {
        "name": "smirking face",
        "unicode": "1f60f",
        "char": "😏"
      },
      {
        "name": "persevering face",
        "unicode": "1f623",
        "char": "😣"
      },
      {
        "name": "disappointed but relieved face",
        "unicode": "1f625",
        "char": "😥"
      },
      {
        "name": "face with open mouth",
        "unicode": "1f62e",
        "char": "😮"
      },
      {
        "name": "zipper-mouth face",
        "unicode": "1f910",
        "char": "🤐"
      },
      {
        "name": "hushed face",
        "unicode": "1f62f",
        "char": "😯"
      },
      {
        "name": "sleepy face",
        "unicode": "1f62a",
        "char": "😪"
      },
      {
        "name": "tired face",
        "unicode": "1f62b",
        "char": "😫"
      },
      {
        "name": "sleeping face",
        "unicode": "1f634",
        "char": "😴"
      },
      {
        "name": "relieved face",
        "unicode": "1f60c",
        "char": "😌"
      },
      {
        "name": "nerd face",
        "unicode": "1f913",
        "char": "🤓"
      },
      {
        "name": "face with stuck-out tongue",
        "unicode": "1f61b",
        "char": "😛"
      },
      {
        "name": "face with stuck-out tongue and winking eye",
        "unicode": "1f61c",
        "char": "😜"
      },
      {
        "name": "face with stuck-out tongue and tightly-closed eyes",
        "unicode": "1f61d",
        "char": "😝"
      },
      {
        "name": "drooling face",
        "unicode": "1f924",
        "char": "🤤"
      },
      {
        "name": "unamused face",
        "unicode": "1f612",
        "char": "😒"
      },
      {
        "name": "face with cold sweat",
        "unicode": "1f613",
        "char": "😓"
      },
      {
        "name": "pensive face",
        "unicode": "1f614",
        "char": "😔"
      },
      {
        "name": "confused face",
        "unicode": "1f615",
        "char": "😕"
      },
      {
        "name": "upside-down face",
        "unicode": "1f643",
        "char": "🙃"
      },
      {
        "name": "money-mouth face",
        "unicode": "1f911",
        "char": "🤑"
      },
      {
        "name": "astonished face",
        "unicode": "1f632",
        "char": "😲"
      },
      {
        "name": "white frowning face",
        "unicode": "2639",
        "char": "☹"
      },
      {
        "name": "slightly frowning face",
        "unicode": "1f641",
        "char": "🙁"
      },
      {
        "name": "confounded face",
        "unicode": "1f616",
        "char": "😖"
      },
      {
        "name": "disappointed face",
        "unicode": "1f61e",
        "char": "😞"
      },
      {
        "name": "worried face",
        "unicode": "1f61f",
        "char": "😟"
      },
      {
        "name": "face with look of triumph",
        "unicode": "1f624",
        "char": "😤"
      },
      {
        "name": "crying face",
        "unicode": "1f622",
        "char": "😢"
      },
      {
        "name": "loudly crying face",
        "unicode": "1f62d",
        "char": "😭"
      },
      {
        "name": "frowning face with open mouth",
        "unicode": "1f626",
        "char": "😦"
      },
      {
        "name": "anguished face",
        "unicode": "1f627",
        "char": "😧"
      },
      {
        "name": "fearful face",
        "unicode": "1f628",
        "char": "😨"
      },
      {
        "name": "weary face",
        "unicode": "1f629",
        "char": "😩"
      },
      {
        "name": "grimacing face",
        "unicode": "1f62c",
        "char": "😬"
      },
      {
        "name": "face with open mouth and cold sweat",
        "unicode": "1f630",
        "char": "😰"
      },
      {
        "name": "face screaming in fear",
        "unicode": "1f631",
        "char": "😱"
      },
      {
        "name": "flushed face",
        "unicode": "1f633",
        "char": "😳"
      },
      {
        "name": "dizzy face",
        "unicode": "1f635",
        "char": "😵"
      },
      {
        "name": "pouting face",
        "unicode": "1f621",
        "char": "😡"
      },
      {
        "name": "angry face",
        "unicode": "1f620",
        "char": "😠"
      },
      {
        "name": "smiling face with halo",
        "unicode": "1f607",
        "char": "😇"
      },
      {
        "name": "face with cowboy hat",
        "unicode": "1f920",
        "char": "🤠"
      },
      {
        "name": "clown face",
        "unicode": "1f921",
        "char": "🤡"
      },
      {
        "name": "lying face",
        "unicode": "1f925",
        "char": "🤥"
      },
      {
        "name": "face with medical mask",
        "unicode": "1f637",
        "char": "😷"
      },
      {
        "name": "face with thermometer",
        "unicode": "1f912",
        "char": "🤒"
      },
      {
        "name": "face with head-bandage",
        "unicode": "1f915",
        "char": "🤕"
      },
      {
        "name": "nauseated face",
        "unicode": "1f922",
        "char": "🤢"
      },
      {
        "name": "sneezing face",
        "unicode": "1f927",
        "char": "🤧"
      },
      {
        "name": "smiling face with horns",
        "unicode": "1f608",
        "char": "😈"
      },
      {
        "name": "imp",
        "unicode": "1f47f",
        "char": "👿"
      },
      {
        "name": "japanese ogre",
        "unicode": "1f479",
        "char": "👹"
      },
      {
        "name": "japanese goblin",
        "unicode": "1f47a",
        "char": "👺"
      },
      {
        "name": "skull",
        "unicode": "1f480",
        "char": "💀"
      },
      {
        "name": "ghost",
        "unicode": "1f47b",
        "char": "👻"
      },
      {
        "name": "extraterrestrial alien",
        "unicode": "1f47d",
        "char": "👽"
      },
      {
        "name": "robot face",
        "unicode": "1f916",
        "char": "🤖"
      },
      {
        "name": "pile of poo",
        "unicode": "1f4a9",
        "char": "💩"
      },
      {
        "name": "smiling cat face with open mouth",
        "unicode": "1f63a",
        "char": "😺"
      },
      {
        "name": "grinning cat face with smiling eyes",
        "unicode": "1f638",
        "char": "😸"
      },
      {
        "name": "cat face with tears of joy",
        "unicode": "1f639",
        "char": "😹"
      },
      {
        "name": "smiling cat face with heart-shaped eyes",
        "unicode": "1f63b",
        "char": "😻"
      },
      {
        "name": "cat face with wry smile",
        "unicode": "1f63c",
        "char": "😼"
      },
      {
        "name": "kissing cat face with closed eyes",
        "unicode": "1f63d",
        "char": "😽"
      },
      {
        "name": "weary cat face",
        "unicode": "1f640",
        "char": "🙀"
      },
      {
        "name": "crying cat face",
        "unicode": "1f63f",
        "char": "😿"
      },
      {
        "name": "pouting cat face",
        "unicode": "1f63e",
        "char": "😾"
      },
      {
        "name": "boy",
        "unicode": "1f466",
        "char": "👦"
      },
      {
        "name": "girl",
        "unicode": "1f467",
        "char": "👧"
      },
      {
        "name": "man",
        "unicode": "1f468",
        "char": "👨"
      },
      {
        "name": "woman",
        "unicode": "1f469",
        "char": "👩"
      },
      {
        "name": "older man",
        "unicode": "1f474",
        "char": "👴"
      },
      {
        "name": "older woman",
        "unicode": "1f475",
        "char": "👵"
      },
      {
        "name": "baby",
        "unicode": "1f476",
        "char": "👶"
      },
      {
        "name": "baby angel",
        "unicode": "1f47c",
        "char": "👼"
      },
      {
        "name": "police officer",
        "unicode": "1f46e",
        "char": "👮"
      },
      {
        "name": "sleuth or spy",
        "unicode": "1f575",
        "char": "🕵"
      },
      {
        "name": "guardsman",
        "unicode": "1f482",
        "char": "💂"
      },
      {
        "name": "construction worker",
        "unicode": "1f477",
        "char": "👷"
      },
      {
        "name": "man with turban",
        "unicode": "1f473",
        "char": "👳"
      },
      {
        "name": "person with blond hair",
        "unicode": "1f471",
        "char": "👱"
      },
      {
        "name": "father christmas",
        "unicode": "1f385",
        "char": "🎅"
      },
      {
        "name": "mother christmas",
        "unicode": "1f936",
        "char": "🤶"
      },
      {
        "name": "princess",
        "unicode": "1f478",
        "char": "👸"
      },
      {
        "name": "prince",
        "unicode": "1f934",
        "char": "🤴"
      },
      {
        "name": "bride with veil",
        "unicode": "1f470",
        "char": "👰"
      },
      {
        "name": "man in tuxedo",
        "unicode": "1f935",
        "char": "🤵"
      },
      {
        "name": "pregnant woman",
        "unicode": "1f930",
        "char": "🤰"
      },
      {
        "name": "man with gua pi mao",
        "unicode": "1f472",
        "char": "👲"
      },
      {
        "name": "person frowning",
        "unicode": "1f64d",
        "char": "🙍"
      },
      {
        "name": "person with pouting face",
        "unicode": "1f64e",
        "char": "🙎"
      },
      {
        "name": "face with no good gesture",
        "unicode": "1f645",
        "char": "🙅"
      },
      {
        "name": "face with ok gesture",
        "unicode": "1f646",
        "char": "🙆"
      },
      {
        "name": "information desk person",
        "unicode": "1f481",
        "char": "💁"
      },
      {
        "name": "happy person raising one hand",
        "unicode": "1f64b",
        "char": "🙋"
      },
      {
        "name": "person bowing deeply",
        "unicode": "1f647",
        "char": "🙇"
      },
      {
        "name": "face palm",
        "unicode": "1f926",
        "char": "🤦"
      },
      {
        "name": "shrug",
        "unicode": "1f937",
        "char": "🤷"
      },
      {
        "name": "face massage",
        "unicode": "1f486",
        "char": "💆"
      },
      {
        "name": "haircut",
        "unicode": "1f487",
        "char": "💇"
      },
      {
        "name": "pedestrian",
        "unicode": "1f6b6",
        "char": "🚶"
      },
      {
        "name": "runner",
        "unicode": "1f3c3",
        "char": "🏃"
      },
      {
        "name": "dancer",
        "unicode": "1f483",
        "char": "💃"
      },
      {
        "name": "man dancing",
        "unicode": "1f57a",
        "char": "🕺"
      },
      {
        "name": "woman with bunny ears",
        "unicode": "1f46f",
        "char": "👯"
      },
      {
        "name": "speaking head in silhouette",
        "unicode": "1f5e3",
        "char": "🗣"
      },
      {
        "name": "bust in silhouette",
        "unicode": "1f464",
        "char": "👤"
      },
      {
        "name": "busts in silhouette",
        "unicode": "1f465",
        "char": "👥"
      },
      {
        "name": "man and woman holding hands",
        "unicode": "1f46b",
        "char": "👫"
      },
      {
        "name": "two men holding hands",
        "unicode": "1f46c",
        "char": "👬"
      },
      {
        "name": "two women holding hands",
        "unicode": "1f46d",
        "char": "👭"
      },
      {
        "name": "kiss",
        "unicode": "1f48f",
        "char": "💏"
      },
      {
        "name": "couple with heart",
        "unicode": "1f491",
        "char": "💑"
      },
      {
        "name": "family",
        "unicode": "1f46a",
        "char": "👪"
      },
      {
        "name": "flexed biceps",
        "unicode": "1f4aa",
        "char": "💪"
      },
      {
        "name": "selfie",
        "unicode": "1f933",
        "char": "🤳"
      },
      {
        "name": "white left pointing backhand index",
        "unicode": "1f448",
        "char": "👈"
      },
      {
        "name": "white right pointing backhand index",
        "unicode": "1f449",
        "char": "👉"
      },
      {
        "name": "white up pointing index",
        "unicode": "261d",
        "char": "☝"
      },
      {
        "name": "white up pointing backhand index",
        "unicode": "1f446",
        "char": "👆"
      },
      {
        "name": "reversed hand with middle finger extended",
        "unicode": "1f595",
        "char": "🖕"
      },
      {
        "name": "white down pointing backhand index",
        "unicode": "1f447",
        "char": "👇"
      },
      {
        "name": "victory hand",
        "unicode": "270c",
        "char": "✌"
      },
      {
        "name": "hand with first and index finger crossed",
        "unicode": "1f91e",
        "char": "🤞"
      },
      {
        "name": "raised hand with part between middle and ring fingers",
        "unicode": "1f596",
        "char": "🖖"
      },
      {
        "name": "sign of the horns",
        "unicode": "1f918",
        "char": "🤘"
      },
      {
        "name": "call me hand",
        "unicode": "1f919",
        "char": "🤙"
      },
      {
        "name": "raised hand with fingers splayed",
        "unicode": "1f590",
        "char": "🖐"
      },
      {
        "name": "raised hand",
        "unicode": "270b",
        "char": "✋"
      },
      {
        "name": "ok hand sign",
        "unicode": "1f44c",
        "char": "👌"
      },
      {
        "name": "thumbs up sign",
        "unicode": "1f44d",
        "char": "👍"
      },
      {
        "name": "thumbs down sign",
        "unicode": "1f44e",
        "char": "👎"
      },
      {
        "name": "raised fist",
        "unicode": "270a",
        "char": "✊"
      },
      {
        "name": "fisted hand sign",
        "unicode": "1f44a",
        "char": "👊"
      },
      {
        "name": "left-facing fist",
        "unicode": "1f91b",
        "char": "🤛"
      },
      {
        "name": "right-facing fist",
        "unicode": "1f91c",
        "char": "🤜"
      },
      {
        "name": "raised back of hand",
        "unicode": "1f91a",
        "char": "🤚"
      },
      {
        "name": "waving hand sign",
        "unicode": "1f44b",
        "char": "👋"
      },
      {
        "name": "clapping hands sign",
        "unicode": "1f44f",
        "char": "👏"
      },
      {
        "name": "writing hand",
        "unicode": "270d",
        "char": "✍"
      },
      {
        "name": "open hands sign",
        "unicode": "1f450",
        "char": "👐"
      },
      {
        "name": "person raising both hands in celebration",
        "unicode": "1f64c",
        "char": "🙌"
      },
      {
        "name": "person with folded hands",
        "unicode": "1f64f",
        "char": "🙏"
      },
      {
        "name": "handshake",
        "unicode": "1f91d",
        "char": "🤝"
      },
      {
        "name": "nail polish",
        "unicode": "1f485",
        "char": "💅"
      },
      {
        "name": "ear",
        "unicode": "1f442",
        "char": "👂"
      },
      {
        "name": "nose",
        "unicode": "1f443",
        "char": "👃"
      },
      {
        "name": "footprints",
        "unicode": "1f463",
        "char": "👣"
      },
      {
        "name": "eyes",
        "unicode": "1f440",
        "char": "👀"
      },
      {
        "name": "eye",
        "unicode": "1f441",
        "char": "👁"
      },
      {
        "name": "tongue",
        "unicode": "1f445",
        "char": "👅"
      },
      {
        "name": "mouth",
        "unicode": "1f444",
        "char": "👄"
      },
      {
        "name": "kiss mark",
        "unicode": "1f48b",
        "char": "💋"
      },
      {
        "name": "sleeping symbol",
        "unicode": "1f4a4",
        "char": "💤"
      },
      {
        "name": "eyeglasses",
        "unicode": "1f453",
        "char": "👓"
      },
      {
        "name": "dark sunglasses",
        "unicode": "1f576",
        "char": "🕶"
      },
      {
        "name": "necktie",
        "unicode": "1f454",
        "char": "👔"
      },
      {
        "name": "t-shirt",
        "unicode": "1f455",
        "char": "👕"
      },
      {
        "name": "jeans",
        "unicode": "1f456",
        "char": "👖"
      },
      {
        "name": "dress",
        "unicode": "1f457",
        "char": "👗"
      },
      {
        "name": "kimono",
        "unicode": "1f458",
        "char": "👘"
      },
      {
        "name": "bikini",
        "unicode": "1f459",
        "char": "👙"
      },
      {
        "name": "womans clothes",
        "unicode": "1f45a",
        "char": "👚"
      },
      {
        "name": "purse",
        "unicode": "1f45b",
        "char": "👛"
      },
      {
        "name": "handbag",
        "unicode": "1f45c",
        "char": "👜"
      },
      {
        "name": "pouch",
        "unicode": "1f45d",
        "char": "👝"
      },
      {
        "name": "school satchel",
        "unicode": "1f392",
        "char": "🎒"
      },
      {
        "name": "mans shoe",
        "unicode": "1f45e",
        "char": "👞"
      },
      {
        "name": "athletic shoe",
        "unicode": "1f45f",
        "char": "👟"
      },
      {
        "name": "high-heeled shoe",
        "unicode": "1f460",
        "char": "👠"
      },
      {
        "name": "womans sandal",
        "unicode": "1f461",
        "char": "👡"
      },
      {
        "name": "womans boots",
        "unicode": "1f462",
        "char": "👢"
      },
      {
        "name": "crown",
        "unicode": "1f451",
        "char": "👑"
      },
      {
        "name": "womans hat",
        "unicode": "1f452",
        "char": "👒"
      },
      {
        "name": "top hat",
        "unicode": "1f3a9",
        "char": "🎩"
      },
      {
        "name": "graduation cap",
        "unicode": "1f393",
        "char": "🎓"
      },
      {
        "name": "helmet with white cross",
        "unicode": "26d1",
        "char": "⛑"
      },
      {
        "name": "lipstick",
        "unicode": "1f484",
        "char": "💄"
      },
      {
        "name": "ring",
        "unicode": "1f48d",
        "char": "💍"
      },
      {
        "name": "closed umbrella",
        "unicode": "1f302",
        "char": "🌂"
      },
      {
        "name": "briefcase",
        "unicode": "1f4bc",
        "char": "💼"
      }
    ]
  },
  {
    "name": "objects",
    "unicode": "1f4a1",
    "char": "💡",
    "emojis": [
      {
        "name": "skull and crossbones",
        "unicode": "2620",
        "char": "☠"
      },
      {
        "name": "love letter",
        "unicode": "1f48c",
        "char": "💌"
      },
      {
        "name": "bomb",
        "unicode": "1f4a3",
        "char": "💣"
      },
      {
        "name": "hole",
        "unicode": "1f573",
        "char": "🕳"
      },
      {
        "name": "shopping bags",
        "unicode": "1f6cd",
        "char": "🛍"
      },
      {
        "name": "prayer beads",
        "unicode": "1f4ff",
        "char": "📿"
      },
      {
        "name": "gem stone",
        "unicode": "1f48e",
        "char": "💎"
      },
      {
        "name": "hocho",
        "unicode": "1f52a",
        "char": "🔪"
      },
      {
        "name": "amphora",
        "unicode": "1f3fa",
        "char": "🏺"
      },
      {
        "name": "world map",
        "unicode": "1f5fa",
        "char": "🗺"
      },
      {
        "name": "barber pole",
        "unicode": "1f488",
        "char": "💈"
      },
      {
        "name": "frame with picture",
        "unicode": "1f5bc",
        "char": "🖼"
      },
      {
        "name": "bellhop bell",
        "unicode": "1f6ce",
        "char": "🛎"
      },
      {
        "name": "door",
        "unicode": "1f6aa",
        "char": "🚪"
      },
      {
        "name": "sleeping accommodation",
        "unicode": "1f6cc",
        "char": "🛌"
      },
      {
        "name": "bed",
        "unicode": "1f6cf",
        "char": "🛏"
      },
      {
        "name": "couch and lamp",
        "unicode": "1f6cb",
        "char": "🛋"
      },
      {
        "name": "toilet",
        "unicode": "1f6bd",
        "char": "🚽"
      },
      {
        "name": "shower",
        "unicode": "1f6bf",
        "char": "🚿"
      },
      {
        "name": "bathtub",
        "unicode": "1f6c1",
        "char": "🛁"
      },
      {
        "name": "hourglass",
        "unicode": "231b",
        "char": "⌛"
      },
      {
        "name": "hourglass with flowing sand",
        "unicode": "23f3",
        "char": "⏳"
      },
      {
        "name": "watch",
        "unicode": "231a",
        "char": "⌚"
      },
      {
        "name": "alarm clock",
        "unicode": "23f0",
        "char": "⏰"
      },
      {
        "name": "stopwatch",
        "unicode": "23f1",
        "char": "⏱"
      },
      {
        "name": "timer clock",
        "unicode": "23f2",
        "char": "⏲"
      },
      {
        "name": "mantlepiece clock",
        "unicode": "1f570",
        "char": "🕰"
      },
      {
        "name": "thermometer",
        "unicode": "1f321",
        "char": "🌡"
      },
      {
        "name": "umbrella on ground",
        "unicode": "26f1",
        "char": "⛱"
      },
      {
        "name": "balloon",
        "unicode": "1f388",
        "char": "🎈"
      },
      {
        "name": "party popper",
        "unicode": "1f389",
        "char": "🎉"
      },
      {
        "name": "confetti ball",
        "unicode": "1f38a",
        "char": "🎊"
      },
      {
        "name": "japanese dolls",
        "unicode": "1f38e",
        "char": "🎎"
      },
      {
        "name": "carp streamer",
        "unicode": "1f38f",
        "char": "🎏"
      },
      {
        "name": "wind chime",
        "unicode": "1f390",
        "char": "🎐"
      },
      {
        "name": "ribbon",
        "unicode": "1f380",
        "char": "🎀"
      },
      {
        "name": "wrapped present",
        "unicode": "1f381",
        "char": "🎁"
      },
      {
        "name": "joystick",
        "unicode": "1f579",
        "char": "🕹"
      },
      {
        "name": "postal horn",
        "unicode": "1f4ef",
        "char": "📯"
      },
      {
        "name": "studio microphone",
        "unicode": "1f399",
        "char": "🎙"
      },
      {
        "name": "level slider",
        "unicode": "1f39a",
        "char": "🎚"
      },
      {
        "name": "control knobs",
        "unicode": "1f39b",
        "char": "🎛"
      },
      {
        "name": "radio",
        "unicode": "1f4fb",
        "char": "📻"
      },
      {
        "name": "mobile phone",
        "unicode": "1f4f1",
        "char": "📱"
      },
      {
        "name": "mobile phone with rightwards arrow at left",
        "unicode": "1f4f2",
        "char": "📲"
      },
      {
        "name": "black telephone",
        "unicode": "260e",
        "char": "☎"
      },
      {
        "name": "telephone receiver",
        "unicode": "1f4de",
        "char": "📞"
      },
      {
        "name": "pager",
        "unicode": "1f4df",
        "char": "📟"
      },
      {
        "name": "fax machine",
        "unicode": "1f4e0",
        "char": "📠"
      },
      {
        "name": "battery",
        "unicode": "1f50b",
        "char": "🔋"
      },
      {
        "name": "electric plug",
        "unicode": "1f50c",
        "char": "🔌"
      },
      {
        "name": "personal computer",
        "unicode": "1f4bb",
        "char": "💻"
      },
      {
        "name": "desktop computer",
        "unicode": "1f5a5",
        "char": "🖥"
      },
      {
        "name": "printer",
        "unicode": "1f5a8",
        "char": "🖨"
      },
      {
        "name": "keyboard",
        "unicode": "2328",
        "char": "⌨"
      },
      {
        "name": "three button mouse",
        "unicode": "1f5b1",
        "char": "🖱"
      },
      {
        "name": "trackball",
        "unicode": "1f5b2",
        "char": "🖲"
      },
      {
        "name": "minidisc",
        "unicode": "1f4bd",
        "char": "💽"
      },
      {
        "name": "floppy disk",
        "unicode": "1f4be",
        "char": "💾"
      },
      {
        "name": "optical disc",
        "unicode": "1f4bf",
        "char": "💿"
      },
      {
        "name": "dvd",
        "unicode": "1f4c0",
        "char": "📀"
      },
      {
        "name": "movie camera",
        "unicode": "1f3a5",
        "char": "🎥"
      },
      {
        "name": "film frames",
        "unicode": "1f39e",
        "char": "🎞"
      },
      {
        "name": "film projector",
        "unicode": "1f4fd",
        "char": "📽"
      },
      {
        "name": "television",
        "unicode": "1f4fa",
        "char": "📺"
      },
      {
        "name": "camera",
        "unicode": "1f4f7",
        "char": "📷"
      },
      {
        "name": "camera with flash",
        "unicode": "1f4f8",
        "char": "📸"
      },
      {
        "name": "video camera",
        "unicode": "1f4f9",
        "char": "📹"
      },
      {
        "name": "videocassette",
        "unicode": "1f4fc",
        "char": "📼"
      },
      {
        "name": "left-pointing magnifying glass",
        "unicode": "1f50d",
        "char": "🔍"
      },
      {
        "name": "right-pointing magnifying glass",
        "unicode": "1f50e",
        "char": "🔎"
      },
      {
        "name": "microscope",
        "unicode": "1f52c",
        "char": "🔬"
      },
      {
        "name": "telescope",
        "unicode": "1f52d",
        "char": "🔭"
      },
      {
        "name": "satellite antenna",
        "unicode": "1f4e1",
        "char": "📡"
      },
      {
        "name": "candle",
        "unicode": "1f56f",
        "char": "🕯"
      },
      {
        "name": "electric light bulb",
        "unicode": "1f4a1",
        "char": "💡"
      },
      {
        "name": "electric torch",
        "unicode": "1f526",
        "char": "🔦"
      },
      {
        "name": "izakaya lantern",
        "unicode": "1f3ee",
        "char": "🏮"
      },
      {
        "name": "notebook with decorative cover",
        "unicode": "1f4d4",
        "char": "📔"
      },
      {
        "name": "closed book",
        "unicode": "1f4d5",
        "char": "📕"
      },
      {
        "name": "open book",
        "unicode": "1f4d6",
        "char": "📖"
      },
      {
        "name": "green book",
        "unicode": "1f4d7",
        "char": "📗"
      },
      {
        "name": "blue book",
        "unicode": "1f4d8",
        "char": "📘"
      },
      {
        "name": "orange book",
        "unicode": "1f4d9",
        "char": "📙"
      },
      {
        "name": "books",
        "unicode": "1f4da",
        "char": "📚"
      },
      {
        "name": "notebook",
        "unicode": "1f4d3",
        "char": "📓"
      },
      {
        "name": "ledger",
        "unicode": "1f4d2",
        "char": "📒"
      },
      {
        "name": "page with curl",
        "unicode": "1f4c3",
        "char": "📃"
      },
      {
        "name": "scroll",
        "unicode": "1f4dc",
        "char": "📜"
      },
      {
        "name": "page facing up",
        "unicode": "1f4c4",
        "char": "📄"
      },
      {
        "name": "newspaper",
        "unicode": "1f4f0",
        "char": "📰"
      },
      {
        "name": "rolled-up newspaper",
        "unicode": "1f5de",
        "char": "🗞"
      },
      {
        "name": "bookmark tabs",
        "unicode": "1f4d1",
        "char": "📑"
      },
      {
        "name": "bookmark",
        "unicode": "1f516",
        "char": "🔖"
      },
      {
        "name": "label",
        "unicode": "1f3f7",
        "char": "🏷"
      },
      {
        "name": "money bag",
        "unicode": "1f4b0",
        "char": "💰"
      },
      {
        "name": "banknote with yen sign",
        "unicode": "1f4b4",
        "char": "💴"
      },
      {
        "name": "banknote with dollar sign",
        "unicode": "1f4b5",
        "char": "💵"
      },
      {
        "name": "banknote with euro sign",
        "unicode": "1f4b6",
        "char": "💶"
      },
      {
        "name": "banknote with pound sign",
        "unicode": "1f4b7",
        "char": "💷"
      },
      {
        "name": "money with wings",
        "unicode": "1f4b8",
        "char": "💸"
      },
      {
        "name": "credit card",
        "unicode": "1f4b3",
        "char": "💳"
      },
      {
        "name": "envelope",
        "unicode": "2709",
        "char": "✉"
      },
      {
        "name": "e-mail symbol",
        "unicode": "1f4e7",
        "char": "📧"
      },
      {
        "name": "incoming envelope",
        "unicode": "1f4e8",
        "char": "📨"
      },
      {
        "name": "envelope with downwards arrow above",
        "unicode": "1f4e9",
        "char": "📩"
      },
      {
        "name": "outbox tray",
        "unicode": "1f4e4",
        "char": "📤"
      },
      {
        "name": "inbox tray",
        "unicode": "1f4e5",
        "char": "📥"
      },
      {
        "name": "package",
        "unicode": "1f4e6",
        "char": "📦"
      },
      {
        "name": "closed mailbox with raised flag",
        "unicode": "1f4eb",
        "char": "📫"
      },
      {
        "name": "closed mailbox with lowered flag",
        "unicode": "1f4ea",
        "char": "📪"
      },
      {
        "name": "open mailbox with raised flag",
        "unicode": "1f4ec",
        "char": "📬"
      },
      {
        "name": "open mailbox with lowered flag",
        "unicode": "1f4ed",
        "char": "📭"
      },
      {
        "name": "postbox",
        "unicode": "1f4ee",
        "char": "📮"
      },
      {
        "name": "ballot box with ballot",
        "unicode": "1f5f3",
        "char": "🗳"
      },
      {
        "name": "pencil",
        "unicode": "270f",
        "char": "✏"
      },
      {
        "name": "black nib",
        "unicode": "2712",
        "char": "✒"
      },
      {
        "name": "lower left fountain pen",
        "unicode": "1f58b",
        "char": "🖋"
      },
      {
        "name": "lower left ballpoint pen",
        "unicode": "1f58a",
        "char": "🖊"
      },
      {
        "name": "lower left paintbrush",
        "unicode": "1f58c",
        "char": "🖌"
      },
      {
        "name": "lower left crayon",
        "unicode": "1f58d",
        "char": "🖍"
      },
      {
        "name": "memo",
        "unicode": "1f4dd",
        "char": "📝"
      },
      {
        "name": "file folder",
        "unicode": "1f4c1",
        "char": "📁"
      },
      {
        "name": "open file folder",
        "unicode": "1f4c2",
        "char": "📂"
      },
      {
        "name": "card index dividers",
        "unicode": "1f5c2",
        "char": "🗂"
      },
      {
        "name": "calendar",
        "unicode": "1f4c5",
        "char": "📅"
      },
      {
        "name": "tear-off calendar",
        "unicode": "1f4c6",
        "char": "📆"
      },
      {
        "name": "spiral note pad",
        "unicode": "1f5d2",
        "char": "🗒"
      },
      {
        "name": "spiral calendar pad",
        "unicode": "1f5d3",
        "char": "🗓"
      },
      {
        "name": "card index",
        "unicode": "1f4c7",
        "char": "📇"
      },
      {
        "name": "chart with upwards trend",
        "unicode": "1f4c8",
        "char": "📈"
      },
      {
        "name": "chart with downwards trend",
        "unicode": "1f4c9",
        "char": "📉"
      },
      {
        "name": "bar chart",
        "unicode": "1f4ca",
        "char": "📊"
      },
      {
        "name": "clipboard",
        "unicode": "1f4cb",
        "char": "📋"
      },
      {
        "name": "pushpin",
        "unicode": "1f4cc",
        "char": "📌"
      },
      {
        "name": "round pushpin",
        "unicode": "1f4cd",
        "char": "📍"
      },
      {
        "name": "paperclip",
        "unicode": "1f4ce",
        "char": "📎"
      },
      {
        "name": "linked paperclips",
        "unicode": "1f587",
        "char": "🖇"
      },
      {
        "name": "straight ruler",
        "unicode": "1f4cf",
        "char": "📏"
      },
      {
        "name": "triangular ruler",
        "unicode": "1f4d0",
        "char": "📐"
      },
      {
        "name": "black scissors",
        "unicode": "2702",
        "char": "✂"
      },
      {
        "name": "card file box",
        "unicode": "1f5c3",
        "char": "🗃"
      },
      {
        "name": "file cabinet",
        "unicode": "1f5c4",
        "char": "🗄"
      },
      {
        "name": "wastebasket",
        "unicode": "1f5d1",
        "char": "🗑"
      },
      {
        "name": "lock",
        "unicode": "1f512",
        "char": "🔒"
      },
      {
        "name": "open lock",
        "unicode": "1f513",
        "char": "🔓"
      },
      {
        "name": "lock with ink pen",
        "unicode": "1f50f",
        "char": "🔏"
      },
      {
        "name": "closed lock with key",
        "unicode": "1f510",
        "char": "🔐"
      },
      {
        "name": "key",
        "unicode": "1f511",
        "char": "🔑"
      },
      {
        "name": "old key",
        "unicode": "1f5dd",
        "char": "🗝"
      },
      {
        "name": "hammer",
        "unicode": "1f528",
        "char": "🔨"
      },
      {
        "name": "pick",
        "unicode": "26cf",
        "char": "⛏"
      },
      {
        "name": "hammer and pick",
        "unicode": "2692",
        "char": "⚒"
      },
      {
        "name": "hammer and wrench",
        "unicode": "1f6e0",
        "char": "🛠"
      },
      {
        "name": "dagger knife",
        "unicode": "1f5e1",
        "char": "🗡"
      },
      {
        "name": "crossed swords",
        "unicode": "2694",
        "char": "⚔"
      },
      {
        "name": "pistol",
        "unicode": "1f52b",
        "char": "🔫"
      },
      {
        "name": "shield",
        "unicode": "1f6e1",
        "char": "🛡"
      },
      {
        "name": "wrench",
        "unicode": "1f527",
        "char": "🔧"
      },
      {
        "name": "nut and bolt",
        "unicode": "1f529",
        "char": "🔩"
      },
      {
        "name": "gear",
        "unicode": "2699",
        "char": "⚙"
      },
      {
        "name": "compression",
        "unicode": "1f5dc",
        "char": "🗜"
      },
      {
        "name": "alembic",
        "unicode": "2697",
        "char": "⚗"
      },
      {
        "name": "scales",
        "unicode": "2696",
        "char": "⚖"
      },
      {
        "name": "link symbol",
        "unicode": "1f517",
        "char": "🔗"
      },
      {
        "name": "chains",
        "unicode": "26d3",
        "char": "⛓"
      },
      {
        "name": "syringe",
        "unicode": "1f489",
        "char": "💉"
      },
      {
        "name": "pill",
        "unicode": "1f48a",
        "char": "💊"
      },
      {
        "name": "smoking symbol",
        "unicode": "1f6ac",
        "char": "🚬"
      },
      {
        "name": "coffin",
        "unicode": "26b0",
        "char": "⚰"
      },
      {
        "name": "funeral urn",
        "unicode": "26b1",
        "char": "⚱"
      },
      {
        "name": "moyai",
        "unicode": "1f5ff",
        "char": "🗿"
      },
      {
        "name": "oil drum",
        "unicode": "1f6e2",
        "char": "🛢"
      },
      {
        "name": "crystal ball",
        "unicode": "1f52e",
        "char": "🔮"
      },
      {
        "name": "shopping trolley",
        "unicode": "1f6d2",
        "char": "🛒"
      },
      {
        "name": "triangular flag on post",
        "unicode": "1f6a9",
        "char": "🚩"
      },
      {
        "name": "crossed flags",
        "unicode": "1f38c",
        "char": "🎌"
      },
      {
        "name": "waving black flag",
        "unicode": "1f3f4",
        "char": "🏴"
      },
      {
        "name": "waving white flag",
        "unicode": "1f3f3",
        "char": "🏳"
      }
    ]
  },
  {
    "name": "activity",
    "unicode": "1f3c8",
    "char": "🏈",
    "emojis": [
      {
        "name": "alien monster",
        "unicode": "1f47e",
        "char": "👾"
      },
      {
        "name": "man in business suit levitating",
        "unicode": "1f574",
        "char": "🕴"
      },
      {
        "name": "fencer",
        "unicode": "1f93a",
        "char": "🤺"
      },
      {
        "name": "horse racing",
        "unicode": "1f3c7",
        "char": "🏇"
      },
      {
        "name": "skier",
        "unicode": "26f7",
        "char": "⛷"
      },
      {
        "name": "snowboarder",
        "unicode": "1f3c2",
        "char": "🏂"
      },
      {
        "name": "golfer",
        "unicode": "1f3cc",
        "char": "🏌"
      },
      {
        "name": "surfer",
        "unicode": "1f3c4",
        "char": "🏄"
      },
      {
        "name": "rowboat",
        "unicode": "1f6a3",
        "char": "🚣"
      },
      {
        "name": "swimmer",
        "unicode": "1f3ca",
        "char": "🏊"
      },
      {
        "name": "person with ball",
        "unicode": "26f9",
        "char": "⛹"
      },
      {
        "name": "weight lifter",
        "unicode": "1f3cb",
        "char": "🏋"
      },
      {
        "name": "bicyclist",
        "unicode": "1f6b4",
        "char": "🚴"
      },
      {
        "name": "mountain bicyclist",
        "unicode": "1f6b5",
        "char": "🚵"
      },
      {
        "name": "person doing cartwheel",
        "unicode": "1f938",
        "char": "🤸"
      },
      {
        "name": "wrestlers",
        "unicode": "1f93c",
        "char": "🤼"
      },
      {
        "name": "water polo",
        "unicode": "1f93d",
        "char": "🤽"
      },
      {
        "name": "handball",
        "unicode": "1f93e",
        "char": "🤾"
      },
      {
        "name": "juggling",
        "unicode": "1f939",
        "char": "🤹"
      },
      {
        "name": "circus tent",
        "unicode": "1f3aa",
        "char": "🎪"
      },
      {
        "name": "performing arts",
        "unicode": "1f3ad",
        "char": "🎭"
      },
      {
        "name": "artist palette",
        "unicode": "1f3a8",
        "char": "🎨"
      },
      {
        "name": "slot machine",
        "unicode": "1f3b0",
        "char": "🎰"
      },
      {
        "name": "bath",
        "unicode": "1f6c0",
        "char": "🛀"
      },
      {
        "name": "reminder ribbon",
        "unicode": "1f397",
        "char": "🎗"
      },
      {
        "name": "admission tickets",
        "unicode": "1f39f",
        "char": "🎟"
      },
      {
        "name": "ticket",
        "unicode": "1f3ab",
        "char": "🎫"
      },
      {
        "name": "military medal",
        "unicode": "1f396",
        "char": "🎖"
      },
      {
        "name": "trophy",
        "unicode": "1f3c6",
        "char": "🏆"
      },
      {
        "name": "sports medal",
        "unicode": "1f3c5",
        "char": "🏅"
      },
      {
        "name": "first place medal",
        "unicode": "1f947",
        "char": "🥇"
      },
      {
        "name": "second place medal",
        "unicode": "1f948",
        "char": "🥈"
      },
      {
        "name": "third place medal",
        "unicode": "1f949",
        "char": "🥉"
      },
      {
        "name": "soccer ball",
        "unicode": "26bd",
        "char": "⚽"
      },
      {
        "name": "baseball",
        "unicode": "26be",
        "char": "⚾"
      },
      {
        "name": "basketball and hoop",
        "unicode": "1f3c0",
        "char": "🏀"
      },
      {
        "name": "volleyball",
        "unicode": "1f3d0",
        "char": "🏐"
      },
      {
        "name": "american football",
        "unicode": "1f3c8",
        "char": "🏈"
      },
      {
        "name": "rugby football",
        "unicode": "1f3c9",
        "char": "🏉"
      },
      {
        "name": "tennis racquet and ball",
        "unicode": "1f3be",
        "char": "🎾"
      },
      {
        "name": "billiards",
        "unicode": "1f3b1",
        "char": "🎱"
      },
      {
        "name": "bowling",
        "unicode": "1f3b3",
        "char": "🎳"
      },
      {
        "name": "cricket bat and ball",
        "unicode": "1f3cf",
        "char": "🏏"
      },
      {
        "name": "field hockey stick and ball",
        "unicode": "1f3d1",
        "char": "🏑"
      },
      {
        "name": "ice hockey stick and puck",
        "unicode": "1f3d2",
        "char": "🏒"
      },
      {
        "name": "table tennis paddle and ball",
        "unicode": "1f3d3",
        "char": "🏓"
      },
      {
        "name": "badminton racquet",
        "unicode": "1f3f8",
        "char": "🏸"
      },
      {
        "name": "boxing glove",
        "unicode": "1f94a",
        "char": "🥊"
      },
      {
        "name": "martial arts uniform",
        "unicode": "1f94b",
        "char": "🥋"
      },
      {
        "name": "goal net",
        "unicode": "1f945",
        "char": "🥅"
      },
      {
        "name": "direct hit",
        "unicode": "1f3af",
        "char": "🎯"
      },
      {
        "name": "flag in hole",
        "unicode": "26f3",
        "char": "⛳"
      },
      {
        "name": "ice skate",
        "unicode": "26f8",
        "char": "⛸"
      },
      {
        "name": "fishing pole and fish",
        "unicode": "1f3a3",
        "char": "🎣"
      },
      {
        "name": "running shirt with sash",
        "unicode": "1f3bd",
        "char": "🎽"
      },
      {
        "name": "ski and ski boot",
        "unicode": "1f3bf",
        "char": "🎿"
      },
      {
        "name": "video game",
        "unicode": "1f3ae",
        "char": "🎮"
      },
      {
        "name": "game die",
        "unicode": "1f3b2",
        "char": "🎲"
      },
      {
        "name": "musical score",
        "unicode": "1f3bc",
        "char": "🎼"
      },
      {
        "name": "microphone",
        "unicode": "1f3a4",
        "char": "🎤"
      },
      {
        "name": "headphone",
        "unicode": "1f3a7",
        "char": "🎧"
      },
      {
        "name": "saxophone",
        "unicode": "1f3b7",
        "char": "🎷"
      },
      {
        "name": "guitar",
        "unicode": "1f3b8",
        "char": "🎸"
      },
      {
        "name": "musical keyboard",
        "unicode": "1f3b9",
        "char": "🎹"
      },
      {
        "name": "trumpet",
        "unicode": "1f3ba",
        "char": "🎺"
      },
      {
        "name": "violin",
        "unicode": "1f3bb",
        "char": "🎻"
      },
      {
        "name": "drum with drumsticks",
        "unicode": "1f941",
        "char": "🥁"
      },
      {
        "name": "clapper board",
        "unicode": "1f3ac",
        "char": "🎬"
      },
      {
        "name": "bow and arrow",
        "unicode": "1f3f9",
        "char": "🏹"
      }
    ]
  },
  {
    "name": "nature",
    "unicode": "1f33f",
    "char": "🌿",
    "emojis": [
      {
        "name": "see-no-evil monkey",
        "unicode": "1f648",
        "char": "🙈"
      },
      {
        "name": "hear-no-evil monkey",
        "unicode": "1f649",
        "char": "🙉"
      },
      {
        "name": "speak-no-evil monkey",
        "unicode": "1f64a",
        "char": "🙊"
      },
      {
        "name": "splashing sweat symbol",
        "unicode": "1f4a6",
        "char": "💦"
      },
      {
        "name": "dash symbol",
        "unicode": "1f4a8",
        "char": "💨"
      },
      {
        "name": "monkey face",
        "unicode": "1f435",
        "char": "🐵"
      },
      {
        "name": "monkey",
        "unicode": "1f412",
        "char": "🐒"
      },
      {
        "name": "gorilla",
        "unicode": "1f98d",
        "char": "🦍"
      },
      {
        "name": "dog face",
        "unicode": "1f436",
        "char": "🐶"
      },
      {
        "name": "dog",
        "unicode": "1f415",
        "char": "🐕"
      },
      {
        "name": "poodle",
        "unicode": "1f429",
        "char": "🐩"
      },
      {
        "name": "wolf face",
        "unicode": "1f43a",
        "char": "🐺"
      },
      {
        "name": "fox face",
        "unicode": "1f98a",
        "char": "🦊"
      },
      {
        "name": "cat face",
        "unicode": "1f431",
        "char": "🐱"
      },
      {
        "name": "cat",
        "unicode": "1f408",
        "char": "🐈"
      },
      {
        "name": "lion face",
        "unicode": "1f981",
        "char": "🦁"
      },
      {
        "name": "tiger face",
        "unicode": "1f42f",
        "char": "🐯"
      },
      {
        "name": "tiger",
        "unicode": "1f405",
        "char": "🐅"
      },
      {
        "name": "leopard",
        "unicode": "1f406",
        "char": "🐆"
      },
      {
        "name": "horse face",
        "unicode": "1f434",
        "char": "🐴"
      },
      {
        "name": "horse",
        "unicode": "1f40e",
        "char": "🐎"
      },
      {
        "name": "deer",
        "unicode": "1f98c",
        "char": "🦌"
      },
      {
        "name": "unicorn face",
        "unicode": "1f984",
        "char": "🦄"
      },
      {
        "name": "cow face",
        "unicode": "1f42e",
        "char": "🐮"
      },
      {
        "name": "ox",
        "unicode": "1f402",
        "char": "🐂"
      },
      {
        "name": "water buffalo",
        "unicode": "1f403",
        "char": "🐃"
      },
      {
        "name": "cow",
        "unicode": "1f404",
        "char": "🐄"
      },
      {
        "name": "pig face",
        "unicode": "1f437",
        "char": "🐷"
      },
      {
        "name": "pig",
        "unicode": "1f416",
        "char": "🐖"
      },
      {
        "name": "boar",
        "unicode": "1f417",
        "char": "🐗"
      },
      {
        "name": "pig nose",
        "unicode": "1f43d",
        "char": "🐽"
      },
      {
        "name": "ram",
        "unicode": "1f40f",
        "char": "🐏"
      },
      {
        "name": "sheep",
        "unicode": "1f411",
        "char": "🐑"
      },
      {
        "name": "goat",
        "unicode": "1f410",
        "char": "🐐"
      },
      {
        "name": "dromedary camel",
        "unicode": "1f42a",
        "char": "🐪"
      },
      {
        "name": "bactrian camel",
        "unicode": "1f42b",
        "char": "🐫"
      },
      {
        "name": "elephant",
        "unicode": "1f418",
        "char": "🐘"
      },
      {
        "name": "rhinoceros",
        "unicode": "1f98f",
        "char": "🦏"
      },
      {
        "name": "mouse face",
        "unicode": "1f42d",
        "char": "🐭"
      },
      {
        "name": "mouse",
        "unicode": "1f401",
        "char": "🐁"
      },
      {
        "name": "rat",
        "unicode": "1f400",
        "char": "🐀"
      },
      {
        "name": "hamster face",
        "unicode": "1f439",
        "char": "🐹"
      },
      {
        "name": "rabbit face",
        "unicode": "1f430",
        "char": "🐰"
      },
      {
        "name": "rabbit",
        "unicode": "1f407",
        "char": "🐇"
      },
      {
        "name": "chipmunk",
        "unicode": "1f43f",
        "char": "🐿"
      },
      {
        "name": "bat",
        "unicode": "1f987",
        "char": "🦇"
      },
      {
        "name": "bear face",
        "unicode": "1f43b",
        "char": "🐻"
      },
      {
        "name": "koala",
        "unicode": "1f428",
        "char": "🐨"
      },
      {
        "name": "panda face",
        "unicode": "1f43c",
        "char": "🐼"
      },
      {
        "name": "paw prints",
        "unicode": "1f43e",
        "char": "🐾"
      },
      {
        "name": "turkey",
        "unicode": "1f983",
        "char": "🦃"
      },
      {
        "name": "chicken",
        "unicode": "1f414",
        "char": "🐔"
      },
      {
        "name": "rooster",
        "unicode": "1f413",
        "char": "🐓"
      },
      {
        "name": "hatching chick",
        "unicode": "1f423",
        "char": "🐣"
      },
      {
        "name": "baby chick",
        "unicode": "1f424",
        "char": "🐤"
      },
      {
        "name": "front-facing baby chick",
        "unicode": "1f425",
        "char": "🐥"
      },
      {
        "name": "bird",
        "unicode": "1f426",
        "char": "🐦"
      },
      {
        "name": "penguin",
        "unicode": "1f427",
        "char": "🐧"
      },
      {
        "name": "dove of peace",
        "unicode": "1f54a",
        "char": "🕊"
      },
      {
        "name": "eagle",
        "unicode": "1f985",
        "char": "🦅"
      },
      {
        "name": "duck",
        "unicode": "1f986",
        "char": "🦆"
      },
      {
        "name": "owl",
        "unicode": "1f989",
        "char": "🦉"
      },
      {
        "name": "frog face",
        "unicode": "1f438",
        "char": "🐸"
      },
      {
        "name": "crocodile",
        "unicode": "1f40a",
        "char": "🐊"
      },
      {
        "name": "turtle",
        "unicode": "1f422",
        "char": "🐢"
      },
      {
        "name": "lizard",
        "unicode": "1f98e",
        "char": "🦎"
      },
      {
        "name": "snake",
        "unicode": "1f40d",
        "char": "🐍"
      },
      {
        "name": "dragon face",
        "unicode": "1f432",
        "char": "🐲"
      },
      {
        "name": "dragon",
        "unicode": "1f409",
        "char": "🐉"
      },
      {
        "name": "spouting whale",
        "unicode": "1f433",
        "char": "🐳"
      },
      {
        "name": "whale",
        "unicode": "1f40b",
        "char": "🐋"
      },
      {
        "name": "dolphin",
        "unicode": "1f42c",
        "char": "🐬"
      },
      {
        "name": "fish",
        "unicode": "1f41f",
        "char": "🐟"
      },
      {
        "name": "tropical fish",
        "unicode": "1f420",
        "char": "🐠"
      },
      {
        "name": "blowfish",
        "unicode": "1f421",
        "char": "🐡"
      },
      {
        "name": "shark",
        "unicode": "1f988",
        "char": "🦈"
      },
      {
        "name": "octopus",
        "unicode": "1f419",
        "char": "🐙"
      },
      {
        "name": "spiral shell",
        "unicode": "1f41a",
        "char": "🐚"
      },
      {
        "name": "crab",
        "unicode": "1f980",
        "char": "🦀"
      },
      {
        "name": "shrimp",
        "unicode": "1f990",
        "char": "🦐"
      },
      {
        "name": "squid",
        "unicode": "1f991",
        "char": "🦑"
      },
      {
        "name": "butterfly",
        "unicode": "1f98b",
        "char": "🦋"
      },
      {
        "name": "snail",
        "unicode": "1f40c",
        "char": "🐌"
      },
      {
        "name": "bug",
        "unicode": "1f41b",
        "char": "🐛"
      },
      {
        "name": "ant",
        "unicode": "1f41c",
        "char": "🐜"
      },
      {
        "name": "honeybee",
        "unicode": "1f41d",
        "char": "🐝"
      },
      {
        "name": "lady beetle",
        "unicode": "1f41e",
        "char": "🐞"
      },
      {
        "name": "spider",
        "unicode": "1f577",
        "char": "🕷"
      },
      {
        "name": "spider web",
        "unicode": "1f578",
        "char": "🕸"
      },
      {
        "name": "scorpion",
        "unicode": "1f982",
        "char": "🦂"
      },
      {
        "name": "bouquet",
        "unicode": "1f490",
        "char": "💐"
      },
      {
        "name": "cherry blossom",
        "unicode": "1f338",
        "char": "🌸"
      },
      {
        "name": "rosette",
        "unicode": "1f3f5",
        "char": "🏵"
      },
      {
        "name": "rose",
        "unicode": "1f339",
        "char": "🌹"
      },
      {
        "name": "wilted flower",
        "unicode": "1f940",
        "char": "🥀"
      },
      {
        "name": "hibiscus",
        "unicode": "1f33a",
        "char": "🌺"
      },
      {
        "name": "sunflower",
        "unicode": "1f33b",
        "char": "🌻"
      },
      {
        "name": "blossom",
        "unicode": "1f33c",
        "char": "🌼"
      },
      {
        "name": "tulip",
        "unicode": "1f337",
        "char": "🌷"
      },
      {
        "name": "seedling",
        "unicode": "1f331",
        "char": "🌱"
      },
      {
        "name": "evergreen tree",
        "unicode": "1f332",
        "char": "🌲"
      },
      {
        "name": "deciduous tree",
        "unicode": "1f333",
        "char": "🌳"
      },
      {
        "name": "palm tree",
        "unicode": "1f334",
        "char": "🌴"
      },
      {
        "name": "cactus",
        "unicode": "1f335",
        "char": "🌵"
      },
      {
        "name": "ear of rice",
        "unicode": "1f33e",
        "char": "🌾"
      },
      {
        "name": "herb",
        "unicode": "1f33f",
        "char": "🌿"
      },
      {
        "name": "shamrock",
        "unicode": "2618",
        "char": "☘"
      },
      {
        "name": "four leaf clover",
        "unicode": "1f340",
        "char": "🍀"
      },
      {
        "name": "maple leaf",
        "unicode": "1f341",
        "char": "🍁"
      },
      {
        "name": "fallen leaf",
        "unicode": "1f342",
        "char": "🍂"
      },
      {
        "name": "leaf fluttering in wind",
        "unicode": "1f343",
        "char": "🍃"
      },
      {
        "name": "mushroom",
        "unicode": "1f344",
        "char": "🍄"
      },
      {
        "name": "chestnut",
        "unicode": "1f330",
        "char": "🌰"
      },
      {
        "name": "earth globe europe-africa",
        "unicode": "1f30d",
        "char": "🌍"
      },
      {
        "name": "earth globe americas",
        "unicode": "1f30e",
        "char": "🌎"
      },
      {
        "name": "earth globe asia-australia",
        "unicode": "1f30f",
        "char": "🌏"
      },
      {
        "name": "new moon symbol",
        "unicode": "1f311",
        "char": "🌑"
      },
      {
        "name": "waxing crescent moon symbol",
        "unicode": "1f312",
        "char": "🌒"
      },
      {
        "name": "first quarter moon symbol",
        "unicode": "1f313",
        "char": "🌓"
      },
      {
        "name": "waxing gibbous moon symbol",
        "unicode": "1f314",
        "char": "🌔"
      },
      {
        "name": "full moon symbol",
        "unicode": "1f315",
        "char": "🌕"
      },
      {
        "name": "waning gibbous moon symbol",
        "unicode": "1f316",
        "char": "🌖"
      },
      {
        "name": "last quarter moon symbol",
        "unicode": "1f317",
        "char": "🌗"
      },
      {
        "name": "waning crescent moon symbol",
        "unicode": "1f318",
        "char": "🌘"
      },
      {
        "name": "crescent moon",
        "unicode": "1f319",
        "char": "🌙"
      },
      {
        "name": "new moon with face",
        "unicode": "1f31a",
        "char": "🌚"
      },
      {
        "name": "first quarter moon with face",
        "unicode": "1f31b",
        "char": "🌛"
      },
      {
        "name": "last quarter moon with face",
        "unicode": "1f31c",
        "char": "🌜"
      },
      {
        "name": "black sun with rays",
        "unicode": "2600",
        "char": "☀"
      },
      {
        "name": "full moon with face",
        "unicode": "1f31d",
        "char": "🌝"
      },
      {
        "name": "sun with face",
        "unicode": "1f31e",
        "char": "🌞"
      },
      {
        "name": "white medium star",
        "unicode": "2b50",
        "char": "⭐"
      },
      {
        "name": "glowing star",
        "unicode": "1f31f",
        "char": "🌟"
      },
      {
        "name": "cloud",
        "unicode": "2601",
        "char": "☁"
      },
      {
        "name": "sun behind cloud",
        "unicode": "26c5",
        "char": "⛅"
      },
      {
        "name": "thunder cloud and rain",
        "unicode": "26c8",
        "char": "⛈"
      },
      {
        "name": "white sun with small cloud",
        "unicode": "1f324",
        "char": "🌤"
      },
      {
        "name": "white sun behind cloud",
        "unicode": "1f325",
        "char": "🌥"
      },
      {
        "name": "white sun behind cloud with rain",
        "unicode": "1f326",
        "char": "🌦"
      },
      {
        "name": "cloud with rain",
        "unicode": "1f327",
        "char": "🌧"
      },
      {
        "name": "cloud with snow",
        "unicode": "1f328",
        "char": "🌨"
      },
      {
        "name": "cloud with lightning",
        "unicode": "1f329",
        "char": "🌩"
      },
      {
        "name": "cloud with tornado",
        "unicode": "1f32a",
        "char": "🌪"
      },
      {
        "name": "fog",
        "unicode": "1f32b",
        "char": "🌫"
      },
      {
        "name": "wind blowing face",
        "unicode": "1f32c",
        "char": "🌬"
      },
      {
        "name": "umbrella",
        "unicode": "2602",
        "char": "☂"
      },
      {
        "name": "umbrella with rain drops",
        "unicode": "2614",
        "char": "☔"
      },
      {
        "name": "high voltage sign",
        "unicode": "26a1",
        "char": "⚡"
      },
      {
        "name": "snowflake",
        "unicode": "2744",
        "char": "❄"
      },
      {
        "name": "snowman",
        "unicode": "2603",
        "char": "☃"
      },
      {
        "name": "snowman without snow",
        "unicode": "26c4",
        "char": "⛄"
      },
      {
        "name": "comet",
        "unicode": "2604",
        "char": "☄"
      },
      {
        "name": "fire",
        "unicode": "1f525",
        "char": "🔥"
      },
      {
        "name": "droplet",
        "unicode": "1f4a7",
        "char": "💧"
      },
      {
        "name": "water wave",
        "unicode": "1f30a",
        "char": "🌊"
      },
      {
        "name": "jack-o-lantern",
        "unicode": "1f383",
        "char": "🎃"
      },
      {
        "name": "christmas tree",
        "unicode": "1f384",
        "char": "🎄"
      },
      {
        "name": "sparkles",
        "unicode": "2728",
        "char": "✨"
      },
      {
        "name": "tanabata tree",
        "unicode": "1f38b",
        "char": "🎋"
      },
      {
        "name": "pine decoration",
        "unicode": "1f38d",
        "char": "🎍"
      }
    ]
  },
  {
    "name": "travel",
    "unicode": "2708",
    "char": "✈",
    "emojis": [
      {
        "name": "racing car",
        "unicode": "1f3ce",
        "char": "🏎"
      },
      {
        "name": "racing motorcycle",
        "unicode": "1f3cd",
        "char": "🏍"
      },
      {
        "name": "silhouette of japan",
        "unicode": "1f5fe",
        "char": "🗾"
      },
      {
        "name": "snow capped mountain",
        "unicode": "1f3d4",
        "char": "🏔"
      },
      {
        "name": "mountain",
        "unicode": "26f0",
        "char": "⛰"
      },
      {
        "name": "volcano",
        "unicode": "1f30b",
        "char": "🌋"
      },
      {
        "name": "mount fuji",
        "unicode": "1f5fb",
        "char": "🗻"
      },
      {
        "name": "camping",
        "unicode": "1f3d5",
        "char": "🏕"
      },
      {
        "name": "beach with umbrella",
        "unicode": "1f3d6",
        "char": "🏖"
      },
      {
        "name": "desert",
        "unicode": "1f3dc",
        "char": "🏜"
      },
      {
        "name": "desert island",
        "unicode": "1f3dd",
        "char": "🏝"
      },
      {
        "name": "national park",
        "unicode": "1f3de",
        "char": "🏞"
      },
      {
        "name": "stadium",
        "unicode": "1f3df",
        "char": "🏟"
      },
      {
        "name": "classical building",
        "unicode": "1f3db",
        "char": "🏛"
      },
      {
        "name": "building construction",
        "unicode": "1f3d7",
        "char": "🏗"
      },
      {
        "name": "house buildings",
        "unicode": "1f3d8",
        "char": "🏘"
      },
      {
        "name": "cityscape",
        "unicode": "1f3d9",
        "char": "🏙"
      },
      {
        "name": "derelict house building",
        "unicode": "1f3da",
        "char": "🏚"
      },
      {
        "name": "house building",
        "unicode": "1f3e0",
        "char": "🏠"
      },
      {
        "name": "house with garden",
        "unicode": "1f3e1",
        "char": "🏡"
      },
      {
        "name": "office building",
        "unicode": "1f3e2",
        "char": "🏢"
      },
      {
        "name": "japanese post office",
        "unicode": "1f3e3",
        "char": "🏣"
      },
      {
        "name": "european post office",
        "unicode": "1f3e4",
        "char": "🏤"
      },
      {
        "name": "hospital",
        "unicode": "1f3e5",
        "char": "🏥"
      },
      {
        "name": "bank",
        "unicode": "1f3e6",
        "char": "🏦"
      },
      {
        "name": "hotel",
        "unicode": "1f3e8",
        "char": "🏨"
      },
      {
        "name": "love hotel",
        "unicode": "1f3e9",
        "char": "🏩"
      },
      {
        "name": "convenience store",
        "unicode": "1f3ea",
        "char": "🏪"
      },
      {
        "name": "school",
        "unicode": "1f3eb",
        "char": "🏫"
      },
      {
        "name": "department store",
        "unicode": "1f3ec",
        "char": "🏬"
      },
      {
        "name": "factory",
        "unicode": "1f3ed",
        "char": "🏭"
      },
      {
        "name": "japanese castle",
        "unicode": "1f3ef",
        "char": "🏯"
      },
      {
        "name": "european castle",
        "unicode": "1f3f0",
        "char": "🏰"
      },
      {
        "name": "wedding",
        "unicode": "1f492",
        "char": "💒"
      },
      {
        "name": "tokyo tower",
        "unicode": "1f5fc",
        "char": "🗼"
      },
      {
        "name": "statue of liberty",
        "unicode": "1f5fd",
        "char": "🗽"
      },
      {
        "name": "church",
        "unicode": "26ea",
        "char": "⛪"
      },
      {
        "name": "mosque",
        "unicode": "1f54c",
        "char": "🕌"
      },
      {
        "name": "synagogue",
        "unicode": "1f54d",
        "char": "🕍"
      },
      {
        "name": "shinto shrine",
        "unicode": "26e9",
        "char": "⛩"
      },
      {
        "name": "kaaba",
        "unicode": "1f54b",
        "char": "🕋"
      },
      {
        "name": "fountain",
        "unicode": "26f2",
        "char": "⛲"
      },
      {
        "name": "tent",
        "unicode": "26fa",
        "char": "⛺"
      },
      {
        "name": "foggy",
        "unicode": "1f301",
        "char": "🌁"
      },
      {
        "name": "night with stars",
        "unicode": "1f303",
        "char": "🌃"
      },
      {
        "name": "sunrise over mountains",
        "unicode": "1f304",
        "char": "🌄"
      },
      {
        "name": "sunrise",
        "unicode": "1f305",
        "char": "🌅"
      },
      {
        "name": "cityscape at dusk",
        "unicode": "1f306",
        "char": "🌆"
      },
      {
        "name": "sunset over buildings",
        "unicode": "1f307",
        "char": "🌇"
      },
      {
        "name": "bridge at night",
        "unicode": "1f309",
        "char": "🌉"
      },
      {
        "name": "milky way",
        "unicode": "1f30c",
        "char": "🌌"
      },
      {
        "name": "carousel horse",
        "unicode": "1f3a0",
        "char": "🎠"
      },
      {
        "name": "ferris wheel",
        "unicode": "1f3a1",
        "char": "🎡"
      },
      {
        "name": "roller coaster",
        "unicode": "1f3a2",
        "char": "🎢"
      },
      {
        "name": "steam locomotive",
        "unicode": "1f682",
        "char": "🚂"
      },
      {
        "name": "railway car",
        "unicode": "1f683",
        "char": "🚃"
      },
      {
        "name": "high-speed train",
        "unicode": "1f684",
        "char": "🚄"
      },
      {
        "name": "high-speed train with bullet nose",
        "unicode": "1f685",
        "char": "🚅"
      },
      {
        "name": "train",
        "unicode": "1f686",
        "char": "🚆"
      },
      {
        "name": "metro",
        "unicode": "1f687",
        "char": "🚇"
      },
      {
        "name": "light rail",
        "unicode": "1f688",
        "char": "🚈"
      },
      {
        "name": "station",
        "unicode": "1f689",
        "char": "🚉"
      },
      {
        "name": "tram",
        "unicode": "1f68a",
        "char": "🚊"
      },
      {
        "name": "monorail",
        "unicode": "1f69d",
        "char": "🚝"
      },
      {
        "name": "mountain railway",
        "unicode": "1f69e",
        "char": "🚞"
      },
      {
        "name": "tram car",
        "unicode": "1f68b",
        "char": "🚋"
      },
      {
        "name": "bus",
        "unicode": "1f68c",
        "char": "🚌"
      },
      {
        "name": "oncoming bus",
        "unicode": "1f68d",
        "char": "🚍"
      },
      {
        "name": "trolleybus",
        "unicode": "1f68e",
        "char": "🚎"
      },
      {
        "name": "minibus",
        "unicode": "1f690",
        "char": "🚐"
      },
      {
        "name": "ambulance",
        "unicode": "1f691",
        "char": "🚑"
      },
      {
        "name": "fire engine",
        "unicode": "1f692",
        "char": "🚒"
      },
      {
        "name": "police car",
        "unicode": "1f693",
        "char": "🚓"
      },
      {
        "name": "oncoming police car",
        "unicode": "1f694",
        "char": "🚔"
      },
      {
        "name": "taxi",
        "unicode": "1f695",
        "char": "🚕"
      },
      {
        "name": "oncoming taxi",
        "unicode": "1f696",
        "char": "🚖"
      },
      {
        "name": "automobile",
        "unicode": "1f697",
        "char": "🚗"
      },
      {
        "name": "oncoming automobile",
        "unicode": "1f698",
        "char": "🚘"
      },
      {
        "name": "recreational vehicle",
        "unicode": "1f699",
        "char": "🚙"
      },
      {
        "name": "delivery truck",
        "unicode": "1f69a",
        "char": "🚚"
      },
      {
        "name": "articulated lorry",
        "unicode": "1f69b",
        "char": "🚛"
      },
      {
        "name": "tractor",
        "unicode": "1f69c",
        "char": "🚜"
      },
      {
        "name": "bicycle",
        "unicode": "1f6b2",
        "char": "🚲"
      },
      {
        "name": "scooter",
        "unicode": "1f6f4",
        "char": "🛴"
      },
      {
        "name": "motor scooter",
        "unicode": "1f6f5",
        "char": "🛵"
      },
      {
        "name": "bus stop",
        "unicode": "1f68f",
        "char": "🚏"
      },
      {
        "name": "motorway",
        "unicode": "1f6e3",
        "char": "🛣"
      },
      {
        "name": "railway track",
        "unicode": "1f6e4",
        "char": "🛤"
      },
      {
        "name": "fuel pump",
        "unicode": "26fd",
        "char": "⛽"
      },
      {
        "name": "police cars revolving light",
        "unicode": "1f6a8",
        "char": "🚨"
      },
      {
        "name": "horizontal traffic light",
        "unicode": "1f6a5",
        "char": "🚥"
      },
      {
        "name": "vertical traffic light",
        "unicode": "1f6a6",
        "char": "🚦"
      },
      {
        "name": "construction sign",
        "unicode": "1f6a7",
        "char": "🚧"
      },
      {
        "name": "anchor",
        "unicode": "2693",
        "char": "⚓"
      },
      {
        "name": "sailboat",
        "unicode": "26f5",
        "char": "⛵"
      },
      {
        "name": "canoe",
        "unicode": "1f6f6",
        "char": "🛶"
      },
      {
        "name": "speedboat",
        "unicode": "1f6a4",
        "char": "🚤"
      },
      {
        "name": "passenger ship",
        "unicode": "1f6f3",
        "char": "🛳"
      },
      {
        "name": "ferry",
        "unicode": "26f4",
        "char": "⛴"
      },
      {
        "name": "motorboat",
        "unicode": "1f6e5",
        "char": "🛥"
      },
      {
        "name": "ship",
        "unicode": "1f6a2",
        "char": "🚢"
      },
      {
        "name": "airplane",
        "unicode": "2708",
        "char": "✈"
      },
      {
        "name": "small airplane",
        "unicode": "1f6e9",
        "char": "🛩"
      },
      {
        "name": "airplane departure",
        "unicode": "1f6eb",
        "char": "🛫"
      },
      {
        "name": "airplane arriving",
        "unicode": "1f6ec",
        "char": "🛬"
      },
      {
        "name": "seat",
        "unicode": "1f4ba",
        "char": "💺"
      },
      {
        "name": "helicopter",
        "unicode": "1f681",
        "char": "🚁"
      },
      {
        "name": "suspension railway",
        "unicode": "1f69f",
        "char": "🚟"
      },
      {
        "name": "mountain cableway",
        "unicode": "1f6a0",
        "char": "🚠"
      },
      {
        "name": "aerial tramway",
        "unicode": "1f6a1",
        "char": "🚡"
      },
      {
        "name": "rocket",
        "unicode": "1f680",
        "char": "🚀"
      },
      {
        "name": "satellite",
        "unicode": "1f6f0",
        "char": "🛰"
      },
      {
        "name": "shooting star",
        "unicode": "1f320",
        "char": "🌠"
      },
      {
        "name": "rainbow",
        "unicode": "1f308",
        "char": "🌈"
      },
      {
        "name": "fireworks",
        "unicode": "1f386",
        "char": "🎆"
      },
      {
        "name": "firework sparkler",
        "unicode": "1f387",
        "char": "🎇"
      },
      {
        "name": "moon viewing ceremony",
        "unicode": "1f391",
        "char": "🎑"
      },
      {
        "name": "chequered flag",
        "unicode": "1f3c1",
        "char": "🏁"
      }
    ]
  },
  {
    "name": "symbols",
    "unicode": "2764",
    "char": "❤",
    "emojis": [
      {
        "name": "heart with arrow",
        "unicode": "1f498",
        "char": "💘"
      },
      {
        "name": "heavy black heart",
        "unicode": "2764",
        "char": "❤"
      },
      {
        "name": "beating heart",
        "unicode": "1f493",
        "char": "💓"
      },
      {
        "name": "broken heart",
        "unicode": "1f494",
        "char": "💔"
      },
      {
        "name": "two hearts",
        "unicode": "1f495",
        "char": "💕"
      },
      {
        "name": "sparkling heart",
        "unicode": "1f496",
        "char": "💖"
      },
      {
        "name": "growing heart",
        "unicode": "1f497",
        "char": "💗"
      },
      {
        "name": "blue heart",
        "unicode": "1f499",
        "char": "💙"
      },
      {
        "name": "green heart",
        "unicode": "1f49a",
        "char": "💚"
      },
      {
        "name": "yellow heart",
        "unicode": "1f49b",
        "char": "💛"
      },
      {
        "name": "purple heart",
        "unicode": "1f49c",
        "char": "💜"
      },
      {
        "name": "black heart",
        "unicode": "1f5a4",
        "char": "🖤"
      },
      {
        "name": "heart with ribbon",
        "unicode": "1f49d",
        "char": "💝"
      },
      {
        "name": "revolving hearts",
        "unicode": "1f49e",
        "char": "💞"
      },
      {
        "name": "heart decoration",
        "unicode": "1f49f",
        "char": "💟"
      },
      {
        "name": "heavy heart exclamation mark ornament",
        "unicode": "2763",
        "char": "❣"
      },
      {
        "name": "anger symbol",
        "unicode": "1f4a2",
        "char": "💢"
      },
      {
        "name": "collision symbol",
        "unicode": "1f4a5",
        "char": "💥"
      },
      {
        "name": "dizzy symbol",
        "unicode": "1f4ab",
        "char": "💫"
      },
      {
        "name": "speech balloon",
        "unicode": "1f4ac",
        "char": "💬"
      },
      {
        "name": "left speech bubble",
        "unicode": "1f5e8",
        "char": "🗨"
      },
      {
        "name": "right anger bubble",
        "unicode": "1f5ef",
        "char": "🗯"
      },
      {
        "name": "thought balloon",
        "unicode": "1f4ad",
        "char": "💭"
      },
      {
        "name": "white flower",
        "unicode": "1f4ae",
        "char": "💮"
      },
      {
        "name": "globe with meridians",
        "unicode": "1f310",
        "char": "🌐"
      },
      {
        "name": "hot springs",
        "unicode": "2668",
        "char": "♨"
      },
      {
        "name": "octagonal sign",
        "unicode": "1f6d1",
        "char": "🛑"
      },
      {
        "name": "clock face twelve oclock",
        "unicode": "1f55b",
        "char": "🕛"
      },
      {
        "name": "clock face twelve-thirty",
        "unicode": "1f567",
        "char": "🕧"
      },
      {
        "name": "clock face one oclock",
        "unicode": "1f550",
        "char": "🕐"
      },
      {
        "name": "clock face one-thirty",
        "unicode": "1f55c",
        "char": "🕜"
      },
      {
        "name": "clock face two oclock",
        "unicode": "1f551",
        "char": "🕑"
      },
      {
        "name": "clock face two-thirty",
        "unicode": "1f55d",
        "char": "🕝"
      },
      {
        "name": "clock face three oclock",
        "unicode": "1f552",
        "char": "🕒"
      },
      {
        "name": "clock face three-thirty",
        "unicode": "1f55e",
        "char": "🕞"
      },
      {
        "name": "clock face four oclock",
        "unicode": "1f553",
        "char": "🕓"
      },
      {
        "name": "clock face four-thirty",
        "unicode": "1f55f",
        "char": "🕟"
      },
      {
        "name": "clock face five oclock",
        "unicode": "1f554",
        "char": "🕔"
      },
      {
        "name": "clock face five-thirty",
        "unicode": "1f560",
        "char": "🕠"
      },
      {
        "name": "clock face six oclock",
        "unicode": "1f555",
        "char": "🕕"
      },
      {
        "name": "clock face six-thirty",
        "unicode": "1f561",
        "char": "🕡"
      },
      {
        "name": "clock face seven oclock",
        "unicode": "1f556",
        "char": "🕖"
      },
      {
        "name": "clock face seven-thirty",
        "unicode": "1f562",
        "char": "🕢"
      },
      {
        "name": "clock face eight oclock",
        "unicode": "1f557",
        "char": "🕗"
      },
      {
        "name": "clock face eight-thirty",
        "unicode": "1f563",
        "char": "🕣"
      },
      {
        "name": "clock face nine oclock",
        "unicode": "1f558",
        "char": "🕘"
      },
      {
        "name": "clock face nine-thirty",
        "unicode": "1f564",
        "char": "🕤"
      },
      {
        "name": "clock face ten oclock",
        "unicode": "1f559",
        "char": "🕙"
      },
      {
        "name": "clock face ten-thirty",
        "unicode": "1f565",
        "char": "🕥"
      },
      {
        "name": "clock face eleven oclock",
        "unicode": "1f55a",
        "char": "🕚"
      },
      {
        "name": "clock face eleven-thirty",
        "unicode": "1f566",
        "char": "🕦"
      },
      {
        "name": "cyclone",
        "unicode": "1f300",
        "char": "🌀"
      },
      {
        "name": "black spade suit",
        "unicode": "2660",
        "char": "♠"
      },
      {
        "name": "black heart suit",
        "unicode": "2665",
        "char": "♥"
      },
      {
        "name": "black diamond suit",
        "unicode": "2666",
        "char": "♦"
      },
      {
        "name": "black club suit",
        "unicode": "2663",
        "char": "♣"
      },
      {
        "name": "playing card black joker",
        "unicode": "1f0cf",
        "char": "🃏"
      },
      {
        "name": "mahjong tile red dragon",
        "unicode": "1f004",
        "char": "🀄"
      },
      {
        "name": "flower playing cards",
        "unicode": "1f3b4",
        "char": "🎴"
      },
      {
        "name": "speaker with cancellation stroke",
        "unicode": "1f507",
        "char": "🔇"
      },
      {
        "name": "speaker",
        "unicode": "1f508",
        "char": "🔈"
      },
      {
        "name": "speaker with one sound wave",
        "unicode": "1f509",
        "char": "🔉"
      },
      {
        "name": "speaker with three sound waves",
        "unicode": "1f50a",
        "char": "🔊"
      },
      {
        "name": "public address loudspeaker",
        "unicode": "1f4e2",
        "char": "📢"
      },
      {
        "name": "cheering megaphone",
        "unicode": "1f4e3",
        "char": "📣"
      },
      {
        "name": "bell",
        "unicode": "1f514",
        "char": "🔔"
      },
      {
        "name": "bell with cancellation stroke",
        "unicode": "1f515",
        "char": "🔕"
      },
      {
        "name": "musical note",
        "unicode": "1f3b5",
        "char": "🎵"
      },
      {
        "name": "multiple musical notes",
        "unicode": "1f3b6",
        "char": "🎶"
      },
      {
        "name": "chart with upwards trend and yen sign",
        "unicode": "1f4b9",
        "char": "💹"
      },
      {
        "name": "currency exchange",
        "unicode": "1f4b1",
        "char": "💱"
      },
      {
        "name": "heavy dollar sign",
        "unicode": "1f4b2",
        "char": "💲"
      },
      {
        "name": "automated teller machine",
        "unicode": "1f3e7",
        "char": "🏧"
      },
      {
        "name": "put litter in its place symbol",
        "unicode": "1f6ae",
        "char": "🚮"
      },
      {
        "name": "potable water symbol",
        "unicode": "1f6b0",
        "char": "🚰"
      },
      {
        "name": "wheelchair symbol",
        "unicode": "267f",
        "char": "♿"
      },
      {
        "name": "mens symbol",
        "unicode": "1f6b9",
        "char": "🚹"
      },
      {
        "name": "womens symbol",
        "unicode": "1f6ba",
        "char": "🚺"
      },
      {
        "name": "restroom",
        "unicode": "1f6bb",
        "char": "🚻"
      },
      {
        "name": "baby symbol",
        "unicode": "1f6bc",
        "char": "🚼"
      },
      {
        "name": "water closet",
        "unicode": "1f6be",
        "char": "🚾"
      },
      {
        "name": "passport control",
        "unicode": "1f6c2",
        "char": "🛂"
      },
      {
        "name": "customs",
        "unicode": "1f6c3",
        "char": "🛃"
      },
      {
        "name": "baggage claim",
        "unicode": "1f6c4",
        "char": "🛄"
      },
      {
        "name": "left luggage",
        "unicode": "1f6c5",
        "char": "🛅"
      },
      {
        "name": "warning sign",
        "unicode": "26a0",
        "char": "⚠"
      },
      {
        "name": "children crossing",
        "unicode": "1f6b8",
        "char": "🚸"
      },
      {
        "name": "no entry",
        "unicode": "26d4",
        "char": "⛔"
      },
      {
        "name": "no entry sign",
        "unicode": "1f6ab",
        "char": "🚫"
      },
      {
        "name": "no bicycles",
        "unicode": "1f6b3",
        "char": "🚳"
      },
      {
        "name": "no smoking symbol",
        "unicode": "1f6ad",
        "char": "🚭"
      },
      {
        "name": "do not litter symbol",
        "unicode": "1f6af",
        "char": "🚯"
      },
      {
        "name": "non-potable water symbol",
        "unicode": "1f6b1",
        "char": "🚱"
      },
      {
        "name": "no pedestrians",
        "unicode": "1f6b7",
        "char": "🚷"
      },
      {
        "name": "no mobile phones",
        "unicode": "1f4f5",
        "char": "📵"
      },
      {
        "name": "no one under eighteen symbol",
        "unicode": "1f51e",
        "char": "🔞"
      },
      {
        "name": "radioactive sign",
        "unicode": "2622",
        "char": "☢"
      },
      {
        "name": "biohazard sign",
        "unicode": "2623",
        "char": "☣"
      },
      {
        "name": "upwards black arrow",
        "unicode": "2b06",
        "char": "⬆"
      },
      {
        "name": "north east arrow",
        "unicode": "2197",
        "char": "↗"
      },
      {
        "name": "black rightwards arrow",
        "unicode": "27a1",
        "char": "➡"
      },
      {
        "name": "south east arrow",
        "unicode": "2198",
        "char": "↘"
      },
      {
        "name": "downwards black arrow",
        "unicode": "2b07",
        "char": "⬇"
      },
      {
        "name": "south west arrow",
        "unicode": "2199",
        "char": "↙"
      },
      {
        "name": "leftwards black arrow",
        "unicode": "2b05",
        "char": "⬅"
      },
      {
        "name": "north west arrow",
        "unicode": "2196",
        "char": "↖"
      },
      {
        "name": "up down arrow",
        "unicode": "2195",
        "char": "↕"
      },
      {
        "name": "left right arrow",
        "unicode": "2194",
        "char": "↔"
      },
      {
        "name": "leftwards arrow with hook",
        "unicode": "21a9",
        "char": "↩"
      },
      {
        "name": "rightwards arrow with hook",
        "unicode": "21aa",
        "char": "↪"
      },
      {
        "name": "arrow pointing rightwards then curving upwards",
        "unicode": "2934",
        "char": "⤴"
      },
      {
        "name": "arrow pointing rightwards then curving downwards",
        "unicode": "2935",
        "char": "⤵"
      },
      {
        "name": "clockwise downwards and upwards open circle arrows",
        "unicode": "1f503",
        "char": "🔃"
      },
      {
        "name": "anticlockwise downwards and upwards open circle arrows",
        "unicode": "1f504",
        "char": "🔄"
      },
      {
        "name": "back with leftwards arrow above",
        "unicode": "1f519",
        "char": "🔙"
      },
      {
        "name": "end with leftwards arrow above",
        "unicode": "1f51a",
        "char": "🔚"
      },
      {
        "name": "on with exclamation mark with left right arrow abo",
        "unicode": "1f51b",
        "char": "🔛"
      },
      {
        "name": "soon with rightwards arrow above",
        "unicode": "1f51c",
        "char": "🔜"
      },
      {
        "name": "top with upwards arrow above",
        "unicode": "1f51d",
        "char": "🔝"
      },
      {
        "name": "place of worship",
        "unicode": "1f6d0",
        "char": "🛐"
      },
      {
        "name": "atom symbol",
        "unicode": "269b",
        "char": "⚛"
      },
      {
        "name": "om symbol",
        "unicode": "1f549",
        "char": "🕉"
      },
      {
        "name": "star of david",
        "unicode": "2721",
        "char": "✡"
      },
      {
        "name": "wheel of dharma",
        "unicode": "2638",
        "char": "☸"
      },
      {
        "name": "yin yang",
        "unicode": "262f",
        "char": "☯"
      },
      {
        "name": "latin cross",
        "unicode": "271d",
        "char": "✝"
      },
      {
        "name": "orthodox cross",
        "unicode": "2626",
        "char": "☦"
      },
      {
        "name": "star and crescent",
        "unicode": "262a",
        "char": "☪"
      },
      {
        "name": "peace symbol",
        "unicode": "262e",
        "char": "☮"
      },
      {
        "name": "menorah with nine branches",
        "unicode": "1f54e",
        "char": "🕎"
      },
      {
        "name": "six pointed star with middle dot",
        "unicode": "1f52f",
        "char": "🔯"
      },
      {
        "name": "aries",
        "unicode": "2648",
        "char": "♈"
      },
      {
        "name": "taurus",
        "unicode": "2649",
        "char": "♉"
      },
      {
        "name": "gemini",
        "unicode": "264a",
        "char": "♊"
      },
      {
        "name": "cancer",
        "unicode": "264b",
        "char": "♋"
      },
      {
        "name": "leo",
        "unicode": "264c",
        "char": "♌"
      },
      {
        "name": "virgo",
        "unicode": "264d",
        "char": "♍"
      },
      {
        "name": "libra",
        "unicode": "264e",
        "char": "♎"
      },
      {
        "name": "scorpius",
        "unicode": "264f",
        "char": "♏"
      },
      {
        "name": "sagittarius",
        "unicode": "2650",
        "char": "♐"
      },
      {
        "name": "capricorn",
        "unicode": "2651",
        "char": "♑"
      },
      {
        "name": "aquarius",
        "unicode": "2652",
        "char": "♒"
      },
      {
        "name": "pisces",
        "unicode": "2653",
        "char": "♓"
      },
      {
        "name": "ophiuchus",
        "unicode": "26ce",
        "char": "⛎"
      },
      {
        "name": "twisted rightwards arrows",
        "unicode": "1f500",
        "char": "🔀"
      },
      {
        "name": "clockwise rightwards and leftwards open circle arrows",
        "unicode": "1f501",
        "char": "🔁"
      },
      {
        "name": "clockwise rightwards and leftwards open circle arrows with circled one overlay",
        "unicode": "1f502",
        "char": "🔂"
      },
      {
        "name": "black right-pointing triangle",
        "unicode": "25b6",
        "char": "▶"
      },
      {
        "name": "black right-pointing double triangle",
        "unicode": "23e9",
        "char": "⏩"
      },
      {
        "name": "black right-pointing double triangle with vertical bar",
        "unicode": "23ed",
        "char": "⏭"
      },
      {
        "name": "black right-pointing double triangle with double vertical bar",
        "unicode": "23ef",
        "char": "⏯"
      },
      {
        "name": "black left-pointing triangle",
        "unicode": "25c0",
        "char": "◀"
      },
      {
        "name": "black left-pointing double triangle",
        "unicode": "23ea",
        "char": "⏪"
      },
      {
        "name": "black left-pointing double triangle with vertical bar",
        "unicode": "23ee",
        "char": "⏮"
      },
      {
        "name": "up-pointing small red triangle",
        "unicode": "1f53c",
        "char": "🔼"
      },
      {
        "name": "black up-pointing double triangle",
        "unicode": "23eb",
        "char": "⏫"
      },
      {
        "name": "down-pointing small red triangle",
        "unicode": "1f53d",
        "char": "🔽"
      },
      {
        "name": "black down-pointing double triangle",
        "unicode": "23ec",
        "char": "⏬"
      },
      {
        "name": "double vertical bar",
        "unicode": "23f8",
        "char": "⏸"
      },
      {
        "name": "black square for stop",
        "unicode": "23f9",
        "char": "⏹"
      },
      {
        "name": "black circle for record",
        "unicode": "23fa",
        "char": "⏺"
      },
      {
        "name": "eject symbol",
        "unicode": "23cf",
        "char": "⏏"
      },
      {
        "name": "cinema",
        "unicode": "1f3a6",
        "char": "🎦"
      },
      {
        "name": "low brightness symbol",
        "unicode": "1f505",
        "char": "🔅"
      },
      {
        "name": "high brightness symbol",
        "unicode": "1f506",
        "char": "🔆"
      },
      {
        "name": "antenna with bars",
        "unicode": "1f4f6",
        "char": "📶"
      },
      {
        "name": "vibration mode",
        "unicode": "1f4f3",
        "char": "📳"
      },
      {
        "name": "mobile phone off",
        "unicode": "1f4f4",
        "char": "📴"
      },
      {
        "name": "black universal recycling symbol",
        "unicode": "267b",
        "char": "♻"
      },
      {
        "name": "name badge",
        "unicode": "1f4db",
        "char": "📛"
      },
      {
        "name": "fleur-de-lis",
        "unicode": "269c",
        "char": "⚜"
      },
      {
        "name": "japanese symbol for beginner",
        "unicode": "1f530",
        "char": "🔰"
      },
      {
        "name": "trident emblem",
        "unicode": "1f531",
        "char": "🔱"
      },
      {
        "name": "heavy large circle",
        "unicode": "2b55",
        "char": "⭕"
      },
      {
        "name": "white heavy check mark",
        "unicode": "2705",
        "char": "✅"
      },
      {
        "name": "ballot box with check",
        "unicode": "2611",
        "char": "☑"
      },
      {
        "name": "heavy check mark",
        "unicode": "2714",
        "char": "✔"
      },
      {
        "name": "heavy multiplication x",
        "unicode": "2716",
        "char": "✖"
      },
      {
        "name": "cross mark",
        "unicode": "274c",
        "char": "❌"
      },
      {
        "name": "negative squared cross mark",
        "unicode": "274e",
        "char": "❎"
      },
      {
        "name": "heavy plus sign",
        "unicode": "2795",
        "char": "➕"
      },
      {
        "name": "heavy minus sign",
        "unicode": "2796",
        "char": "➖"
      },
      {
        "name": "heavy division sign",
        "unicode": "2797",
        "char": "➗"
      },
      {
        "name": "curly loop",
        "unicode": "27b0",
        "char": "➰"
      },
      {
        "name": "double curly loop",
        "unicode": "27bf",
        "char": "➿"
      },
      {
        "name": "part alternation mark",
        "unicode": "303d",
        "char": "〽"
      },
      {
        "name": "eight spoked asterisk",
        "unicode": "2733",
        "char": "✳"
      },
      {
        "name": "eight pointed black star",
        "unicode": "2734",
        "char": "✴"
      },
      {
        "name": "sparkle",
        "unicode": "2747",
        "char": "❇"
      },
      {
        "name": "double exclamation mark",
        "unicode": "203c",
        "char": "‼"
      },
      {
        "name": "exclamation question mark",
        "unicode": "2049",
        "char": "⁉"
      },
      {
        "name": "black question mark ornament",
        "unicode": "2753",
        "char": "❓"
      },
      {
        "name": "white question mark ornament",
        "unicode": "2754",
        "char": "❔"
      },
      {
        "name": "white exclamation mark ornament",
        "unicode": "2755",
        "char": "❕"
      },
      {
        "name": "heavy exclamation mark symbol",
        "unicode": "2757",
        "char": "❗"
      },
      {
        "name": "wavy dash",
        "unicode": "3030",
        "char": "〰"
      },
      {
        "name": "trade mark sign",
        "unicode": "2122",
        "char": "™"
      },
      {
        "name": "keycap ten",
        "unicode": "1f51f",
        "char": "🔟"
      },
      {
        "name": "hundred points symbol",
        "unicode": "1f4af",
        "char": "💯"
      },
      {
        "name": "input symbol for latin capital letters",
        "unicode": "1f520",
        "char": "🔠"
      },
      {
        "name": "input symbol for latin small letters",
        "unicode": "1f521",
        "char": "🔡"
      },
      {
        "name": "input symbol for numbers",
        "unicode": "1f522",
        "char": "🔢"
      },
      {
        "name": "input symbol for symbols",
        "unicode": "1f523",
        "char": "🔣"
      },
      {
        "name": "input symbol for latin letters",
        "unicode": "1f524",
        "char": "🔤"
      },
      {
        "name": "negative squared latin capital letter a",
        "unicode": "1f170",
        "char": "🅰"
      },
      {
        "name": "negative squared ab",
        "unicode": "1f18e",
        "char": "🆎"
      },
      {
        "name": "negative squared latin capital letter b",
        "unicode": "1f171",
        "char": "🅱"
      },
      {
        "name": "squared cl",
        "unicode": "1f191",
        "char": "🆑"
      },
      {
        "name": "squared cool",
        "unicode": "1f192",
        "char": "🆒"
      },
      {
        "name": "squared free",
        "unicode": "1f193",
        "char": "🆓"
      },
      {
        "name": "information source",
        "unicode": "2139",
        "char": "ℹ"
      },
      {
        "name": "squared id",
        "unicode": "1f194",
        "char": "🆔"
      },
      {
        "name": "circled latin capital letter m",
        "unicode": "24c2",
        "char": "Ⓜ"
      },
      {
        "name": "squared new",
        "unicode": "1f195",
        "char": "🆕"
      },
      {
        "name": "squared ng",
        "unicode": "1f196",
        "char": "🆖"
      },
      {
        "name": "negative squared latin capital letter o",
        "unicode": "1f17e",
        "char": "🅾"
      },
      {
        "name": "squared ok",
        "unicode": "1f197",
        "char": "🆗"
      },
      {
        "name": "negative squared latin capital letter p",
        "unicode": "1f17f",
        "char": "🅿"
      },
      {
        "name": "squared sos",
        "unicode": "1f198",
        "char": "🆘"
      },
      {
        "name": "squared up with exclamation mark",
        "unicode": "1f199",
        "char": "🆙"
      },
      {
        "name": "squared vs",
        "unicode": "1f19a",
        "char": "🆚"
      },
      {
        "name": "squared katakana koko",
        "unicode": "1f201",
        "char": "🈁"
      },
      {
        "name": "squared katakana sa",
        "unicode": "1f202",
        "char": "🈂"
      },
      {
        "name": "squared cjk unified ideograph-6708",
        "unicode": "1f237",
        "char": "🈷"
      },
      {
        "name": "squared cjk unified ideograph-6709",
        "unicode": "1f236",
        "char": "🈶"
      },
      {
        "name": "squared cjk unified ideograph-6307",
        "unicode": "1f22f",
        "char": "🈯"
      },
      {
        "name": "circled ideograph advantage",
        "unicode": "1f250",
        "char": "🉐"
      },
      {
        "name": "squared cjk unified ideograph-5272",
        "unicode": "1f239",
        "char": "🈹"
      },
      {
        "name": "squared cjk unified ideograph-7121",
        "unicode": "1f21a",
        "char": "🈚"
      },
      {
        "name": "squared cjk unified ideograph-7981",
        "unicode": "1f232",
        "char": "🈲"
      },
      {
        "name": "circled ideograph accept",
        "unicode": "1f251",
        "char": "🉑"
      },
      {
        "name": "squared cjk unified ideograph-7533",
        "unicode": "1f238",
        "char": "🈸"
      },
      {
        "name": "squared cjk unified ideograph-5408",
        "unicode": "1f234",
        "char": "🈴"
      },
      {
        "name": "squared cjk unified ideograph-7a7a",
        "unicode": "1f233",
        "char": "🈳"
      },
      {
        "name": "circled ideograph congratulation",
        "unicode": "3297",
        "char": "㊗"
      },
      {
        "name": "circled ideograph secret",
        "unicode": "3299",
        "char": "㊙"
      },
      {
        "name": "squared cjk unified ideograph-55b6",
        "unicode": "1f23a",
        "char": "🈺"
      },
      {
        "name": "squared cjk unified ideograph-6e80",
        "unicode": "1f235",
        "char": "🈵"
      },
      {
        "name": "black small square",
        "unicode": "25aa",
        "char": "▪"
      },
      {
        "name": "white small square",
        "unicode": "25ab",
        "char": "▫"
      },
      {
        "name": "white medium square",
        "unicode": "25fb",
        "char": "◻"
      },
      {
        "name": "black medium square",
        "unicode": "25fc",
        "char": "◼"
      },
      {
        "name": "white medium small square",
        "unicode": "25fd",
        "char": "◽"
      },
      {
        "name": "black medium small square",
        "unicode": "25fe",
        "char": "◾"
      },
      {
        "name": "black large square",
        "unicode": "2b1b",
        "char": "⬛"
      },
      {
        "name": "white large square",
        "unicode": "2b1c",
        "char": "⬜"
      },
      {
        "name": "large orange diamond",
        "unicode": "1f536",
        "char": "🔶"
      },
      {
        "name": "large blue diamond",
        "unicode": "1f537",
        "char": "🔷"
      },
      {
        "name": "small orange diamond",
        "unicode": "1f538",
        "char": "🔸"
      },
      {
        "name": "small blue diamond",
        "unicode": "1f539",
        "char": "🔹"
      },
      {
        "name": "up-pointing red triangle",
        "unicode": "1f53a",
        "char": "🔺"
      },
      {
        "name": "down-pointing red triangle",
        "unicode": "1f53b",
        "char": "🔻"
      },
      {
        "name": "diamond shape with a dot inside",
        "unicode": "1f4a0",
        "char": "💠"
      },
      {
        "name": "radio button",
        "unicode": "1f518",
        "char": "🔘"
      },
      {
        "name": "black square button",
        "unicode": "1f532",
        "char": "🔲"
      },
      {
        "name": "white square button",
        "unicode": "1f533",
        "char": "🔳"
      },
      {
        "name": "white circle",
        "unicode": "26aa",
        "char": "⚪"
      },
      {
        "name": "black circle",
        "unicode": "26ab",
        "char": "⚫"
      },
      {
        "name": "red circle",
        "unicode": "1f534",
        "char": "🔴"
      },
      {
        "name": "blue circle",
        "unicode": "1f535",
        "char": "🔵"
      }
    ]
  },
  {
    "name": "food",
    "unicode": "1f354",
    "char": "🍔",
    "emojis": [
      {
        "name": "grapes",
        "unicode": "1f347",
        "char": "🍇"
      },
      {
        "name": "melon",
        "unicode": "1f348",
        "char": "🍈"
      },
      {
        "name": "watermelon",
        "unicode": "1f349",
        "char": "🍉"
      },
      {
        "name": "tangerine",
        "unicode": "1f34a",
        "char": "🍊"
      },
      {
        "name": "lemon",
        "unicode": "1f34b",
        "char": "🍋"
      },
      {
        "name": "banana",
        "unicode": "1f34c",
        "char": "🍌"
      },
      {
        "name": "pineapple",
        "unicode": "1f34d",
        "char": "🍍"
      },
      {
        "name": "red apple",
        "unicode": "1f34e",
        "char": "🍎"
      },
      {
        "name": "green apple",
        "unicode": "1f34f",
        "char": "🍏"
      },
      {
        "name": "pear",
        "unicode": "1f350",
        "char": "🍐"
      },
      {
        "name": "peach",
        "unicode": "1f351",
        "char": "🍑"
      },
      {
        "name": "cherries",
        "unicode": "1f352",
        "char": "🍒"
      },
      {
        "name": "strawberry",
        "unicode": "1f353",
        "char": "🍓"
      },
      {
        "name": "kiwifruit",
        "unicode": "1f95d",
        "char": "🥝"
      },
      {
        "name": "tomato",
        "unicode": "1f345",
        "char": "🍅"
      },
      {
        "name": "avocado",
        "unicode": "1f951",
        "char": "🥑"
      },
      {
        "name": "aubergine",
        "unicode": "1f346",
        "char": "🍆"
      },
      {
        "name": "potato",
        "unicode": "1f954",
        "char": "🥔"
      },
      {
        "name": "carrot",
        "unicode": "1f955",
        "char": "🥕"
      },
      {
        "name": "ear of maize",
        "unicode": "1f33d",
        "char": "🌽"
      },
      {
        "name": "hot pepper",
        "unicode": "1f336",
        "char": "🌶"
      },
      {
        "name": "cucumber",
        "unicode": "1f952",
        "char": "🥒"
      },
      {
        "name": "peanuts",
        "unicode": "1f95c",
        "char": "🥜"
      },
      {
        "name": "bread",
        "unicode": "1f35e",
        "char": "🍞"
      },
      {
        "name": "croissant",
        "unicode": "1f950",
        "char": "🥐"
      },
      {
        "name": "baguette bread",
        "unicode": "1f956",
        "char": "🥖"
      },
      {
        "name": "pancakes",
        "unicode": "1f95e",
        "char": "🥞"
      },
      {
        "name": "cheese wedge",
        "unicode": "1f9c0",
        "char": "🧀"
      },
      {
        "name": "meat on bone",
        "unicode": "1f356",
        "char": "🍖"
      },
      {
        "name": "poultry leg",
        "unicode": "1f357",
        "char": "🍗"
      },
      {
        "name": "bacon",
        "unicode": "1f953",
        "char": "🥓"
      },
      {
        "name": "hamburger",
        "unicode": "1f354",
        "char": "🍔"
      },
      {
        "name": "french fries",
        "unicode": "1f35f",
        "char": "🍟"
      },
      {
        "name": "slice of pizza",
        "unicode": "1f355",
        "char": "🍕"
      },
      {
        "name": "hot dog",
        "unicode": "1f32d",
        "char": "🌭"
      },
      {
        "name": "taco",
        "unicode": "1f32e",
        "char": "🌮"
      },
      {
        "name": "burrito",
        "unicode": "1f32f",
        "char": "🌯"
      },
      {
        "name": "stuffed flatbread",
        "unicode": "1f959",
        "char": "🥙"
      },
      {
        "name": "egg",
        "unicode": "1f95a",
        "char": "🥚"
      },
      {
        "name": "cooking",
        "unicode": "1f373",
        "char": "🍳"
      },
      {
        "name": "shallow pan of food",
        "unicode": "1f958",
        "char": "🥘"
      },
      {
        "name": "pot of food",
        "unicode": "1f372",
        "char": "🍲"
      },
      {
        "name": "green salad",
        "unicode": "1f957",
        "char": "🥗"
      },
      {
        "name": "popcorn",
        "unicode": "1f37f",
        "char": "🍿"
      },
      {
        "name": "bento box",
        "unicode": "1f371",
        "char": "🍱"
      },
      {
        "name": "rice cracker",
        "unicode": "1f358",
        "char": "🍘"
      },
      {
        "name": "rice ball",
        "unicode": "1f359",
        "char": "🍙"
      },
      {
        "name": "cooked rice",
        "unicode": "1f35a",
        "char": "🍚"
      },
      {
        "name": "curry and rice",
        "unicode": "1f35b",
        "char": "🍛"
      },
      {
        "name": "steaming bowl",
        "unicode": "1f35c",
        "char": "🍜"
      },
      {
        "name": "spaghetti",
        "unicode": "1f35d",
        "char": "🍝"
      },
      {
        "name": "roasted sweet potato",
        "unicode": "1f360",
        "char": "🍠"
      },
      {
        "name": "oden",
        "unicode": "1f362",
        "char": "🍢"
      },
      {
        "name": "sushi",
        "unicode": "1f363",
        "char": "🍣"
      },
      {
        "name": "fried shrimp",
        "unicode": "1f364",
        "char": "🍤"
      },
      {
        "name": "fish cake with swirl design",
        "unicode": "1f365",
        "char": "🍥"
      },
      {
        "name": "dango",
        "unicode": "1f361",
        "char": "🍡"
      },
      {
        "name": "soft ice cream",
        "unicode": "1f366",
        "char": "🍦"
      },
      {
        "name": "shaved ice",
        "unicode": "1f367",
        "char": "🍧"
      },
      {
        "name": "ice cream",
        "unicode": "1f368",
        "char": "🍨"
      },
      {
        "name": "doughnut",
        "unicode": "1f369",
        "char": "🍩"
      },
      {
        "name": "cookie",
        "unicode": "1f36a",
        "char": "🍪"
      },
      {
        "name": "birthday cake",
        "unicode": "1f382",
        "char": "🎂"
      },
      {
        "name": "shortcake",
        "unicode": "1f370",
        "char": "🍰"
      },
      {
        "name": "chocolate bar",
        "unicode": "1f36b",
        "char": "🍫"
      },
      {
        "name": "candy",
        "unicode": "1f36c",
        "char": "🍬"
      },
      {
        "name": "lollipop",
        "unicode": "1f36d",
        "char": "🍭"
      },
      {
        "name": "custard",
        "unicode": "1f36e",
        "char": "🍮"
      },
      {
        "name": "honey pot",
        "unicode": "1f36f",
        "char": "🍯"
      },
      {
        "name": "baby bottle",
        "unicode": "1f37c",
        "char": "🍼"
      },
      {
        "name": "glass of milk",
        "unicode": "1f95b",
        "char": "🥛"
      },
      {
        "name": "hot beverage",
        "unicode": "2615",
        "char": "☕"
      },
      {
        "name": "teacup without handle",
        "unicode": "1f375",
        "char": "🍵"
      },
      {
        "name": "sake bottle and cup",
        "unicode": "1f376",
        "char": "🍶"
      },
      {
        "name": "bottle with popping cork",
        "unicode": "1f37e",
        "char": "🍾"
      },
      {
        "name": "wine glass",
        "unicode": "1f377",
        "char": "🍷"
      },
      {
        "name": "cocktail glass",
        "unicode": "1f378",
        "char": "🍸"
      },
      {
        "name": "tropical drink",
        "unicode": "1f379",
        "char": "🍹"
      },
      {
        "name": "beer mug",
        "unicode": "1f37a",
        "char": "🍺"
      },
      {
        "name": "clinking beer mugs",
        "unicode": "1f37b",
        "char": "🍻"
      },
      {
        "name": "clinking glasses",
        "unicode": "1f942",
        "char": "🥂"
      },
      {
        "name": "tumbler glass",
        "unicode": "1f943",
        "char": "🥃"
      },
      {
        "name": "fork and knife with plate",
        "unicode": "1f37d",
        "char": "🍽"
      },
      {
        "name": "fork and knife",
        "unicode": "1f374",
        "char": "🍴"
      },
      {
        "name": "spoon",
        "unicode": "1f944",
        "char": "🥄"
      }
    ]
  },
  {
    "name": "flags",
    "unicode": "1f1fa-1f1f8",
    "char": "🇺🇸",
    "emojis": [
      {
        "name": "ascension",
        "unicode": "1f1e6-1f1e8",
        "char": "🇦🇨"
      },
      {
        "name": "andorra",
        "unicode": "1f1e6-1f1e9",
        "char": "🇦🇩"
      },
      {
        "name": "the united arab emirates",
        "unicode": "1f1e6-1f1ea",
        "char": "🇦🇪"
      },
      {
        "name": "afghanistan",
        "unicode": "1f1e6-1f1eb",
        "char": "🇦🇫"
      },
      {
        "name": "antigua and barbuda",
        "unicode": "1f1e6-1f1ec",
        "char": "🇦🇬"
      },
      {
        "name": "anguilla",
        "unicode": "1f1e6-1f1ee",
        "char": "🇦🇮"
      },
      {
        "name": "albania",
        "unicode": "1f1e6-1f1f1",
        "char": "🇦🇱"
      },
      {
        "name": "armenia",
        "unicode": "1f1e6-1f1f2",
        "char": "🇦🇲"
      },
      {
        "name": "angola",
        "unicode": "1f1e6-1f1f4",
        "char": "🇦🇴"
      },
      {
        "name": "antarctica",
        "unicode": "1f1e6-1f1f6",
        "char": "🇦🇶"
      },
      {
        "name": "argentina",
        "unicode": "1f1e6-1f1f7",
        "char": "🇦🇷"
      },
      {
        "name": "american samoa",
        "unicode": "1f1e6-1f1f8",
        "char": "🇦🇸"
      },
      {
        "name": "austria",
        "unicode": "1f1e6-1f1f9",
        "char": "🇦🇹"
      },
      {
        "name": "australia",
        "unicode": "1f1e6-1f1fa",
        "char": "🇦🇺"
      },
      {
        "name": "aruba",
        "unicode": "1f1e6-1f1fc",
        "char": "🇦🇼"
      },
      {
        "name": "åland islands",
        "unicode": "1f1e6-1f1fd",
        "char": "🇦🇽"
      },
      {
        "name": "azerbaijan",
        "unicode": "1f1e6-1f1ff",
        "char": "🇦🇿"
      },
      {
        "name": "bosnia and herzegovina",
        "unicode": "1f1e7-1f1e6",
        "char": "🇧🇦"
      },
      {
        "name": "barbados",
        "unicode": "1f1e7-1f1e7",
        "char": "🇧🇧"
      },
      {
        "name": "bangladesh",
        "unicode": "1f1e7-1f1e9",
        "char": "🇧🇩"
      },
      {
        "name": "belgium",
        "unicode": "1f1e7-1f1ea",
        "char": "🇧🇪"
      },
      {
        "name": "burkina faso",
        "unicode": "1f1e7-1f1eb",
        "char": "🇧🇫"
      },
      {
        "name": "bulgaria",
        "unicode": "1f1e7-1f1ec",
        "char": "🇧🇬"
      },
      {
        "name": "bahrain",
        "unicode": "1f1e7-1f1ed",
        "char": "🇧🇭"
      },
      {
        "name": "burundi",
        "unicode": "1f1e7-1f1ee",
        "char": "🇧🇮"
      },
      {
        "name": "benin",
        "unicode": "1f1e7-1f1ef",
        "char": "🇧🇯"
      },
      {
        "name": "saint barthélemy",
        "unicode": "1f1e7-1f1f1",
        "char": "🇧🇱"
      },
      {
        "name": "bermuda",
        "unicode": "1f1e7-1f1f2",
        "char": "🇧🇲"
      },
      {
        "name": "brunei",
        "unicode": "1f1e7-1f1f3",
        "char": "🇧🇳"
      },
      {
        "name": "bolivia",
        "unicode": "1f1e7-1f1f4",
        "char": "🇧🇴"
      },
      {
        "name": "caribbean netherlands",
        "unicode": "1f1e7-1f1f6",
        "char": "🇧🇶"
      },
      {
        "name": "brazil",
        "unicode": "1f1e7-1f1f7",
        "char": "🇧🇷"
      },
      {
        "name": "the bahamas",
        "unicode": "1f1e7-1f1f8",
        "char": "🇧🇸"
      },
      {
        "name": "bhutan",
        "unicode": "1f1e7-1f1f9",
        "char": "🇧🇹"
      },
      {
        "name": "bouvet island",
        "unicode": "1f1e7-1f1fb",
        "char": "🇧🇻"
      },
      {
        "name": "botswana",
        "unicode": "1f1e7-1f1fc",
        "char": "🇧🇼"
      },
      {
        "name": "belarus",
        "unicode": "1f1e7-1f1fe",
        "char": "🇧🇾"
      },
      {
        "name": "belize",
        "unicode": "1f1e7-1f1ff",
        "char": "🇧🇿"
      },
      {
        "name": "canada",
        "unicode": "1f1e8-1f1e6",
        "char": "🇨🇦"
      },
      {
        "name": "cocos (keeling) islands",
        "unicode": "1f1e8-1f1e8",
        "char": "🇨🇨"
      },
      {
        "name": "the democratic republic of the congo",
        "unicode": "1f1e8-1f1e9",
        "char": "🇨🇩"
      },
      {
        "name": "central african republic",
        "unicode": "1f1e8-1f1eb",
        "char": "🇨🇫"
      },
      {
        "name": "the republic of the congo",
        "unicode": "1f1e8-1f1ec",
        "char": "🇨🇬"
      },
      {
        "name": "switzerland",
        "unicode": "1f1e8-1f1ed",
        "char": "🇨🇭"
      },
      {
        "name": "côte d’ivoire",
        "unicode": "1f1e8-1f1ee",
        "char": "🇨🇮"
      },
      {
        "name": "cook islands",
        "unicode": "1f1e8-1f1f0",
        "char": "🇨🇰"
      },
      {
        "name": "chile",
        "unicode": "1f1e8-1f1f1",
        "char": "🇨🇱"
      },
      {
        "name": "cameroon",
        "unicode": "1f1e8-1f1f2",
        "char": "🇨🇲"
      },
      {
        "name": "china",
        "unicode": "1f1e8-1f1f3",
        "char": "🇨🇳"
      },
      {
        "name": "colombia",
        "unicode": "1f1e8-1f1f4",
        "char": "🇨🇴"
      },
      {
        "name": "clipperton island",
        "unicode": "1f1e8-1f1f5",
        "char": "🇨🇵"
      },
      {
        "name": "costa rica",
        "unicode": "1f1e8-1f1f7",
        "char": "🇨🇷"
      },
      {
        "name": "cuba",
        "unicode": "1f1e8-1f1fa",
        "char": "🇨🇺"
      },
      {
        "name": "cape verde",
        "unicode": "1f1e8-1f1fb",
        "char": "🇨🇻"
      },
      {
        "name": "curaçao",
        "unicode": "1f1e8-1f1fc",
        "char": "🇨🇼"
      },
      {
        "name": "christmas island",
        "unicode": "1f1e8-1f1fd",
        "char": "🇨🇽"
      },
      {
        "name": "cyprus",
        "unicode": "1f1e8-1f1fe",
        "char": "🇨🇾"
      },
      {
        "name": "the czech republic",
        "unicode": "1f1e8-1f1ff",
        "char": "🇨🇿"
      },
      {
        "name": "germany",
        "unicode": "1f1e9-1f1ea",
        "char": "🇩🇪"
      },
      {
        "name": "diego garcia",
        "unicode": "1f1e9-1f1ec",
        "char": "🇩🇬"
      },
      {
        "name": "djibouti",
        "unicode": "1f1e9-1f1ef",
        "char": "🇩🇯"
      },
      {
        "name": "denmark",
        "unicode": "1f1e9-1f1f0",
        "char": "🇩🇰"
      },
      {
        "name": "dominica",
        "unicode": "1f1e9-1f1f2",
        "char": "🇩🇲"
      },
      {
        "name": "the dominican republic",
        "unicode": "1f1e9-1f1f4",
        "char": "🇩🇴"
      },
      {
        "name": "algeria",
        "unicode": "1f1e9-1f1ff",
        "char": "🇩🇿"
      },
      {
        "name": "ceuta, melilla",
        "unicode": "1f1ea-1f1e6",
        "char": "🇪🇦"
      },
      {
        "name": "ecuador",
        "unicode": "1f1ea-1f1e8",
        "char": "🇪🇨"
      },
      {
        "name": "estonia",
        "unicode": "1f1ea-1f1ea",
        "char": "🇪🇪"
      },
      {
        "name": "egypt",
        "unicode": "1f1ea-1f1ec",
        "char": "🇪🇬"
      },
      {
        "name": "western sahara",
        "unicode": "1f1ea-1f1ed",
        "char": "🇪🇭"
      },
      {
        "name": "eritrea",
        "unicode": "1f1ea-1f1f7",
        "char": "🇪🇷"
      },
      {
        "name": "spain",
        "unicode": "1f1ea-1f1f8",
        "char": "🇪🇸"
      },
      {
        "name": "ethiopia",
        "unicode": "1f1ea-1f1f9",
        "char": "🇪🇹"
      },
      {
        "name": "european union",
        "unicode": "1f1ea-1f1fa",
        "char": "🇪🇺"
      },
      {
        "name": "finland",
        "unicode": "1f1eb-1f1ee",
        "char": "🇫🇮"
      },
      {
        "name": "fiji",
        "unicode": "1f1eb-1f1ef",
        "char": "🇫🇯"
      },
      {
        "name": "falkland islands",
        "unicode": "1f1eb-1f1f0",
        "char": "🇫🇰"
      },
      {
        "name": "micronesia",
        "unicode": "1f1eb-1f1f2",
        "char": "🇫🇲"
      },
      {
        "name": "faroe islands",
        "unicode": "1f1eb-1f1f4",
        "char": "🇫🇴"
      },
      {
        "name": "france",
        "unicode": "1f1eb-1f1f7",
        "char": "🇫🇷"
      },
      {
        "name": "gabon",
        "unicode": "1f1ec-1f1e6",
        "char": "🇬🇦"
      },
      {
        "name": "great britain",
        "unicode": "1f1ec-1f1e7",
        "char": "🇬🇧"
      },
      {
        "name": "grenada",
        "unicode": "1f1ec-1f1e9",
        "char": "🇬🇩"
      },
      {
        "name": "georgia",
        "unicode": "1f1ec-1f1ea",
        "char": "🇬🇪"
      },
      {
        "name": "french guiana",
        "unicode": "1f1ec-1f1eb",
        "char": "🇬🇫"
      },
      {
        "name": "guernsey",
        "unicode": "1f1ec-1f1ec",
        "char": "🇬🇬"
      },
      {
        "name": "ghana",
        "unicode": "1f1ec-1f1ed",
        "char": "🇬🇭"
      },
      {
        "name": "gibraltar",
        "unicode": "1f1ec-1f1ee",
        "char": "🇬🇮"
      },
      {
        "name": "greenland",
        "unicode": "1f1ec-1f1f1",
        "char": "🇬🇱"
      },
      {
        "name": "the gambia",
        "unicode": "1f1ec-1f1f2",
        "char": "🇬🇲"
      },
      {
        "name": "guinea",
        "unicode": "1f1ec-1f1f3",
        "char": "🇬🇳"
      },
      {
        "name": "guadeloupe",
        "unicode": "1f1ec-1f1f5",
        "char": "🇬🇵"
      },
      {
        "name": "equatorial guinea",
        "unicode": "1f1ec-1f1f6",
        "char": "🇬🇶"
      },
      {
        "name": "greece",
        "unicode": "1f1ec-1f1f7",
        "char": "🇬🇷"
      },
      {
        "name": "south georgia",
        "unicode": "1f1ec-1f1f8",
        "char": "🇬🇸"
      },
      {
        "name": "guatemala",
        "unicode": "1f1ec-1f1f9",
        "char": "🇬🇹"
      },
      {
        "name": "guam",
        "unicode": "1f1ec-1f1fa",
        "char": "🇬🇺"
      },
      {
        "name": "guinea-bissau",
        "unicode": "1f1ec-1f1fc",
        "char": "🇬🇼"
      },
      {
        "name": "guyana",
        "unicode": "1f1ec-1f1fe",
        "char": "🇬🇾"
      },
      {
        "name": "hong kong",
        "unicode": "1f1ed-1f1f0",
        "char": "🇭🇰"
      },
      {
        "name": "heard island and mcdonald islands",
        "unicode": "1f1ed-1f1f2",
        "char": "🇭🇲"
      },
      {
        "name": "honduras",
        "unicode": "1f1ed-1f1f3",
        "char": "🇭🇳"
      },
      {
        "name": "croatia",
        "unicode": "1f1ed-1f1f7",
        "char": "🇭🇷"
      },
      {
        "name": "haiti",
        "unicode": "1f1ed-1f1f9",
        "char": "🇭🇹"
      },
      {
        "name": "hungary",
        "unicode": "1f1ed-1f1fa",
        "char": "🇭🇺"
      },
      {
        "name": "canary islands",
        "unicode": "1f1ee-1f1e8",
        "char": "🇮🇨"
      },
      {
        "name": "indonesia",
        "unicode": "1f1ee-1f1e9",
        "char": "🇮🇩"
      },
      {
        "name": "ireland",
        "unicode": "1f1ee-1f1ea",
        "char": "🇮🇪"
      },
      {
        "name": "israel",
        "unicode": "1f1ee-1f1f1",
        "char": "🇮🇱"
      },
      {
        "name": "isle of man",
        "unicode": "1f1ee-1f1f2",
        "char": "🇮🇲"
      },
      {
        "name": "india",
        "unicode": "1f1ee-1f1f3",
        "char": "🇮🇳"
      },
      {
        "name": "british indian ocean territory",
        "unicode": "1f1ee-1f1f4",
        "char": "🇮🇴"
      },
      {
        "name": "iraq",
        "unicode": "1f1ee-1f1f6",
        "char": "🇮🇶"
      },
      {
        "name": "iran",
        "unicode": "1f1ee-1f1f7",
        "char": "🇮🇷"
      },
      {
        "name": "iceland",
        "unicode": "1f1ee-1f1f8",
        "char": "🇮🇸"
      },
      {
        "name": "italy",
        "unicode": "1f1ee-1f1f9",
        "char": "🇮🇹"
      },
      {
        "name": "jersey",
        "unicode": "1f1ef-1f1ea",
        "char": "🇯🇪"
      },
      {
        "name": "jamaica",
        "unicode": "1f1ef-1f1f2",
        "char": "🇯🇲"
      },
      {
        "name": "jordan",
        "unicode": "1f1ef-1f1f4",
        "char": "🇯🇴"
      },
      {
        "name": "japan",
        "unicode": "1f1ef-1f1f5",
        "char": "🇯🇵"
      },
      {
        "name": "kenya",
        "unicode": "1f1f0-1f1ea",
        "char": "🇰🇪"
      },
      {
        "name": "kyrgyzstan",
        "unicode": "1f1f0-1f1ec",
        "char": "🇰🇬"
      },
      {
        "name": "cambodia",
        "unicode": "1f1f0-1f1ed",
        "char": "🇰🇭"
      },
      {
        "name": "kiribati",
        "unicode": "1f1f0-1f1ee",
        "char": "🇰🇮"
      },
      {
        "name": "the comoros",
        "unicode": "1f1f0-1f1f2",
        "char": "🇰🇲"
      },
      {
        "name": "saint kitts and nevis",
        "unicode": "1f1f0-1f1f3",
        "char": "🇰🇳"
      },
      {
        "name": "north korea",
        "unicode": "1f1f0-1f1f5",
        "char": "🇰🇵"
      },
      {
        "name": "korea",
        "unicode": "1f1f0-1f1f7",
        "char": "🇰🇷"
      },
      {
        "name": "kuwait",
        "unicode": "1f1f0-1f1fc",
        "char": "🇰🇼"
      },
      {
        "name": "cayman islands",
        "unicode": "1f1f0-1f1fe",
        "char": "🇰🇾"
      },
      {
        "name": "kazakhstan",
        "unicode": "1f1f0-1f1ff",
        "char": "🇰🇿"
      },
      {
        "name": "laos",
        "unicode": "1f1f1-1f1e6",
        "char": "🇱🇦"
      },
      {
        "name": "lebanon",
        "unicode": "1f1f1-1f1e7",
        "char": "🇱🇧"
      },
      {
        "name": "saint lucia",
        "unicode": "1f1f1-1f1e8",
        "char": "🇱🇨"
      },
      {
        "name": "liechtenstein",
        "unicode": "1f1f1-1f1ee",
        "char": "🇱🇮"
      },
      {
        "name": "sri lanka",
        "unicode": "1f1f1-1f1f0",
        "char": "🇱🇰"
      },
      {
        "name": "liberia",
        "unicode": "1f1f1-1f1f7",
        "char": "🇱🇷"
      },
      {
        "name": "lesotho",
        "unicode": "1f1f1-1f1f8",
        "char": "🇱🇸"
      },
      {
        "name": "lithuania",
        "unicode": "1f1f1-1f1f9",
        "char": "🇱🇹"
      },
      {
        "name": "luxembourg",
        "unicode": "1f1f1-1f1fa",
        "char": "🇱🇺"
      },
      {
        "name": "latvia",
        "unicode": "1f1f1-1f1fb",
        "char": "🇱🇻"
      },
      {
        "name": "libya",
        "unicode": "1f1f1-1f1fe",
        "char": "🇱🇾"
      },
      {
        "name": "morocco",
        "unicode": "1f1f2-1f1e6",
        "char": "🇲🇦"
      },
      {
        "name": "monaco",
        "unicode": "1f1f2-1f1e8",
        "char": "🇲🇨"
      },
      {
        "name": "moldova",
        "unicode": "1f1f2-1f1e9",
        "char": "🇲🇩"
      },
      {
        "name": "montenegro",
        "unicode": "1f1f2-1f1ea",
        "char": "🇲🇪"
      },
      {
        "name": "saint martin",
        "unicode": "1f1f2-1f1eb",
        "char": "🇲🇫"
      },
      {
        "name": "madagascar",
        "unicode": "1f1f2-1f1ec",
        "char": "🇲🇬"
      },
      {
        "name": "the marshall islands",
        "unicode": "1f1f2-1f1ed",
        "char": "🇲🇭"
      },
      {
        "name": "macedonia",
        "unicode": "1f1f2-1f1f0",
        "char": "🇲🇰"
      },
      {
        "name": "mali",
        "unicode": "1f1f2-1f1f1",
        "char": "🇲🇱"
      },
      {
        "name": "myanmar",
        "unicode": "1f1f2-1f1f2",
        "char": "🇲🇲"
      },
      {
        "name": "mongolia",
        "unicode": "1f1f2-1f1f3",
        "char": "🇲🇳"
      },
      {
        "name": "macau",
        "unicode": "1f1f2-1f1f4",
        "char": "🇲🇴"
      },
      {
        "name": "northern mariana islands",
        "unicode": "1f1f2-1f1f5",
        "char": "🇲🇵"
      },
      {
        "name": "martinique",
        "unicode": "1f1f2-1f1f6",
        "char": "🇲🇶"
      },
      {
        "name": "mauritania",
        "unicode": "1f1f2-1f1f7",
        "char": "🇲🇷"
      },
      {
        "name": "montserrat",
        "unicode": "1f1f2-1f1f8",
        "char": "🇲🇸"
      },
      {
        "name": "malta",
        "unicode": "1f1f2-1f1f9",
        "char": "🇲🇹"
      },
      {
        "name": "mauritius",
        "unicode": "1f1f2-1f1fa",
        "char": "🇲🇺"
      },
      {
        "name": "maldives",
        "unicode": "1f1f2-1f1fb",
        "char": "🇲🇻"
      },
      {
        "name": "malawi",
        "unicode": "1f1f2-1f1fc",
        "char": "🇲🇼"
      },
      {
        "name": "mexico",
        "unicode": "1f1f2-1f1fd",
        "char": "🇲🇽"
      },
      {
        "name": "malaysia",
        "unicode": "1f1f2-1f1fe",
        "char": "🇲🇾"
      },
      {
        "name": "mozambique",
        "unicode": "1f1f2-1f1ff",
        "char": "🇲🇿"
      },
      {
        "name": "namibia",
        "unicode": "1f1f3-1f1e6",
        "char": "🇳🇦"
      },
      {
        "name": "new caledonia",
        "unicode": "1f1f3-1f1e8",
        "char": "🇳🇨"
      },
      {
        "name": "niger",
        "unicode": "1f1f3-1f1ea",
        "char": "🇳🇪"
      },
      {
        "name": "norfolk island",
        "unicode": "1f1f3-1f1eb",
        "char": "🇳🇫"
      },
      {
        "name": "nigeria",
        "unicode": "1f1f3-1f1ec",
        "char": "🇳🇬"
      },
      {
        "name": "nicaragua",
        "unicode": "1f1f3-1f1ee",
        "char": "🇳🇮"
      },
      {
        "name": "the netherlands",
        "unicode": "1f1f3-1f1f1",
        "char": "🇳🇱"
      },
      {
        "name": "norway",
        "unicode": "1f1f3-1f1f4",
        "char": "🇳🇴"
      },
      {
        "name": "nepal",
        "unicode": "1f1f3-1f1f5",
        "char": "🇳🇵"
      },
      {
        "name": "nauru",
        "unicode": "1f1f3-1f1f7",
        "char": "🇳🇷"
      },
      {
        "name": "niue",
        "unicode": "1f1f3-1f1fa",
        "char": "🇳🇺"
      },
      {
        "name": "new zealand",
        "unicode": "1f1f3-1f1ff",
        "char": "🇳🇿"
      },
      {
        "name": "oman",
        "unicode": "1f1f4-1f1f2",
        "char": "🇴🇲"
      },
      {
        "name": "panama",
        "unicode": "1f1f5-1f1e6",
        "char": "🇵🇦"
      },
      {
        "name": "peru",
        "unicode": "1f1f5-1f1ea",
        "char": "🇵🇪"
      },
      {
        "name": "french polynesia",
        "unicode": "1f1f5-1f1eb",
        "char": "🇵🇫"
      },
      {
        "name": "papua new guinea",
        "unicode": "1f1f5-1f1ec",
        "char": "🇵🇬"
      },
      {
        "name": "the philippines",
        "unicode": "1f1f5-1f1ed",
        "char": "🇵🇭"
      },
      {
        "name": "pakistan",
        "unicode": "1f1f5-1f1f0",
        "char": "🇵🇰"
      },
      {
        "name": "poland",
        "unicode": "1f1f5-1f1f1",
        "char": "🇵🇱"
      },
      {
        "name": "saint pierre and miquelon",
        "unicode": "1f1f5-1f1f2",
        "char": "🇵🇲"
      },
      {
        "name": "pitcairn",
        "unicode": "1f1f5-1f1f3",
        "char": "🇵🇳"
      },
      {
        "name": "puerto rico",
        "unicode": "1f1f5-1f1f7",
        "char": "🇵🇷"
      },
      {
        "name": "palestinian authority",
        "unicode": "1f1f5-1f1f8",
        "char": "🇵🇸"
      },
      {
        "name": "portugal",
        "unicode": "1f1f5-1f1f9",
        "char": "🇵🇹"
      },
      {
        "name": "palau",
        "unicode": "1f1f5-1f1fc",
        "char": "🇵🇼"
      },
      {
        "name": "paraguay",
        "unicode": "1f1f5-1f1fe",
        "char": "🇵🇾"
      },
      {
        "name": "qatar",
        "unicode": "1f1f6-1f1e6",
        "char": "🇶🇦"
      },
      {
        "name": "réunion",
        "unicode": "1f1f7-1f1ea",
        "char": "🇷🇪"
      },
      {
        "name": "romania",
        "unicode": "1f1f7-1f1f4",
        "char": "🇷🇴"
      },
      {
        "name": "serbia",
        "unicode": "1f1f7-1f1f8",
        "char": "🇷🇸"
      },
      {
        "name": "russia",
        "unicode": "1f1f7-1f1fa",
        "char": "🇷🇺"
      },
      {
        "name": "rwanda",
        "unicode": "1f1f7-1f1fc",
        "char": "🇷🇼"
      },
      {
        "name": "saudi arabia",
        "unicode": "1f1f8-1f1e6",
        "char": "🇸🇦"
      },
      {
        "name": "the solomon islands",
        "unicode": "1f1f8-1f1e7",
        "char": "🇸🇧"
      },
      {
        "name": "the seychelles",
        "unicode": "1f1f8-1f1e8",
        "char": "🇸🇨"
      },
      {
        "name": "sudan",
        "unicode": "1f1f8-1f1e9",
        "char": "🇸🇩"
      },
      {
        "name": "sweden",
        "unicode": "1f1f8-1f1ea",
        "char": "🇸🇪"
      },
      {
        "name": "singapore",
        "unicode": "1f1f8-1f1ec",
        "char": "🇸🇬"
      },
      {
        "name": "saint helena",
        "unicode": "1f1f8-1f1ed",
        "char": "🇸🇭"
      },
      {
        "name": "slovenia",
        "unicode": "1f1f8-1f1ee",
        "char": "🇸🇮"
      },
      {
        "name": "svalbard and jan mayen",
        "unicode": "1f1f8-1f1ef",
        "char": "🇸🇯"
      },
      {
        "name": "slovakia",
        "unicode": "1f1f8-1f1f0",
        "char": "🇸🇰"
      },
      {
        "name": "sierra leone",
        "unicode": "1f1f8-1f1f1",
        "char": "🇸🇱"
      },
      {
        "name": "san marino",
        "unicode": "1f1f8-1f1f2",
        "char": "🇸🇲"
      },
      {
        "name": "senegal",
        "unicode": "1f1f8-1f1f3",
        "char": "🇸🇳"
      },
      {
        "name": "somalia",
        "unicode": "1f1f8-1f1f4",
        "char": "🇸🇴"
      },
      {
        "name": "suriname",
        "unicode": "1f1f8-1f1f7",
        "char": "🇸🇷"
      },
      {
        "name": "south sudan",
        "unicode": "1f1f8-1f1f8",
        "char": "🇸🇸"
      },
      {
        "name": "são tomé and príncipe",
        "unicode": "1f1f8-1f1f9",
        "char": "🇸🇹"
      },
      {
        "name": "el salvador",
        "unicode": "1f1f8-1f1fb",
        "char": "🇸🇻"
      },
      {
        "name": "sint maarten",
        "unicode": "1f1f8-1f1fd",
        "char": "🇸🇽"
      },
      {
        "name": "syria",
        "unicode": "1f1f8-1f1fe",
        "char": "🇸🇾"
      },
      {
        "name": "swaziland",
        "unicode": "1f1f8-1f1ff",
        "char": "🇸🇿"
      },
      {
        "name": "tristan da cunha",
        "unicode": "1f1f9-1f1e6",
        "char": "🇹🇦"
      },
      {
        "name": "turks and caicos islands",
        "unicode": "1f1f9-1f1e8",
        "char": "🇹🇨"
      },
      {
        "name": "chad",
        "unicode": "1f1f9-1f1e9",
        "char": "🇹🇩"
      },
      {
        "name": "french southern territories",
        "unicode": "1f1f9-1f1eb",
        "char": "🇹🇫"
      },
      {
        "name": "togo",
        "unicode": "1f1f9-1f1ec",
        "char": "🇹🇬"
      },
      {
        "name": "thailand",
        "unicode": "1f1f9-1f1ed",
        "char": "🇹🇭"
      },
      {
        "name": "tajikistan",
        "unicode": "1f1f9-1f1ef",
        "char": "🇹🇯"
      },
      {
        "name": "tokelau",
        "unicode": "1f1f9-1f1f0",
        "char": "🇹🇰"
      },
      {
        "name": "timor-leste",
        "unicode": "1f1f9-1f1f1",
        "char": "🇹🇱"
      },
      {
        "name": "turkmenistan",
        "unicode": "1f1f9-1f1f2",
        "char": "🇹🇲"
      },
      {
        "name": "tunisia",
        "unicode": "1f1f9-1f1f3",
        "char": "🇹🇳"
      },
      {
        "name": "tonga",
        "unicode": "1f1f9-1f1f4",
        "char": "🇹🇴"
      },
      {
        "name": "turkey",
        "unicode": "1f1f9-1f1f7",
        "char": "🇹🇷"
      },
      {
        "name": "trinidad and tobago",
        "unicode": "1f1f9-1f1f9",
        "char": "🇹🇹"
      },
      {
        "name": "tuvalu",
        "unicode": "1f1f9-1f1fb",
        "char": "🇹🇻"
      },
      {
        "name": "the republic of china",
        "unicode": "1f1f9-1f1fc",
        "char": "🇹🇼"
      },
      {
        "name": "tanzania",
        "unicode": "1f1f9-1f1ff",
        "char": "🇹🇿"
      },
      {
        "name": "ukraine",
        "unicode": "1f1fa-1f1e6",
        "char": "🇺🇦"
      },
      {
        "name": "uganda",
        "unicode": "1f1fa-1f1ec",
        "char": "🇺🇬"
      },
      {
        "name": "united states minor outlying islands",
        "unicode": "1f1fa-1f1f2",
        "char": "🇺🇲"
      },
      {
        "name": "united states",
        "unicode": "1f1fa-1f1f8",
        "char": "🇺🇸"
      },
      {
        "name": "uruguay",
        "unicode": "1f1fa-1f1fe",
        "char": "🇺🇾"
      },
      {
        "name": "uzbekistan",
        "unicode": "1f1fa-1f1ff",
        "char": "🇺🇿"
      },
      {
        "name": "the vatican city",
        "unicode": "1f1fb-1f1e6",
        "char": "🇻🇦"
      },
      {
        "name": "saint vincent and the grenadines",
        "unicode": "1f1fb-1f1e8",
        "char": "🇻🇨"
      },
      {
        "name": "venezuela",
        "unicode": "1f1fb-1f1ea",
        "char": "🇻🇪"
      },
      {
        "name": "british virgin islands",
        "unicode": "1f1fb-1f1ec",
        "char": "🇻🇬"
      },
      {
        "name": "u.s. virgin islands",
        "unicode": "1f1fb-1f1ee",
        "char": "🇻🇮"
      },
      {
        "name": "vietnam",
        "unicode": "1f1fb-1f1f3",
        "char": "🇻🇳"
      },
      {
        "name": "vanuatu",
        "unicode": "1f1fb-1f1fa",
        "char": "🇻🇺"
      },
      {
        "name": "wallis and futuna",
        "unicode": "1f1fc-1f1eb",
        "char": "🇼🇫"
      },
      {
        "name": "samoa",
        "unicode": "1f1fc-1f1f8",
        "char": "🇼🇸"
      },
      {
        "name": "kosovo",
        "unicode": "1f1fd-1f1f0",
        "char": "🇽🇰"
      },
      {
        "name": "yemen",
        "unicode": "1f1fe-1f1ea",
        "char": "🇾🇪"
      },
      {
        "name": "mayotte",
        "unicode": "1f1fe-1f1f9",
        "char": "🇾🇹"
      },
      {
        "name": "south africa",
        "unicode": "1f1ff-1f1e6",
        "char": "🇿🇦"
      },
      {
        "name": "zambia",
        "unicode": "1f1ff-1f1f2",
        "char": "🇿🇲"
      },
      {
        "name": "zimbabwe",
        "unicode": "1f1ff-1f1fc",
        "char": "🇿🇼"
      }
    ]
  },
  {
    "name": "alphabet",
    "unicode": "1f1e6",
    "char": "🇦",
    "emojis": [
      {
        "name": "regional indicator symbol letter a",
        "unicode": "1f1e6",
        "char": "🇦"
      },
      {
        "name": "regional indicator symbol letter b",
        "unicode": "1f1e7",
        "char": "🇧"
      },
      {
        "name": "regional indicator symbol letter c",
        "unicode": "1f1e8",
        "char": "🇨"
      },
      {
        "name": "regional indicator symbol letter d",
        "unicode": "1f1e9",
        "char": "🇩"
      },
      {
        "name": "regional indicator symbol letter e",
        "unicode": "1f1ea",
        "char": "🇪"
      },
      {
        "name": "regional indicator symbol letter f",
        "unicode": "1f1eb",
        "char": "🇫"
      },
      {
        "name": "regional indicator symbol letter g",
        "unicode": "1f1ec",
        "char": "🇬"
      },
      {
        "name": "regional indicator symbol letter h",
        "unicode": "1f1ed",
        "char": "🇭"
      },
      {
        "name": "regional indicator symbol letter i",
        "unicode": "1f1ee",
        "char": "🇮"
      },
      {
        "name": "regional indicator symbol letter j",
        "unicode": "1f1ef",
        "char": "🇯"
      },
      {
        "name": "regional indicator symbol letter k",
        "unicode": "1f1f0",
        "char": "🇰"
      },
      {
        "name": "regional indicator symbol letter l",
        "unicode": "1f1f1",
        "char": "🇱"
      },
      {
        "name": "regional indicator symbol letter m",
        "unicode": "1f1f2",
        "char": "🇲"
      },
      {
        "name": "regional indicator symbol letter n",
        "unicode": "1f1f3",
        "char": "🇳"
      },
      {
        "name": "regional indicator symbol letter o",
        "unicode": "1f1f4",
        "char": "🇴"
      },
      {
        "name": "regional indicator symbol letter p",
        "unicode": "1f1f5",
        "char": "🇵"
      },
      {
        "name": "regional indicator symbol letter q",
        "unicode": "1f1f6",
        "char": "🇶"
      },
      {
        "name": "regional indicator symbol letter r",
        "unicode": "1f1f7",
        "char": "🇷"
      },
      {
        "name": "regional indicator symbol letter s",
        "unicode": "1f1f8",
        "char": "🇸"
      },
      {
        "name": "regional indicator symbol letter t",
        "unicode": "1f1f9",
        "char": "🇹"
      },
      {
        "name": "regional indicator symbol letter u",
        "unicode": "1f1fa",
        "char": "🇺"
      },
      {
        "name": "regional indicator symbol letter v",
        "unicode": "1f1fb",
        "char": "🇻"
      },
      {
        "name": "regional indicator symbol letter w",
        "unicode": "1f1fc",
        "char": "🇼"
      },
      {
        "name": "regional indicator symbol letter x",
        "unicode": "1f1fd",
        "char": "🇽"
      },
      {
        "name": "regional indicator symbol letter y",
        "unicode": "1f1fe",
        "char": "🇾"
      },
      {
        "name": "regional indicator symbol letter z",
        "unicode": "1f1ff",
        "char": "🇿"
      }
    ]
  }
];
