<template>
  <div class="fixed inset-0 overflow-hidden z-50">
    <div class="absolute inset-0 overflow-hidden">
      <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" aria-labelledby="slide-over-heading">
        <div class="w-screen max-w-2xl">
          <div class="h-full flex flex-col bg-white shadow-xl">
            <div class="min-h-0 flex-1 flex flex-col overflow-y-scroll">
              <!-- Header -->
              <div
                class="px-4 py-6 sm:px-6"
                :class="'bg-gray-100'"
              >
                <div class="flex items-center justify-between space-x-3">
                  <div class="flex-none">
                    <div
                      class="items-center w-10 h-10 rounded-full"
                      :class="'bg-gray-200 text-gray-900'"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z" />
                      </svg>
                    </div>
                  </div>
                  <div class="space-y-1 flex-grow">
                    <h2 class="text-lg font-medium text-gray-900">
                      Notes
                    </h2>
                  </div>
                  <div class="h-7 flex-none items-center">
                    <button
                      @click="notesEditStop"
                      class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      type="button"
                    >
                      <span class="sr-only">Close panel</span>
                      <!-- Heroicon name: outline/x -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <!-- Time entries -->
              <div v-if="mode === 'info'" class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                <div class="flex flex-col space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div class="">
                    <div class="rounded-md bg-blue-50 p-4">
                      <div class="flex">
                        <div class="flex-shrink-0">
                          <!-- Heroicon name: solid/information-circle -->
                          <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                          </svg>
                        </div>
                        <div class="ml-3 flex-1 md:flex md:justify-between">
                          <p class="text-sm text-blue-700">To add a new quick note, use <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800 border border-gray-200"> Alt (Option) </span> + <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800 border border-gray-200"> n </span> keyboard shortcut.</p>
                        </div>
                      </div>
                    </div>
                    <div class="py-2 align-middle inline-block min-w-full">
                      <div class="shadow border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                          <thead class="bg-gray-50">
                          <tr>
                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Time
                            </th>
                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            </th>
                            <th colspan="2" scope="col" class="relative px-6 py-3">
                              <span class="sr-only">Actions</span>
                            </th>
                          </tr>
                          </thead>
                          <tbody>
                          <!-- More items... -->

                          <template v-for="note in notesProcessed" v-bind:key="note.id">
                            <tr class="bg-white"
                            >
                              <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                {{note.ts}} - {{note.block}} - {{note.project}}<br />
                                {{note.content}}
                              </td>
                              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              </td>
                              <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <a @click="noteDelete(note.refID, note.id)" href="#" class="text-indigo-600 hover:text-indigo-900">Delete</a>
                              </td>
                            </tr>
                          </template>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <!-- Action buttons -->
            <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
              <div class="space-x-3 flex justify-end">
                <button @click="notesEditStop" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  //import {v4 as uuidv4} from "uuid";
  //import * as dayjs from "dayjs";
  import {ref} from "vue";
  import {mapGetters} from "vuex";
  import {createNote, deleteNote} from "../models/NotesModel";
  import {v4 as uuidv4} from "uuid";
  import * as dayjs from "dayjs";

  export default {
    name: 'NotesEdit',
    props: {
      notes: Array,
      blocks: Array,
      projects: Array
    },
    setup() {
      const formatter = ref({
        date: 'DD MMM YYYY HH:mm',
        month: 'MMM'
      });

      return {
        formatter
      };
    },
    data() {
      return {
        mode: 'info',
        noteAddStart: '',
        noteAddContent: '',
      }
    },
    computed: {
      ...mapGetters("auth", ["currentUser"]),
      notesProcessed () {
        //let initTime = null;
        let notesNew = [];

        // get the highest start
        if (this.notes.length > 0) {
          // calculate durations and format date to display
          this.notes.forEach((note) => {
            // assign blocks
            let blockTitle = '';
            let blockIndex = this.blocks.findIndex(item => item.id === note.blockRef);
            if (blockIndex > -1) {
              blockTitle = this.blocks[blockIndex].title;
            }
            // assign projects
            let projectTitle = '';
            let projectIndex = this.projects.findIndex(item => item.id === note.projectRef);
            if (projectIndex > -1) {
              projectTitle = this.projects[projectIndex].name;
            }

            notesNew.push({
              id: note.id,
              refID: note.refID,
              ts: dayjs(note.ts).format('HH:mm DD MMM YYYY'),
              content: note.content,
              project: projectTitle,
              block: blockTitle
            });
          });
        }
        return notesNew;
      },
    },
    methods: {
      notesEditStop() {
        this.$emit('notesEditStop');
      },
      dateFormat(tm) {
        let d = new Date(tm);
        return d.getHours() + ':' + d.getMinutes() + ' ' + d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear();
      },
      addNote() {
        const randomId = uuidv4();
        let start = new Date(this.noteAddStart);

        const note = {
          id: randomId,
          ts: start.getTime(),
          content: this.noteAddContent,
          refID: null,
        };

        this.$store.commit('noteAdd', note);

        if (this.currentUser) {
          createNote(note)
              .then((res) => {
                this.$store.commit('noteUpdateRef', {id: note.id, refID: res.ref.value.id});
              })
              .catch(() => {
                this.toast.error(`We are not able to create the note. Please try again.`)
              });
        }
      },
      noteDelete(noteRefId, noteId) {
        if (this.currentUser) {
          deleteNote(noteRefId)
              .then(() => {
                this.$store.commit('noteDelete', noteId);
              })
              .catch(() =>  {
                this.toast.error(`We were not able to delete the note. Please refresh and try again.`)
              });
        }
      },
    },
  }
</script>
