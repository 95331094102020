const getDefaultState = () => {
  return {
    notes: []
  }
};

export default {
  state: getDefaultState(),
  actions: {
    notesResetAll({commit}) {
      commit("notesResetAll");
    },
  },
  mutations: {
    noteAdd(state, note) {
      state.notes.push(note);
    },
    noteDelete(state, noteId) {
      let index = state.notes.findIndex(item => item.id === noteId );
      state.notes.splice(index, 1);
    },
    notesAdd(state, notes) {
      state.notes.splice(0, state.notes.length);
      notes.forEach(function(note) {
        state.notes.push(note);
      });
    },
    noteUpdateRef(state, data) {
      let index = state.notes.findIndex(item => item.id === data.id );
      state.notes[index].refID = data.refID;
    },
    noteEditNote(state, note) {
      let index = state.notes.findIndex(item => item.id === note.id );
      state.notes[index].content = note.content;
    },
    notesResetAll(state) {
      state.notes.splice(0, state.notes.length);
    },
  }
}
