<template>
  <div>
    <!-- Group selector -->
    <div class="">
      <div v-for="(group, index) in twemoji" v-bind:key="group.unicode"
        class="inline-block relative px-2 py-1 cursor-pointer"
        :class="selectedGroup === index ? 'bg-white' : 'bg-gray-100 rounded-md rounded-b-none shadow-sm' "
        @click="selectedGroup = index"
      >
        <img :src="'/icons/' + group.unicode + '.svg'" alt="" class="h-6 w-6 m-0.5" />
      </div>
    </div>

    <div>
      <div v-for="emoji in twemoji[selectedGroup].emojis" v-bind:key="emoji.unicode"
        class="inline-block relative rounded-md h-10 w-10 m-2 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 shadow border-white border-2"
        :class="type === emoji.unicode ? 'ring-2 ring-offset-2 ring-green-500' : 'ring-0'"
        @click="selectEmoji(emoji.unicode)"
      >
        <img :src="'/icons/' + emoji.unicode + '.svg'" alt="icon" class="h-8 w-8 m-0.5" />
        <svg v-if="emoji.unicode === type" class="rounded-full absolute top-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
      </div>
    </div>

  </div>
</template>

<script>
  import {twemoji} from '../icons';

  export default {
    name: 'BlockIconPicker',
    props: {
      type: String,
      position: {
        type: [String, Number],
      },
      isPreview: Boolean,
      isLoading: Boolean
    },
    data() {
      return {
        twemoji,
        selectedGroup: 0,
      }
    },
    computed: {
    },
    methods: {
      selectEmoji(emoji) {
        this.$emit('blockIconUpdate', emoji, this.position);
      }
    }
  }
</script>
